import { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
	TableHead,
	IconButton,
  TableRow,
	Typography,
  Button,
} from '@material-ui/core';
import dayjs from 'dayjs'
import { Link as RouterLink } from 'react-router-dom'
import formatNumbers from 'src/utils/formatNumbers';
import Loading from 'src/components/loading/Loading';
import { useGetUmnsQuery } from '../../../generated/graphql'
import Scrollbar from '../../Scrollbar';
import Export from '../../export/Exports';
import ArrowRight from '../../../icons/ArrowRight';

interface TableDetailConfirmadaProps {
	items?: any[];
		umnSelected?: any;
		setUmnSelected?: any;
		handleSubmit?: any;
    agencia?: any;
    loading?: boolean;
}

const TableTransaccionesProductoV2: FC<TableDetailConfirmadaProps> = (props) => {
    const { items, setUmnSelected, umnSelected, handleSubmit, agencia, loading,  ...other } = props;

    let variableAgencia;
    if (agencia === 0 || agencia === '0') {
      variableAgencia = [1, 2, 3];
    } else {
      variableAgencia = [parseInt(agencia, 10)];
    }

    const { data } = useGetUmnsQuery({variables: {agencia: variableAgencia}});

    const dataUmn = []
		data?.allUmns.map(oneUmn => dataUmn.push({id: oneUmn.id, numero: oneUmn.numero }))

		const isAllSelected = dataUmn.length > 0 && umnSelected.length === dataUmn.length;

		useEffect(() => {}, [umnSelected, isAllSelected])

    useEffect(() => {}, [items, agencia])

		const totales = {cantidad: 0, comisionA: 0, comisionC:0, neto: 0, bruto: 0, cpremiados : 0, premio : 0, comisionTdp: 0, total_pagar: 0}

    const headers = ["Producto", "Cantidad", "Total Bruto", "Premiados", "Premios", "Comision A", "Comision C", "Neto"];

    let dataPdf = items.map((item)=> [
      item.nombre,
      formatNumbers(parseInt(item.cantidad, 10)),
      formatNumbers(parseInt(item.total, 10)),
      formatNumbers(parseInt(item?.cantidad_premiados ?? 0, 10)),
      formatNumbers(parseInt(item?.premio ?? 0, 10)),
      formatNumbers(parseInt(item.totalComisionA, 10)),
      formatNumbers(parseInt(item.totalComisionC, 10)),
      formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))
    ]);
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      totales.bruto += parseInt(item.total, 10)
      totales.cantidad += parseInt(item.cantidad, 10)
      totales.comisionA += parseInt(item.totalComisionA, 10)
      totales.comisionC += parseInt(item.totalComisionC, 10)
      totales.cpremiados += parseInt(item?.cantidad_premiados ?? 0, 10)
      totales.premio += parseInt(item?.premio ?? 0, 10)
      totales.neto += (parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10))
      totales.comisionTdp += (item?.nombre === "Megaloto" ? item.total * 0.04 : 0) + (item?.id === 52 ? item.total * 0.02 : 0);
      totales.total_pagar += (item?.id !== 401 && item?.id !== 400 && item?.id !== 2) ? (parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10) - (item?.nombre === "Megaloto" ? item.total * 0.04 : 0 ) - (item?.id === 52 ? item.total * 0.02 : 0)) : 0
      })

    dataPdf = [
      ...dataPdf,
      [
        "Total: ",
        formatNumbers(totales.cantidad),
        formatNumbers(totales.bruto),
        formatNumbers(totales.cpremiados),
        formatNumbers(totales.premio),
        `${formatNumbers(totales.comisionA)}`,
        `${formatNumbers(totales.comisionC)}`,
        `${ formatNumbers(totales.neto)}`
      ]
    ]

    const pdfData = {
      fontSize: 10,
      title: "Transacciones por Producto",
      headers,
      data: dataPdf,
      downloadTitle: `productos-${dayjs().format('DD-MM-YYYY')}`,
    }

    return (
    <>
        <Card {...other}>
          <CardHeader
            action={<Export pdf
            pdfData={pdfData} />}
            title="Transacciones por Producto"
          />
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                            <TableCell style={{ width: "150px", margin: "0px", padding: "0px" }} align='center'>
                            <Typography color="textPrimary" variant="h6" >
                              Producto
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: "150px", margin: "0px", padding: "0px"}}>
                            <Typography color="textPrimary" variant="h6">
                              Cantidad
                            </Typography>
                          </TableCell>
                              <TableCell  style={{ width: "150px", margin: "0px", padding: "0px"}} >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
	                                Total Bruto
																</Typography>
                              </TableCell>
                              <TableCell style={{ width: "150px", margin: "0px", padding: "4px"}}>

                                    <Typography color="textPrimary" variant="h6" style={{ textAlign: "center"}}>
                                        Cant Premios
                                    </Typography>

                            </TableCell>
                              <TableCell  style={{ width: "150px", margin: "0px", padding: "4px"}} >

                                    <Typography color="textPrimary" variant="h6" style={{ textAlign: "center"}} >
                                       Total Premios
                                    </Typography>

                              </TableCell>
                              <TableCell  style={{ width: "150px", margin: "0px", padding: "4px"}} >
															<Typography
																	color="textPrimary"
																	variant="h6"

																>
																	C Agencia
																</Typography>
                              </TableCell>
															<TableCell  style={{ width: "150px", margin: "0px",padding:"4px"}}>
															<Typography
																	color="textPrimary"
																	variant="h6"

																>
																	C Corredor

																</Typography>
                              </TableCell>
															<TableCell  style={{ width: "150px", margin: "0px", padding: "4px"}}>
														    <Typography
																	color="textPrimary"
																	variant="h6"

																>
																	Neto
																</Typography>
                              </TableCell>
                              <TableCell  style={{width: "150px", margin: "0px", padding: "0px"}}>
														    <Typography
																	color="textPrimary"
																	variant="h6"

																>
																	C TDP
																</Typography>

                              </TableCell>
                              <TableCell  style={{ width: "150px", margin: "10px", padding: "0px"}}>
														    <Typography
																	color="textPrimary"
																	variant="h6"

																>
																	A pagar
																</Typography>

                              </TableCell>
                              <TableCell align='center'>
														    <Typography
																	color="textPrimary"
																	variant="h6"

																>
																	Opciones
																</Typography>
                              </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading ?
                            items?.map((item) => {
                              console.log('productos_nuevos',item)
                              const productType = item.nombre === 'Quiniela';
                              let comisionTdp = 0;
                              if (item.nombre === 'Megaloto'){
                                comisionTdp = item.total * 0.04
                              }
                              if (item?.id === 52){
                                comisionTdp = item.total * 0.02
                              }
														 return <TableRow
                                hover
                                key={item.id}
                                >
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                            style={{whiteSpace: "nowrap"}}
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
																					{formatNumbers(parseInt(item.cantidad, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.total, 10))}
                                        </Typography>
                                    </TableCell>
                                    { productType
                                      ?
                                      <>
                                        <TableCell align="right">
                                          <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                          >
                                            {formatNumbers(parseInt(item?.cantidad_premiados, 10))}
                                            {/* - */}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle1"
                                        >
                                          {formatNumbers(parseInt(item?.premio ?? 0, 10))}
                                          {/* - */}
                                        </Typography>
                                      </TableCell>
                                      </>
                                      :
                                      <>
                                        <TableCell align="right">
                                          <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                          >
                                           {formatNumbers(parseInt(item?.cantidad_premiados || 0, 10))}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle1"
                                        >
                                           {formatNumbers(parseInt(item?.premio ?? 0, 10))}
                                        </Typography>
                                      </TableCell>
                                      </>
                                    }
																		<TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.totalComisionA, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.totalComisionC, 10))}
                                        </Typography>
                                    </TableCell>

                                    {
                                      // eslint-disable-next-line no-nested-ternary
                                      productType ?
                                      <>
                                        <TableCell  align="right">
                                          <Typography
                                              color="textPrimary"
                                              variant="subtitle1"
                                          >
                                              { formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10)) }
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">0</TableCell>
                                        <TableCell align='right'>0</TableCell>
                                        <TableCell align="center">
                                          <Button
                                              component={RouterLink}
                                              to={`/gerencia/productos/${item?.id}`}
                                              sx={{maxHeight:10}}
                                          >
                                              <IconButton>
                                                <ArrowRight
                                                    fontSize="small"
                                                    style={{color: "blue"}}
                                                />
                                              </IconButton>
                                          </Button>
                                        </TableCell>
                                      </>
                                    :
                                    item?.nombre === "Raspadita" ?
                                      <>
                                          <TableCell  align="right">
                                            <Typography
                                                color="textPrimary"
                                                variant="subtitle1"
                                            >
                                                { formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10)) }
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="right">0</TableCell>
                                        <TableCell align="right">
                                          <Typography color="textPrimary" variant='subtitle1'>

                                          {formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10) - 0) }
                                          </Typography>
                                          </TableCell>
                                      {item?.nombre === "Raspadita" ?
                                        <TableCell align='center'>

                                        <Button
                                            component={RouterLink}
                                            to="/planillaGeneral/gerencia/raspadita/totales"
                                            sx={{maxHeight:10}}
                                        >
                                            <IconButton>
                                              <ArrowRight
                                                  fontSize="small"
                                                  style={{color: "blue"}}
                                              />
                                            </IconButton>
                                        </Button>
                                        </TableCell>: null
                                      }
                                        </>
                                      :
                                      <>
                                      <TableCell  align="right">
                                          <Typography
                                              color="textPrimary"
                                              variant="subtitle1"
                                          >
                                            { formatNumbers(parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)) }
                                          </Typography>
                                      </TableCell>

                                      <TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                             {item?.nombre === "Megaloto" || item?.id === 52 ? formatNumbers(comisionTdp) : 0}
                                        </Typography>
                                    </TableCell>
                                    <TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {
                                            item?.id === 52
                                            ? formatNumbers(parseInt(item.total, 10) * 0.8)
                                            : item?.nombre === "Megaloto"
                                              ? formatNumbers(
                                                  parseInt(item.total, 10) -
                                                  parseInt(item?.premio ?? 0, 10) -
                                                    parseInt(item.totalComisionA, 10) -
                                                    parseInt(item.totalComisionC, 10) -
                                                    comisionTdp
                                                )
                                              : // eslint-disable-next-line no-nested-ternary
                                                item?.id === 400 || item?.id === 401
                                              ? 0
                                              : formatNumbers(
                                                  parseInt(item.total, 10) -
                                                    parseInt(item.totalComisionA, 10) -
                                                    parseInt(item.totalComisionC, 10) -
                                                    parseInt(item?.premio ?? 0, 10) -
                                                    0
                                                )
                                          }
                                        </Typography>
                                    </TableCell>
                                        <TableCell align="center">
                                          <Button
                                              component={RouterLink}
                                              to={`/gerencia/productos/${item?.id}`}
                                              sx={{maxHeight:10}}
                                          >
                                              <IconButton>
                                                <ArrowRight
                                                    fontSize="small"
                                                    style={{color: "blue"}}
                                                />
                                              </IconButton>
                                          </Button>
                                        </TableCell>
                                      </>
                                    }
                                  </TableRow>
                              }) :<TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <Loading />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              </TableRow>
                            }
														<TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                       Total
                                    </Typography>
                                </TableCell>
                                <TableCell  align="right">
																	<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.cantidad)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.bruto)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                     color="textPrimary"
                                     sx={{fontWeight:"bold"}}
                                  >
                                   {formatNumbers(totales?.cpremiados)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                     color="textPrimary"
                                     sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales?.premio)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionA)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionC)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.neto)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionTdp)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.total_pagar)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                      component={RouterLink}
                                      to="/gerencia/productos/all"
                                      sx={{maxHeight:10}}
                                  >
                                      <IconButton>
                                        <ArrowRight
                                            fontSize="small"
                                            style={{color: "blue"}}
                                        />
                                      </IconButton>
                                  </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableTransaccionesProductoV2.propTypes = {
		items: PropTypes.any.isRequired,
		umnSelected: PropTypes.any,
		setUmnSelected: PropTypes.any,
		handleSubmit: PropTypes.any,
    agencia: PropTypes.any,
    loading: PropTypes.bool
};

export default TableTransaccionesProductoV2;
