/* eslint-disable new-cap */
import { jsPDF } from 'jspdf';
import autoTable from "jspdf-autotable";
import dayjs from 'dayjs'

export const exportToPDF = (pdfData) => {
  const { fontSize = 13, title, headers, data, downloadTitle = `pdfExport-${dayjs().format("DD-MM-YYYY")}` } = pdfData;
  const unit = "pt";
  const size = "A3"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 10;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(fontSize);

  const content = {
    startY: 50,
    head: [headers],
    body: data
  };

  doc.text(title, marginLeft, 40);
  autoTable(doc, content)
  doc.save(`${downloadTitle}.pdf`);
}

// let data = items.map((item)=> [dayjs(item?.fecha).format("DD-MM-YYYY"), item?.agencia_numero, item?.total_boletas, item?.tipo_juego, item?.total_monto_premiado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")]);
// data = [...data, ["Total: ", "", formatNumbers(totales.boletas), "", formatNumbers(totales.monto)]]
