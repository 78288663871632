import type { FC } from 'react';
import { Icon, Box, Card, Container, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CloseIcon from '@material-ui/icons/Close';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import PropTypes from 'prop-types'

interface AlertOpenBoxesProps {
  quantity: any;
  title?: any;
  subtitle?: any;
  icon?: any;
  color?: any;
}

const AlertOpenBoxes: FC<AlertOpenBoxesProps> = (props) => {
  const { quantity, title, subtitle, icon, color } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // minHeight: '100%',
        // p: 3
      }}
    >
      <Container
        // maxWidth="md"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Card
          sx={{
            // p: 2,
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Icon>
                {
                  // eslint-disable-next-line no-nested-ternary
                  icon === 'Queue' ?
                  <QueryBuilderIcon
                    fontSize="medium"
                    sx={{
                      color: `${color || 'warning.main'}`
                    }}
                  />
                  // eslint-disable-next-line no-nested-ternary
                  : icon === 'Total' ?
                  <PhonelinkSetupIcon
                    fontSize="medium"
                    sx={{
                      color: `${color || 'warning.main'}`
                    }}
                  />
                  : icon === 'Close' ?
                  <CloseIcon
                    fontSize="medium"
                    sx={{
                      color: `${color || 'warning.main'}`
                    }}
                  /> :
                  <ErrorOutlineIcon
                  fontSize="medium"
                  sx={{
                    color: `${color || 'warning.main'}`
                  }}
                />

                }
              </Icon>
              <Box sx={{ ml: 1, mr: 1 }}>
                <Typography
                  color="textPrimary"
                  noWrap
                  sx={{ fontWeight: 'fontWeightBold' }}
                  variant="subtitle2"
                >
                  {quantity} {title || 'Cajas'}
                </Typography>
                <Typography
                  color="textPrimary"
                  sx={{ fontWeight: 'fontWeightBold' }}
                  variant="subtitle2"
                >
                  {subtitle || 'Abiertas'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Container>
    </Box>
  )
};

AlertOpenBoxes.propTypes = {
  quantity: PropTypes.any.isRequired,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  color: PropTypes.any,
  icon: PropTypes.any,
};

export default AlertOpenBoxes;
