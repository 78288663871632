import { useState } from 'react'
import dayjs from "dayjs"

export const useDate = () => {
  let startDate = window.localStorage.getItem('startDate')
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  if (startDate === undefined || startDate === 'undefined' || startDate === null) {
      startDate = dayjs(new Date()).format("YYYY-MM-DD");
  }
  if (startDate !== today) {
    startDate = dayjs(new Date()).format("YYYY-MM-DD");
}
  const [dateState, setDateState] = useState({'startDate': startDate, 'endDate': startDate})
  return [
    dateState,
    (newDate) => {
      window.localStorage.setItem('startDate', newDate?.startDate)
      setDateState(newDate)
    }
  ]
}