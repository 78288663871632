import { TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ArrowRight from "src/icons/ArrowRight";

const TableBodyCustom = ({ dataBody, onClickCell,rowsPerPage,page}: any) => {

    const table = (rowsPerPage > 0
        ? dataBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : dataBody).map((rows: any) => {

        const body = rows?.map((cell: any) => {
            if (!cell)
                return null;
            const { value, key, align, id, isLink, icon } = cell;
            return (
                
                <TableCell key={`${id}-${key}`} align={align} onClick={()=>onClickCell(cell, key)}>
                    <Typography variant="subtitle1">{isLink?<Typography component={RouterLink} variant="subtitle1" to={isLink}>{value}{icon?<ArrowRight />:null}</Typography> : value}</Typography>
                </TableCell>
            );
        });

        return (
            <TableRow>
                {body}
            </TableRow>
        );
    });

    return (
        <TableBody>
            {table}
        </TableBody>
    )
}

export default TableBodyCustom;