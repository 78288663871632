import { useEffect, useState, useContext } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
	Modal,
  Divider,
  Tabs,
  Tab,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel
} from '@material-ui/core';
import dayjs from 'dayjs'
import formatWithoutDot from 'src/utils/formatWithoutDot';
import ErrorMessage from 'src/components/dashboard/error/ErrorMessage';
import {IOSSwitch} from '../../components/widgets/IOSSwitch'
import useSettings from '../../hooks/useSettings';
import TableDetail from '../../components/dashboard/entregas/TableDetail'
import Pagos from '../../components/dashboard/entregas/Pagos'
import Generar from '../../components/dashboard/entregas/Generar'
import { useDashboardCobradorQuery, useCobradorMutation, useCobradorRechazarMutation, useGetSwitchEntregasQuery, useChangeSwitchEntregasMutation } from '../../generated/graphql'
import { UMNContext, RoleContext, DateContext } from '../../contexts'
import Loading from '../../components/loading/Loading'
import useAuth from '../../hooks/useAuth'

const tabs = [
  { label: 'Confirmada', value: 'confirmada' },
  { label: 'Rechazada', value: 'rechazada' },
  { label: 'Pendiente', value: 'pendiente' },
];

const Entregas: FC = () => {
  const { role } = useContext(RoleContext)
  const { settings } = useSettings();
  const [ currentTab, setCurrentTab ] = useState<string>('confirmada')
  const auth = useAuth();
  const {user: {cobradores} = {}} = auth;

  const { UMN } = useContext(UMNContext)
  const { actualDate } = useContext(DateContext)
  const umnId = UMN

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const [ open, setOpen ] = useState(false)
  const [ openGenerar, setOpenGenerar ] = useState(false)

  const [ currentItem, setCurrentItem ] = useState(null);
  const [ titleModal, setTitleModal ] = useState('');

  const [variables, setVariables] = useState({
      umn_id: umnId,
      desde: actualDate.startDate,
      hasta: actualDate.endDate ,
      estado: [currentTab],
      offset: 0,
      limit: 10000,
      cobrador_id: undefined,
      nrodoc: '',
      nro_corredor: undefined
  })
  const [form, setForm] = useState({nrodoc: '', numero_corredor: ''})

  const handleFormChange = (e) => {
    e.preventDefault()
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setVariables({
        umn_id: umnId,
        desde: actualDate.startDate,
        hasta: actualDate.endDate ,
        estado: [currentTab],
        offset: 0,
        limit: 10000,
        cobrador_id: undefined,
        nrodoc: formatWithoutDot(form.nrodoc.toString()),
        nro_corredor: Number(form.numero_corredor)
      }
    )
  }

	const handleOpen = (item: any, title: any): void => {
    setCurrentItem(item)
    setTitleModal(title)
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpenGenerar = (): void => {
    setOpenGenerar(true)
  }

  const handleCloseGenerar = (): void => {
    setOpenGenerar(false);
  };

  useEffect(() => {
    setVariables({
        umn_id: umnId,
        desde: actualDate.startDate,
        hasta: actualDate.endDate ,
        estado: [currentTab],
        offset: 0,
        limit: 10000,
        cobrador_id: undefined,
        nrodoc: formatWithoutDot(form.nrodoc.toString()),
        nro_corredor: Number(form.numero_corredor)
      }
    )
  }, [actualDate, umnId, currentTab])

  if (role?.includes('cobrador') && currentTab !== 'pendiente') {
    if (cobradores && cobradores.length > 0) {
      variables.cobrador_id  = parseInt(cobradores[0].id, 10)
    }
  }

  const { data, error, loading, refetch } = useDashboardCobradorQuery({variables, fetchPolicy: 'network-only'})

  const refresh = (): void => {
    refetch({umn_id: umnId, estado: [currentTab]})
  }

  const [ cobradorMutation ] = useCobradorMutation({
    onCompleted: () => {handleClose(); refresh();}
  })

  const [ cobradorRechazarMutation ] = useCobradorRechazarMutation({
    onCompleted: () => {handleClose(); refresh();}
  })

  const handleConfirmation = (type: any): void => {
    if(type === 'Confirmar'){
      if (role.includes('cobrador')) {
        cobradorMutation({variables: {entrega_id: currentItem?.id, referencia:  `${dayjs(Date.now()).format("DD-MM-YYYY")}-${dayjs(Date.now()).format("HH:mm:ss")}+${currentItem?.id}`,  cobrador_id: parseInt(cobradores[0]?.id, 10)}})
      } else {
        cobradorMutation({variables: {entrega_id: currentItem?.id, referencia:  `${dayjs(Date.now()).format("DD-MM-YYYY")}-${dayjs(Date.now()).format("HH:mm:ss")}+${currentItem?.id}`,}})
      }
    }
    if(type === 'Rechazar'){
      cobradorRechazarMutation({variables: {entrega_id: currentItem?.id}})
    }
  }

  // get switch data from umn
  const {data: dataSwitch, loading: loadingSwitch, refetch: refetchSwitch} = useGetSwitchEntregasQuery({variables: {umn_id: umnId}})
  // mutation to switch data
  const [changeSwitchEntregasMutation, { loading: loadingMutationSwith, error: errorMutationSwitch }] = useChangeSwitchEntregasMutation({
    onCompleted: () => {refetchSwitch({umn_id: umnId})},
    onError: () => {refetchSwitch({umn_id: umnId})},
  });

  const handleSwitchEntrega = async (umn_id) => {
    await changeSwitchEntregasMutation({variables: {umn_id}})
  }

  interface TableDetailCell {
    name: string;
  }

  const tableTitle: TableDetailCell[] = [
    {name: 'Nro Corredor'},
    {name: 'Corredor'},
    {name: 'Agencia'},
    {name: 'UMN'},
    {name: 'Fecha de creacion'},
    {name: 'Fecha de confirmacion'},
    {name: 'Monto'},
    {name: 'Estado'},
  ]

  interface corredores {
    id?: any;
    numero?: any;
    cedula?: any;
    nombre?: any;
  }

  const corredoresArray: corredores[] =
		data?.umn.Corredores.map((corredor) => (
      {
        id: corredor?.id,
        numero: corredor?.numero,
        cedula: corredor?.Usuario?.username,
        nombre: `${corredor?.Usuario?.Person?.nombre} ${corredor?.Usuario?.Person?.apellido}`,
      }
	))

  if(error){
    return <ErrorMessage title={error.name}
    message={error.message} />
  }
  if(errorMutationSwitch){
    return <ErrorMessage title={errorMutationSwitch.name}
    message={errorMutationSwitch.message} />
  }
  if(loading) return <Loading />
  if(loadingSwitch) return <Loading />
  if(loadingMutationSwith) return <Loading />

  return (
    <>
      <Helmet>
        <title>Dashboard: Entregas | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Entregas
              </Typography>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
               Desde: { dayjs(actualDate?.startDate).format("DD/MM/YYYY") }, Hasta: { dayjs(actualDate?.endDate).format("DD/MM/YYYY") } {/* Aqui va la fecha que recibo formateada  */}
              </Typography>
            </Grid>
            {role?.includes('finanzas') ?
            null
            :
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={handleOpenGenerar}
                >
                  Generar Entrega
                </Button>
                <Modal
                  open={openGenerar}
                  onClose={() => handleCloseGenerar}
                >
                  <Generar
                    handleCloseGenerar={handleCloseGenerar}
                    data={corredoresArray}
                    refetchData={refetch}
                  />
                </Modal>
              </Box>
            </Grid>
            }
          </Grid>
          {role?.includes('agencia') || role?.includes('tdp_operativa') || role?.includes('admin_juego') ?
                <Grid item>
                  {umnId.length > 2 ? null :
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Entregas por Corredor</FormLabel>
                    <FormControlLabel
                      label={`${dataSwitch?.switchEntregas?.entrega_corredor ? 'Activado' : 'Desactivado'}`}
                      control={<IOSSwitch checked={dataSwitch?.switchEntregas?.entrega_corredor}
                      onChange={() => {handleSwitchEntrega(umnId)}}
                      name="checkedB" />}
                    />
                  </FormControl>
                  }
                </Grid>
                :
                null
                }
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'confirmada' &&
              <TableDetail
                data={data}
                tableTitle={tableTitle}
                title='Confirmada'
                handleOpen={handleOpen}
                form={form}
                searchByNumber={handleFormChange}
                searchByCI={handleFormChange}
                handleSubmit={handleSubmit}
              />
            }
            {currentTab === 'rechazada' &&
              <TableDetail
                data={data}
                tableTitle={tableTitle}
                title='Rechazada'
                handleOpen={handleOpen}
                form={form}
                searchByNumber={handleFormChange}
                searchByCI={handleFormChange}
                handleSubmit={handleSubmit}
              />
            }
            {currentTab === 'pendiente' &&
              <TableDetail
                data={data}
                tableTitle={tableTitle}
                title='Pendiente'
                handleOpen={handleOpen}
                form={form}
                searchByNumber={handleFormChange}
                searchByCI={handleFormChange}
                handleSubmit={handleSubmit}
              />
            }
          </Box>
          <Grid
            container
            spacing={3}
            pt={5}
          >
           <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
							<Modal
								open={open}
                onClose={cobradorMutation}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<Pagos
                  currentItem={currentItem}
                  titleModal={titleModal}
                  handleConfirm={handleConfirmation}
                  close={handleClose}
                />
							</Modal>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Entregas;
