import { Dialog, DialogContent, Grid, Typography, Box} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

export const ResponseScan = ({onClose, open, message, error }: any) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          p: 4
        }}
        direction='row'
        justifyContent='space-around'
      >
        <Box mr={4}>
          {
            error ?
              <ErrorIcon
                fontSize="large"
                htmlColor='#fc202b'
              />
            :
              <CheckCircleIcon
                fontSize="large"
                htmlColor='#20FC8F'
              />
          }
        </Box>
        <Box mb={0.5}>
          <Typography
            variant='h6'
          >
            {message}
          </Typography>
        </Box>
      </Grid>
    </DialogContent>
  </Dialog>
)
