import type { FC } from 'react';
import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { DateContext } from 'src/contexts/DateContext';
import { FilterGerenciaContext } from 'src/contexts/FilterContext';

import "../../../css/estiloGerencia.css"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import dayjs from "dayjs"
import axios from 'axios';
import Loading from 'src/components/loading/Loading';
import MoreMenu from '../../MoreMenu';
import Scrollbar from '../../Scrollbar';
import ArrowRight from '../../../icons/ArrowRight';
import formatNumbers from '../../../utils/formatNumbers';
import AlertOpenBoxes from '../../gerencia/AlertOpenBoxes'

interface TableRecaudacionAgenciaV2Props {
  items: any[];
  idProducto?: any;
  RouterLink?: any;
  umnoption?: any;
}

const TableRecaudacionAgenciaV2: FC<TableRecaudacionAgenciaV2Props> = (props) => {
  const { items, idProducto, umnoption, ...other } = props;
  console.log("umnoption", umnoption)
  const [loadingSorteoAgencia, setLoadingSorteoAgencia] = useState(true);
  const [detalleSorteoAgencia, setDetalleSorteoAgencia] = useState([]);
  console.log("detalleSorteoAgencia", detalleSorteoAgencia)
  const { sorteoId, agencia: agenciaParams } = useParams();
  const { agencia: agenciaFilter } = useContext(FilterGerenciaContext);
  const agencia = agenciaFilter === 0 ? agenciaParams : agenciaFilter;
  const totales = {
    // corredores: 0,
    cantidad: 0,
    bruto: 0,
    premio: 0,
    percent: 0,
    comisionA: 0,
    comisionC: 0,
    totalPremiados: 0,
    neto: 0,
    cajas: 0
  }


  const { actualDate } = useContext(DateContext);
  const selectLink = (umnop, item) => {
    if (umnop) {
      return `/gerencia/premiados/${sorteoId}/${item.agencia_numero}/umns/${item.umn_id}`;
    }
    return parseInt(item?.umns_cantidad, 10) > 1 ?
      `/gerencia/premiados/${sorteoId}/${item.agencia_numero}/umns` :
      `/gerencia/premiados-agrupados/${sorteoId}/${item.agencia_numero}`
  }

  const getDatosSorteoAgencia = async () => {
    const url = umnoption === undefined ? `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-recaudacion-sorteo-agencia` : `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-recaudacion-sorteo-umns`;
    const ag = agencia ? [Number(agencia)] : [0];
    setLoadingSorteoAgencia(true);
    setDetalleSorteoAgencia([]);
    const token = localStorage.getItem('accessToken');
    await axios.post(
      `${url}`,
      {
        "desde": actualDate?.startDate,
        "hasta": actualDate?.endDate,
        "agencia": ag,
        "umn_ids": [0],
        "sorteo": [parseInt(sorteoId, 10)]
      },
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {
        const dataSorteo = response.data as [];
        setDetalleSorteoAgencia(dataSorteo);
        setLoadingSorteoAgencia(false);
      })
      .catch((response) => {
        console.log("error", response)
        setLoadingSorteoAgencia(false);
      });
  };

  useEffect(() => {

    let mounted = true
    if (mounted) {

      getDatosSorteoAgencia();
    };
    mounted = false;

  }, [actualDate, agencia]);

  return (
    <>
      <Card {...other}>
        <CardHeader
          action={<MoreMenu />}
          title="Recaudacion por Agencia"
        />
        <Typography
          color="textSecondary"
          variant="h6"
          sx={{ marginLeft: 2 }}
        >
          Desde {dayjs(actualDate.startDate).format("DD/MM/YYYY")} hasta {dayjs(actualDate.endDate).format("DD/MM/YYYY")}
        </Typography>
        <Divider />
        <Scrollbar>
          <Box sx={{ minWidth: 720 }}>
            <Table stickyHeader
              className="tableColor"
              style={{ height: 150 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      {umnoption ? `SubAgencia` : `Agencia`}
                    </Typography>
                  </TableCell >
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Cantidad
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Premiados
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Total Bruto
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Premio
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      %
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Comisión Agencia
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Comisión Corredor
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Neto
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Opciones
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingSorteoAgencia ?
                  detalleSorteoAgencia.map((item) => {
                    totales.cantidad += parseInt(item.cantidad, 10)
                    totales.bruto += parseInt(item.monto, 10)
                    totales.premio += parseInt(item.premio, 10)
                    totales.comisionA += parseInt(item.comisionA, 10)
                    totales.comisionC += parseInt(item.comisionC, 10)
                    totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
                    totales.neto += (parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))
                    return <TableRow
                      key={item.id}
                    >
                      <TableCell align="left">
                        <Typography
                          variant="subtitle1"
                        >
                          {umnoption ? `AG ${item.nombre} Umn ${item.umn_id}` : `AG ${item.agencia_numero} ${item.nombre}`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {formatNumbers(parseInt(item.cantidad, 10))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {formatNumbers(parseInt(item.cantidad_premiados, 10))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {`${formatNumbers(parseInt(item.monto, 10))}`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {item.premio ? formatNumbers(parseInt(item.premio, 10)) : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          color={parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54 ? "red" : "textPrimary"}
                          sx={{ fontSize: parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54 ? "20px" : "16px" }}
                          variant="subtitle1"
                        >
                          {((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {formatNumbers(parseInt(item.comisionA, 10))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {formatNumbers(parseInt(item.comisionC, 10))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          {parseInt(item.premio, 10) ? formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)) : formatNumbers(parseInt(item.monto, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Grid
                          display='flex'
                          direction='row'
                          justifyContent="flex-end"
                        >
                          <Button
                            component={RouterLink}
                            to={
                              selectLink(umnoption, item)
                            }
                            sx={{ maxHeight: 10 }}
                          >
                            <IconButton>
                              <ArrowRight
                                fontSize="small"
                                style={{ color: "blue" }}
                              />
                            </IconButton>
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  }
                  ) : <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <Loading />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>}
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="h6"
                    >
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {totales.cajas > 0
                      ?
                      <AlertOpenBoxes quantity={totales.cajas} />
                      :
                      null
                    }
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color='textPrimary'
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.cantidad)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color='textPrimary'
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.totalPremiados)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color='textPrimary'
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.bruto)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color='textPrimary'
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.premio)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {(((totales.premio / totales.bruto) * 100) || 0).toFixed(2)}%
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.comisionA)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.comisionC)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {formatNumbers(totales.neto)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    </>)
};

TableRecaudacionAgenciaV2.propTypes = {
  items: PropTypes.array.isRequired,
  idProducto: PropTypes.any,
  umnoption: PropTypes.any
};

export default TableRecaudacionAgenciaV2;
