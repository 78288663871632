import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Chip
} from '@material-ui/core';
import { QrCode2, AddBox } from '@material-ui/icons';
import ErrorMessage from 'src/components/dashboard/error/ErrorMessage';
import { ResponseScan } from 'src/components/dashboard/raspaditas/ResponseScan'

const InventoryAdd = ({ handleClose, handleChange, handleSendBarcode, errorState, umnIdContext, openState, booksData, handleRemoveBox, handleAddItem, ErrorMessageState, valueState, libroErrorState }: any) => {
    const error = errorState;
    const umnId = umnIdContext;
    const open = openState;
    const books = booksData;
    const LibroErrorMessage = ErrorMessageState;
    const value = valueState;
    const libroError = libroErrorState;
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    m: 3
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xl={12} md={12} xs={12}>
                        {error ?
                            <Box sx={{ mt: 3, mb: 3 }}>
                                <ErrorMessage title={error.data.detail} message={error.data.detail} />
                            </Box>
                            :
                            null
                        }
                        {umnId.length > 1 ?
                            <Box sx={{ mt: 3, mb: 3 }}>
                                <ErrorMessage title='Debes seleccionar una UMN' message='Para cargar los inventarios necesariamente debe seleccionar una umn' />
                            </Box>
                            :
                            null
                        }
                        <Grid container spacing={3}>
                            <Grid item xl={10} md={10} xs={12}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Escanea con el codigo de barras"
                                    multiline
                                    size="small"
                                    maxRows={4}
                                    value={value}
                                    disabled={umnId.length > 1}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" >
                                                <QrCode2 />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="add new item in arr of boxes"
                                                    onClick={handleAddItem}
                                                    disabled={umnId.length > 1}
                                                >
                                                    <AddBox />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xl={2} md={2} xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ mb: 1, mt: 1, width: '100%', height: '70%' }}
                                    disabled={umnId.length > 1 || books.book.length < 1}
                                    onClick={() => { handleSendBarcode() }}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xl={12} md={12} xs={12} >
                        {libroError ?
                            <Box sx={{ mt: 3, mb: 3 }} >
                                <ErrorMessage title='Error al insertar el codigo' message={LibroErrorMessage} />
                            </Box>
                            :
                            null
                        }
                        {books.book.length >= 1 ?
                            <>
                                <Typography fontSize="xl" variant='h6' fontWeight='medium' >
                                    Lista de Inventario a Guardar
                                </Typography>
                                <Grid >
                                    {books.book.map((item: any) => (
                                        <Chip
                                            color="primary"
                                            key={item}
                                            onDelete={() => { handleRemoveBox(item) }}
                                            label={item}
                                            sx={{ marginRight: 1, mt: 1 }}
                                        />
                                    ))}
                                </Grid>
                            </>
                            : null
                        }
                    </Grid>
                    {open && !error ?
                        <ResponseScan onClose={handleClose} open={open} message="Carga de Inventario exitosa" /> : null
                    }
                </Grid>
            </Box>
        </>
    );
};

export default InventoryAdd;
