import  { createContext, useState, useEffect } from 'react'
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types'


interface FilterGerenciaProps {
    children?: ReactNode;
}

export const FilterGerenciaContext = createContext<any>({
    agencia: 0,
    agenciaValue: 0,
    corredor: null,
    corredorValue: null,
    sorteo: "0",
    sorteoValue: "0",
    setSorteo: () => {},
    setSorteoValue: () => {},
    setCorredor: () => {},
    setCorredorValue: () => {},
    setAgencia: () => {},
    setAgenciaValue: () => {},
})

export const FilterGerenciaProvider:FC<FilterGerenciaProps> = ({ children }) => {
    const [agencia, setAgencia] = useState(0)
    const [corredor, setCorredor] = useState(null)
    const [sorteo, setSorteo] = useState("0")
    const [agenciaValue, setAgenciaValue] = useState(0)
    const [corredorValue, setCorredorValue] = useState(null)
    const [sorteoValue, setSorteoValue] = useState("0")

    useEffect(() => {}, [agencia, corredor, sorteo])

    const handleSearchGerencia = () => {
      console.log("handleSearchGerencia")
      setAgencia(agenciaValue)
      setCorredor(corredorValue)
      setSorteo(sorteoValue)
    }

    const value = {
          agencia,
          corredor,
          sorteo,
          setAgencia,
          setCorredor,
          setSorteo,
          handleSearchGerencia,
          agenciaValue,
          corredorValue,
          sorteoValue,
          setAgenciaValue,
          setCorredorValue,
          setSorteoValue,
        }
    return (
      <FilterGerenciaContext.Provider value={value}>
        {children}
      </FilterGerenciaContext.Provider>
    )
  }


export const FilterGerenciaConsumer = FilterGerenciaContext.Consumer


FilterGerenciaProvider.propTypes = {
    children: PropTypes.node.isRequired
};
