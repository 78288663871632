import { TableFooter, TableRow, TableCell, Typography } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import ArrowRight from "src/icons/ArrowRight";

const TableFooterComponent = ({ dataFooter }: any) => {

    const footer = dataFooter?.map((item: any) => {
        if (!item)
            return null;
        const { value, align, key, isLink, icon } = item;
        return (
            <TableCell align={align} key={key}>
                <Typography
                    color="textPrimary"
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold' }}
                >
                    {isLink ?
                        <Typography
                            component={RouterLink}
                            variant="subtitle1"
                            to={isLink}
                        >
                            {value}{icon ? <ArrowRight /> : null}
                        </Typography> : value}
                </Typography>
            </TableCell>
        );
    });

    return (
        <TableFooter>
            <TableRow>
                <TableCell align="left">
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold" }}
                    >
                        Total
                    </Typography>
                </TableCell>
                {footer}
            </TableRow>
        </TableFooter>
    )
}

export default TableFooterComponent;