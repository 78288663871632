import { memo } from 'react'
import { Box, CircularProgress, Grid } from '@material-ui/core';

const Loading = memo(() => (
    <Grid
        sx={{
            backgroundColor: 'transparent',
            minHeight: '100%',
            minWidth: '100%',
            display: 'flex'
        }}
        alignItems='center'
        justifyContent='center'
    >
        <Box>
            <CircularProgress />
        </Box>
    </Grid>
  ))

export default Loading
