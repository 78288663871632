import { Helmet } from "react-helmet-async";
import { Box, Grid, Container, Typography, Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import InventoryTool from "src/components/dashboard/raspaditas/inventory/InventarioTool";
import useSettings from "src/hooks/useSettings";
import ChevronRightIcon from '../../../../icons/ChevronRight';

const Inventario = () => {

    const title = 'Dashboard: Inventario Raspadita Juegos | TDPBIZ 2.0';
    const { settings } = useSettings();



    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Inventario de Raspadita
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                                maxItems={4}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Planilla General
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Inventario
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                    item
                                    md={12}
                                    xl={12}
                                    xs={12}
                                >
                                    <InventoryTool />
                                </Grid>

                            </Box>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );


}

export default Inventario;