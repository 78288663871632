import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
// import formatNumbers from 'src/utils/formatNumbers'
// import MoreMenu from '../../../../MoreMenu';
import Scrollbar from "../../../Scrollbar"

interface ReporteProps {
  items: any;
}

const TableConscistencia: FC<ReporteProps> = (props) => {
  const { items, ...other } = props;

  const data = [
    {
      label: 'Cargas',
      property: 'cargas_m',
      color: false,
      value: 'positive'
    },
    {
      label: 'Apuestas',
      property: 'apuestas_m',
      color: true,
      value: 'negative'
    },
    {
      label: 'Premios',
      property: 'premios_m',
      color: false,
      value: 'positive'
    },
    {
      label: 'Retiros',
      property: 'retiros_m',
      color: false,
      value: 'negative'
    },
    {
      label: 'Recibo en Cta',
      property: 'recibo_cuenta_m',
      color: false,
      value: 'positive'
    },
    {
      label: 'Envio a Cta',
      property: 'envio_cuenta_m',
      color: true,
      value: 'negative'
    },
    {
        label: 'Saldo p/ Apostar',
        property: 'para_apostar_miles',
    },
    {
        label: 'Saldo ganado',
        property: 'ganado_m',
    },
    {
        label: 'Aux Saldo',
        property: 'aux_saldo',
    },
    {
        label: 'Formula',
        property: 'formula',
    },
    {
        label: 'Diferencia',
        property: 'dif'
    }

  ]
//   console.log({ items1: items?.data?.getAnalisisDeConsistencia?.[data[0].property] })
  return (
    <>
      <Card {...other}>
        <CardHeader
          // action={<MoreMenu />}
          title="Analisis de Consistencia"
        />
        <Divider />
        <Scrollbar>
          <Box>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Tipo
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      // sx={{textAlign: 'right', color: 'white'}}
                    >
                      Monto
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) =>
                    <TableRow
                    hover
                    key={item?.property}
                    sx={{backgroundColor: "inherit"}}
                    >
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                          >
                          {item?.label}
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                          sx={{ fontWeight: "bold",color: item?.value === 'positive' ? '#519E8A' : item?.value === 'negative' ? '#F72C25' : 'inherit' }}
                          variant="subtitle1"
                          
                          >
                         {items?.data?.getAnalisisDeConsistencia[0]?.[item?.property]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                )}
                <TableRow
                  hover
                  // key must be random
                  key={Math.random()}
                  sx={{backgroundColor: "inherit"}}
                >
                  <TableCell align='right'>
                      <Typography
                        color="textPrimary"
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                          <div>
                            {items?.data?.getAnalisisDeConsistencia?.[data[0].property]}
                          </div>
                      </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

TableConscistencia.propTypes = {
  items: PropTypes.any,
};

export default TableConscistencia;
