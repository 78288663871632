import type { FC } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types'

interface PagoProps {
  currentItem: any;
  handleConfirm: any;
  close: any;
  titleModal: any;
}

const Pagos: FC<PagoProps> = (props) => {
  const { currentItem, handleConfirm, close, titleModal } = props;
  console.log("hola ",currentItem)

  return (
    <Box
      sx={{
        minHeight: '100%',
        p: 10
      }}
    >
      <Paper
        elevation={12}
        sx={{
          maxWidth: 320,
          mx: 'auto',
          p: 2
        }}
      >
        <Box sx={{ 
          alignContent: 'center'
        }}>
          <Typography
            color="textSecondary"
            variant="h6"
          >
            {titleModal} pago de {currentItem.corredor}
          </Typography>
        </Box>
        <Box sx={{ 
          mt: 3 ,
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <Typography
          color="textSecondary"
          variant="h6"
        >
          {currentItem.monto}
        </Typography>
        </Box>
        <Box sx={{ 
          mt: 3,
          justifyContent: 'space-around',
          alignItems: 'center',
          display: 'flex',
        }}
        >
          <Button
            color="secondary"
            fullWidth
            variant="contained"
            style={{ marginLeft: 2, marginRight: 2 }}
            onClick={() => close()}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            style={{ marginLeft: 2, marginRight: 2 }}
            onClick={() => handleConfirm(titleModal)}
          >
            Ok
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

Pagos.propTypes = {
  currentItem: PropTypes.any,
  handleConfirm: PropTypes.any,
  close: PropTypes.any,
  titleModal: PropTypes.any
};
export default Pagos;
