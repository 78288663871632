import {  useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  CardContent,
  Typography,
  // Link
} from '@material-ui/core';
import formatNumbers from 'src/utils/formatNumbers';
// import {Link as RouterLink} from 'react-router-dom'
// import formatWithoutDot from '../../../utils/formatWithoutDot'

interface JuegosListTableProps {
  items: any;
  xlsx?: any;
  refrescar?: any;
}

const TableReportesDelSistema: FC<JuegosListTableProps> = (props) => {
  const { items, xlsx, refrescar } = props;
  console.log(xlsx, refrescar)

  useEffect(() => {}, [items])
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid
          item
          md={6}
          xl={6}
          xs={12}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  flexDirection: 'column',
                  textAlign: 'center',
                  height: 100
                }}
              >
                  <Typography
                    color="textPrimary"
                    sx={{ mt: 1 }}
                    variant="h2"
                  >
                    Apuestas: <a style={{color: '#519E8A'}}>{formatNumbers(parseInt(items[0].apostado ?? 0, 10))} &#8370;</a>
                  </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      <Grid
        item
        md={6}
        xl={6}
        xs={12}
      >
        <Card>
            <CardContent>
              <Box
                sx={{
                  flexDirection: 'column',
                  textAlign: 'center',
                  height: 100
                }}
              >
                  <Typography
                     color="textPrimary"
                     sx={{ mt: 1 }}
                     variant="h2"
                  >
                    Monto Ganado:  <a style={{color: '#F72C25'}}>{formatNumbers(parseInt(items[0].ganado ?? 0, 10))} &#8370;</a>
                  </Typography>
              </Box>
            </CardContent>
          </Card>
      </Grid>
      </Grid>
    </>
  );
};

TableReportesDelSistema.propTypes = {
  items: PropTypes.any,
  xlsx: PropTypes.any,
  refrescar: PropTypes.any
};

export default TableReportesDelSistema;
