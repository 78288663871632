import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface QuinielaState {
  isSidebarOpen: boolean;
  isComposeOpen: boolean;
}

const initialState: QuinielaState = {
  isSidebarOpen: false,
  isComposeOpen: false
};

const slice = createSlice({
  name: 'quiniela',
  initialState,
  reducers: {
    openSidebar(state: QuinielaState): void {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: QuinielaState): void {
      state.isSidebarOpen = false;
    },
    openCompose(state: QuinielaState): void {
      state.isComposeOpen = true;
    },
    closeCompose(state: QuinielaState): void {
      state.isComposeOpen = false;
    }
  }
});

export const { reducer } = slice;


export const openSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openCompose());
};

export const closeCompose = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
