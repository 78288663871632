import {
    Box,
    Button,
    Card,
    CardHeader,
    Table,
    Grid,
    TablePagination
} from "@material-ui/core";
import Scrollbar from "src/components/Scrollbar";
import TableHeadCustom from "src/components/_common/table-head";
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import TableBodyCustom from "src/components/_common/table-body";
import dayjs from 'dayjs'

const InventarioDetalle = ({ close, dataDetail, gameType, cardTitle, sortClick }: any) => {

    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const title = `Detalle de Inventario`;

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: any) => {
        setLimit(parseInt(event.target.value, 10));
    };

    const pascalCase = (str: string) => {
        console.log('');
        return str.split(' ').map((word: string) => {
            console.log('');
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }


    const columns = [{ name: `Libros de ${gameType}`, align: "left" },{ name: 'UMN', align: "center" }, { name: `Fecha de los Ultimos ${pascalCase(cardTitle)}`, align: "center" }, {name: 'Ultimos Asignados', align:'center'}];

    const data = dataDetail.map((item: any) => {
        const { codigo, fecha_asignacion, registro_inventario , box_id, umn_nombre} = item;
        const row = [{ value: codigo, align: 'left', key: "codigo", isLink:`/raspaditas/caja/libro/${box_id}/${codigo}` },{ value:umn_nombre, align: 'center', key: "umn_nombre" }, { value: dayjs(registro_inventario).format('DD/MM/YY HH:mm')==='Invalid Date'?'-':dayjs(registro_inventario).format('DD/MM/YY HH:mm'), align: 'center', key: "registro_inventario" }, { value: dayjs(fecha_asignacion).format('DD/MM/YY HH:mm')==='Invalid Date'?'-':dayjs(fecha_asignacion).format('DD/MM/YY HH:mm'), align: 'center', key: "fecha_asignacion" } ];
        return row;
    });

    return (
        <Box>
            <Card sx={{ m: 3 }}>
                <CardHeader
                    title={title}
                    action={
                        <Button
                            color="inherit"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            onClick={close}
                            sx={{
                                width: "100%"
                            }}
                        >
                            Cerrar
                        </Button>
                    }
                />
                <Scrollbar>
                    <Box sx={{ minWidth: 720 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Table stickyHeader
                                    className="tableColor"
                                    style={{ height: 150 }}>
                                    <TableHeadCustom columns={columns} sortClick={sortClick} />
                                    <TableBodyCustom dataBody={data} onClickCell={() => { }} rowsPerPage={limit} page={page} />
                                </Table>
                            </Grid>
                        </Grid>
                    </Box>
                </Scrollbar>
                <TablePagination
                    component="div"
                    count={data.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 50, 100]}
                />
            </Card>
        </Box>
    );
}
export default InventarioDetalle;