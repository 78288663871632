import { useRef, useState, memo } from 'react';
import type { FC } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types'
import DocumentTextIcon from '../icons/DocumentText';
import DotsHorizontalIcon from '../icons/DotsHorizontal';

interface ActionsAgenciaProductoProps {
    xlsx: any;
    items: any;
    idProducto: any;
 }

const ActionsAgenciaProducto: FC<ActionsAgenciaProductoProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { xlsx, items, idProducto} = props;


  const mapProducto = (id) => {
    console.log("idProducto: ", id)
    if (parseInt(id, 10) === 2) {
      return `Quiniela`;
    }
    if (parseInt(id, 10) === 3) {
      return `Billetaje`;
    }
    if (parseInt(id, 10) === 4 || id === 6) {
      return `Tigo`;
    }
    if (parseInt(id, 10) === 101) {
      return `Recarga Remota`;
    }
    if (parseInt(id, 10) === 51) {
      return `Rifa Capuchinos`;
    }
    if (parseInt(id, 10) === 7) {
      return `Personal`;
    }
    if (parseInt(id, 10) === 8) {
      return `Claro`;
    }
    return "No definido"
  }

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  interface resumen {
		Agencia?: any;
    UMN?: any;
    Cantidad?: any;
    items?: any;
    TotalBruto?: any;
    Premio?: any;
    Porcentaje?: any;
    ComisionAgencia?: any;
    ComisionCorredor?: any;
    Neto?: any;
  }
  const XLSXArray: resumen[] =
    items?.map((item) => (
        {
						Agencia: `AG ${item.agencia_numero}`,
            UMN: `UMN ${item.numero} - ${item.nombre}`,
            Cantidad: `${item?.cantidad}`,
            items: `${ item?.cantidad_premiados }`,
            TotalBruto: `${item?.total}`,
            Premio: `${item.premio? item.premio: "-"}`,
            Porcentaje: `${((parseInt(item.premio, 10) / parseInt(item.total, 10)) * 100).toFixed(2)} %`,
            ComisionAgencia: parseInt(item.totalComisionA, 10),
            ComisionCorredor: parseInt(item.totalComisionC, 10),
            Neto: `${item.premio ? (parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)): (parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))}`
        }
    ))

  const exportToCVS = () => {
      const workSheet = xlsx.utils.json_to_sheet(XLSXArray)
      const workBook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(workBook, workSheet, "premiados")
    	//   buffer
      const buff = xlsx.write(workBook, {bookType: 'xlsx', type: 'buffer'})
			console.log(buff)
    	//   binary
      xlsx.write(workBook, {bookType: 'xlsx', type: 'binary'})
			// download
			xlsx.writeFile(workBook, `resumen producto ${mapProducto(idProducto)}.xlsx`)
  }

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton
          onClick={handleMenuOpen}
          ref={anchorRef}
          {...props}
        >
          <DotsHorizontalIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 256,
            marginTop:6
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuItem onClick={() => exportToCVS()}>
          <ListItemIcon>
            <DocumentTextIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary="Exportar a XLSX" />
        </MenuItem>
      </Menu>
    </>
  );
};

ActionsAgenciaProducto.propTypes = {
    xlsx: PropTypes.any,
    items: PropTypes.any,
    idProducto: PropTypes.any
};

export default memo(ActionsAgenciaProducto);