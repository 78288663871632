import type { FC } from "react";
// import { useEffect } from 'react';
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, FormHelperText, TextField } from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";

import type { User } from '../../../types/user';



const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String, $password: String ) {
    login(username: $username, password: $password ) {
      token
      user{
    id
    Person{
      nombre
      apellido
    }
  }
    }
  }
`;


const LoginJWT: FC = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth() as any;
  const [login2] = useMutation(LOGIN_MUTATION);

  // useEffect(() => {}, [login])
  return (
    <Formik
      initialValues={{
        cedula: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        cedula: Yup.string().max(255).required("Se requiere la cédula"),
        password: Yup.string().max(255).required("Se requiere la contraseña"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {

          const {data}:any= await login2({variables:{username:values.cedula,password:values.password}})
          console.log("login data: ", data);

          const user:User = {
            avatar:  `https://i.pravatar.cc/300?u=${data.login.user.id}`,
            email: 'aa@example.com',
            id: data.login.user.id,
            name: `${data.login.user.Person.nombre} ${data.login.user.Person.apellido}`
          }


   await login( data.login.token, user);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate
        onSubmit={handleSubmit}
        {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.cedula && errors.cedula)}
            fullWidth
            helperText={touched.cedula && errors.cedula}
            label='Cédula'
            margin='normal'
            name='cedula'
            onBlur={handleBlur}
            onChange={handleChange}
            type='text'
            value={values.cedula}
            variant='outlined'
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label='Password'
            margin='normal'
            name='password'
            onBlur={handleBlur}
            onChange={handleChange}
            type='password'
            value={values.password}
            variant='outlined'
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color='primary'
              disabled={isSubmitting}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Iniciar Sesión
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
