import {useContext, useEffect} from 'react'
import {
  TextField,
  Grid,
} from '@material-ui/core';
import { AgenciaContext } from '../../contexts/AgenciaContext'

const AgenciesFilter = ({ dataAgencia }: any) => {
    const {agenciaValue, setAgenciaValue, allAgencies} = useContext(AgenciaContext)

    const handleChangeAgencia = (event) => {
			if(event.target.value === 0 || event.target.value === '0'){
		localStorage.setItem('agencia', JSON.stringify(allAgencies))
        setAgenciaValue(allAgencies)
      } else {
		localStorage.setItem('agencia', JSON.stringify([Number(event.target.value)]))
        setAgenciaValue([Number(event.target.value)]);
      }
		};

    useEffect(() => {
      console.log('se actualizo la agencia')
    },[dataAgencia])
    // este es el selector de agencias para el filtro de agencias
    return (<>
        <Grid
					container
					sx={{ justifyContent: 'flex-start'}}
				>
            <Grid sx={{ paddingBottom: 2, paddingLeft: 3}}>
							<TextField
								fullWidth
								label="Agencia"
								select
								SelectProps={{ native: true }}
								variant="outlined"
								name="agencia"
								onChange={handleChangeAgencia}
								value={agenciaValue}
						>
                <option
									key={0}
									value={0}
								>
									Todos
								</option>
							{
								dataAgencia?.map((item)=> (
								<option
									key={item?.numero}
									value={item?.numero}
								>
									{item?.numero} ({item?.nombre ?? "-"})
								</option>
								))
            	}
						</TextField>
          </Grid>
        </Grid>
    </>)
}

export default AgenciesFilter;
