import type { FC } from 'react'
import { useContext, useState, useEffect } from 'react'
// import { useContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Divider
} from '@material-ui/core';
import XLSX from 'xlsx';
import TableRecaudacionAgenciaGerenciaAgrupado from 'src/components/dashboard/gestionar/TableRecaudacionAgenciaGerenciaRaspaAgrupado';
import { useParams, Link as RouterLink } from 'react-router-dom';
// import Loading from 'src/components/loading/Loading'
// import {UMNContext} from '../../contexts/UMNContext'
// settings
import Loading from 'src/components/loading/Loading';
import axios from 'axios';
import { FilterGerenciaContext } from 'src/contexts';
import { DateContext } from '../../../../contexts/DateContext'
import useSettings from '../../../../hooks/useSettings';
import ChevronRightIcon from '../../../../icons/ChevronRight';

const PlanillaGeneralRaspaGerencia: FC = () => {
  // const { actualDate } = useContext(DateContext)
  // const { UMN } = useContext(UMNContext)
  // const umnId = UMN

  const { settings } = useSettings();

  const { juegoId } = useParams();
  const { actualDate } = useContext(DateContext)
  const { agencia } = useContext(FilterGerenciaContext);
  const [loadingRaspa, setLoadingRaspa] = useState(true);
  const [detalleRaspaAgrupado, setDetalleRaspaAgrupado] = useState([]);

  const varJuegoId = parseInt(juegoId, 10);

  const getRaspaAgrupados = async () => {
    setLoadingRaspa(true);
    setDetalleRaspaAgrupado([]);
    const token = localStorage.getItem('accessToken');
    await axios.post(
      `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-transaccion-producto-raspa-juego-agencia`,
      {
        "desde": actualDate?.startDate,
        "hasta": actualDate?.endDate,
        "fechas": {
          desde: actualDate?.startDate,
          hasta: actualDate?.endDate
        },
        "agencia": agencia === 0 || agencia === '0' ? [0] : [agencia],
        "umn_ids": [0],
        "producto_id": [varJuegoId]
      },
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {
        const dataSorteoAgrupado = response.data as [];
        setDetalleRaspaAgrupado(dataSorteoAgrupado);
        setLoadingRaspa(false);
      })
      .catch((response) => {
        console.log("error", response)
        setLoadingRaspa(false);
      });
  };

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getRaspaAgrupados();
      console.log(detalleRaspaAgrupado)
    };
    mounted = false;
  }, [agencia, actualDate])


  if (loadingRaspa) {
    return <Loading />
  }


  return (
    <>
      <Helmet>
        <title>Dashboard: Detalles por producto Agencias | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Planilla de Agencias por Producto
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
              maxItems={4}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/"
                variant="subtitle2"
              >
                Planilla General
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                Agencias Producto
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Divider orientation="horizontal"
                  sx={{ backgroundColor: "white" }}
                />
              </Box>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TableRecaudacionAgenciaGerenciaAgrupado items={detalleRaspaAgrupado || []}
                  xlsx={XLSX} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PlanillaGeneralRaspaGerencia;
