import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

interface UMNContextI {
    UMN: any;
    setUMN: any;
    agencie: any;
    setAgencie: any;
  }

export const UMNContext = createContext<UMNContextI>({
    UMN: [0],
    setUMN: () => {},
    agencie: [0],
    setAgencie: () => {}
});

export const UMNProvider = (props) => {
  const { children } = props;
  const auth = useAuth()
  console.log('auth from context umn',auth)
  const [UMN, setUMN] = useState(localStorage.getItem("UMNid") || auth?.user?.umns[0]?.id);
  const [agencie, setAgencie] = useState(localStorage.getItem("agencia") || auth?.user?.umns[0]?.agencia_numero);
  console.log('auth from context umn',UMN)


	useEffect(() => {
    if(!UMN){
      localStorage.setItem('UMNid', auth?.user?.umns[0]?.id)
    }
    localStorage.setItem('UMNid', UMN)
    if(!agencie){
      localStorage.setItem('agencia', auth?.user?.umns[0]?.agencia_numero)
    }
    localStorage.setItem('agencia', agencie)

    console.log("UMN selected:",UMN)
	}, [UMN])
  console.log("agencia umn value", auth)

  return (
    <UMNContext.Provider

      value={{
        UMN,
        setUMN,
        agencie,
        setAgencie
      }}
    >
      {children}
    </UMNContext.Provider>
  );
};

UMNProvider.propTypes = {
  children: PropTypes.node.isRequired
};
