import { TableRow, TableCell, Typography, TableHead, TableSortLabel } from "@material-ui/core";
// import { ArrowDropUp } from "@material-ui/icons";

const TableHeadCustom = ({ columns, sortClick }: any) => {

    const columnsHead = columns.map((column: any) => {
        if (!column)
            return null;

        const align = column?.align
        const label = column?.name
        const key = column?.key

        return (
            <TableCell
                align={align}
                key={key}
                className="tableColor textPrimary" >
                <TableSortLabel
                    direction="asc"
                    onClick={() => sortClick(key)}
                    hideSortIcon={false}
                // IconComponent={() => <ArrowDropUp />}
                >
                    <Typography
                        variant="h6"
                        color="textPrimary">{label}</Typography>
                </TableSortLabel>
            </TableCell>
        );
    });

    return (
        <TableHead>
            <TableRow>
                {columnsHead}
            </TableRow>
        </TableHead>
    );
}

export default TableHeadCustom;