import type { FC } from 'react'
import { useContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  Breadcrumbs
} from '@material-ui/core';
import TableRecaudacionAgencia from 'src/components/dashboard/gerencia/TableRecaudacionAgencia';
import TableRecaudacionSorteo from 'src/components/dashboard/gerencia/TableRecaudacionSorteo';
import TableTotalGeneralAgrupados from 'src/components/dashboard/gerencia/TableTotalGeneralAgrupados';
import TableTransaccionesProducto from 'src/components/dashboard/gerencia/TableTransaccionesProducto';
import TableTransaccionesUMN from 'src/components/dashboard/gerencia/TableTransaccionesUMN';
import axios from 'axios';
import ChevronRightIcon from '../../icons/ChevronRight';
import { DateContext } from '../../contexts/DateContext'
import { FilterGerenciaContext } from '../../contexts/FilterContext'
import { UMNContext } from '../../contexts/UMNContext'
// settings
import useSettings from '../../hooks/useSettings';

const Gerencia: FC = () => {
  const { actualDate } = useContext(DateContext)
  const { sorteo, agencia, setSorteo, corredor } = useContext(FilterGerenciaContext)
  const { UMN } = useContext(UMNContext)
  const umnId = UMN
  const [toggle, setToggle] = useState(2)
  const [toggleTransacciones, setToggleTransacciones] = useState(1)
  const [umnSelected, setUmnSelected] = useState([umnId])
  const [loadingSorteo, setLoadingSorteo] = useState(true);
  const [loadingResumenProducto, setLoadingResumenProducto] = useState(true);
  const [detalleSorteo, setDetalleSorteo] = useState<any>();
  const [dataResumenProducto, setDataResumenProducto] = useState([]);
  const [loadingProductoUmn, setLoadingProductoUmn] = useState(true);
  const [detalleProductoUmn, setDetalleProductoUmn] = useState([]);
  const [loadingSorteoAgrupado, setLoadingSorteoAgrupado] = useState(true);
  const [detalleSorteoAgrupado, setDetalleSorteoAgrupado] = useState([]);

  // let variables: any = null;
  let variablesAgrupados: any = null;
  let varSorteo = [1, 2, 4, 5, 20];
  if (sorteo !== '0') {
    varSorteo = [parseInt(sorteo, 10)];
  }
  let varAgencia = [parseInt(agencia, 10)];

  if (agencia === 0 || agencia === '0') {
    varAgencia = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 112, 113, 114, 115, 116, 117, 118, 119, 120, 300, 301, 302, 303, 304, 305, 306, 311];
  }

  // variables = { desde: actualDate.startDate, hasta: actualDate.endDate, agencia: varAgencia, sorteo: varSorteo, corredor_numero: corredor }
  variablesAgrupados = { fechaFrom: actualDate?.startDate, fechaTo: actualDate?.endDate, agencia: varAgencia, sorteo: varSorteo, corredor_numero: corredor }

  const { settings } = useSettings();
  const data = [];
  const error = undefined;
  // const { data, error } = useDashGerenciaQuery({ variables, fetchPolicy: 'network-only' })
  // const detalleSorteo = useDashGerenciaSorteoQuery({ variables, fetchPolicy: 'network-only' })
  // const detalleAgencia = useDashGerenciaAgenciaQuery({ variables, fetchPolicy: 'network-only' })
  // const agrupados = useDashGerenciaAgrupadoQuery({ variables: variablesAgrupados, fetchPolicy: 'network-only' })

  console.log(actualDate.startDate, actualDate.endDate)

  // const { data: dataResumenProducto } = useResumenUmnGerenciaProductoQuery({
  //     variables: {
  //         // eslint-disable-next-line no-unneeded-ternary
  //         agencia: varAgencia,
  //         desde: actualDate.startDate,
  //         hasta: actualDate.endDate,
  //         corredor_numero: corredor,
  //         sorteo: varSorteo
  //     }, fetchPolicy: 'network-only'
  // })
  // const { data: dataResumenUmn } = useResumenUmnGerenciaUmnQuery({
  //     variables: {
  //         // eslint-disable-next-line no-unneeded-ternary
  //         agencia: varAgencia,
  //         desde: actualDate.startDate,
  //         hasta: actualDate.endDate,
  //         corredor_numero: corredor
  //     }, fetchPolicy: 'network-only'
  // })

  const getDatosSorteoAgrupado = async () => {
    setLoadingSorteoAgrupado(true);
    setDetalleSorteoAgrupado([]);
    const token = localStorage.getItem('accessToken');
    await axios.post(
      `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-recaudacion-sorteo-fecha`,
      {
        "desde": actualDate?.startDate,
        "hasta": actualDate?.endDate,
        "fechas": {
          desde: actualDate?.startDate,
          hasta: actualDate?.endDate
        },
        "agencia": variablesAgrupados.agencia,
        "umn_ids": [0],
        "sorteo": variablesAgrupados.sorteo
      },
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {
        const dataSorteoAgrupado = response.data as [];
        setDetalleSorteoAgrupado(dataSorteoAgrupado);
        setLoadingSorteoAgrupado(false);
      })
      .catch((response) => {
        console.log("error", response)
        setLoadingSorteoAgrupado(false);
      });
  };

  // const getDatosSorteo = async () => {
  //   setLoadingSorteo(true);
  //   setDetalleSorteo([]);
  //   const token = localStorage.getItem('accessToken');
  //   await axios.post(
  //     `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-recaudacion-sorteo`,
  //     {
  //       "desde": actualDate?.startDate,
  //       "hasta": actualDate?.endDate,
  //       "agencia": varAgencia,
  //       "umn_ids": [0],
  //       "sorteo": [parseInt(sorteo, 10)]
  //     },
  //     { headers: { "Authorization": `Bearer ${token}` } }
  //   )
  //     .then((response) => {
  //       const dataSorteo = response.data as [];
  //       setDetalleSorteo(dataSorteo);
  //       setLoadingSorteo(false);
  //     })
  //     .catch((response) => {
  //       console.log("error", response)
  //       setLoadingSorteo(false);
  //     });
  // };

    const getDatosSorteo = async () => {
      setLoadingSorteo(true);
      setDetalleSorteo([]);
      const token = localStorage.getItem('accessToken');

      const variables = {
        desde: actualDate?.startDate,
        hasta: actualDate?.endDate,
        agencias: varAgencia,
        sorteos: varSorteo,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL_GRAPHQL}`,
        JSON.stringify({
          operationName: null,
          query: `
            query getQuinielaSorteoRecaudacion($desde: Date!, $hasta: Date!, $agencias: [Int]!, $sorteos: [Int]!) {
              getQuinielaSorteoRecaudacion(desde: $desde, hasta: $hasta, agencias: $agencias, sorteos: $sorteos)
            }
          `,
          variables,
        }),
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          const responseData = response?.data as { data: { getQuinielaSorteoRecaudacion: any } };
          setDetalleSorteo(responseData?.data?.getQuinielaSorteoRecaudacion);
          setLoadingSorteo(false);
        })
        .catch((e) => {
          console.log('error', e);
          setLoadingSorteo(false);
        });
    };

  console.log('hola', varAgencia,'sorteo', varSorteo);
  const getdataResumenProducto = async () => {
    setLoadingResumenProducto(true);
    setDataResumenProducto([]);
    const token = localStorage.getItem('accessToken');
    await axios.post(
      `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-transaccion-producto`,
      {
        "desde": actualDate?.startDate,
        "hasta": actualDate?.endDate,
        "agencia": varAgencia,
        "umn_ids": [0],
        "producto_id": [0]
      },
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {
        const dataProducto = response.data as [];
        setDataResumenProducto(dataProducto);
        setLoadingResumenProducto(false);
      })
      .catch((response) => {
        console.log("error", response)
        setLoadingResumenProducto(false);
      });
  };


  const getResumenProductoUmn = async () => {
    const url = `${process.env.REACT_APP_API_URL_RASPADITA}/api/agencias/sumario-transaccion-producto-umns-gerencia`;
    setLoadingProductoUmn(true);
    setDetalleProductoUmn([]);
    const token = localStorage.getItem('accessToken');
    await axios.post(
      `${url}`,
      {
        "desde": actualDate?.startDate,
        "hasta": actualDate?.endDate,
        "agencia": varAgencia,
        "umn_ids": [0],
        "producto_id": [0]
      },
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {
        console.log("kha")
        const dataSorteo = response.data as [];
        setDetalleProductoUmn(dataSorteo);
        setLoadingProductoUmn(false);
      })
      .catch((response) => {
        console.log("error", response)
        setLoadingProductoUmn(false);
      });
  };

  console.log('dash gerencia general: ', data)
  // console.log("total agrupados: ", agrupados)
  console.log("total sorteo: ", detalleSorteo)
  console.log(error)
  console.log("resumenProducto: ", dataResumenProducto)

  useEffect(() => {
    setSorteo("0")
  }, [])


  useEffect(() => {

    let mounted = true
    if (mounted) {
      getDatosSorteo();
      getdataResumenProducto();
      getResumenProductoUmn();
      getDatosSorteoAgrupado();
    };
    mounted = false;
  }, [sorteo, umnSelected, corredor, actualDate, toggle, agencia])

  return (
    <>
      <Helmet>
        <title>Dashboard: Gerencia | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
                maxItems={4}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Planilla General - Gerencia
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              className="grillaboton"
              container
              item
              spacing={3}
              xl={12}
              md={12}
              xs={12}
            >
              <Grid

                item
                xl={12}
                md={12}
                xs={12}
              >
                <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
                >
                  {/* <Button   onClick={() => setToggle(1)} variant="contained" className="botones" color="primary" >
                                Recaudaciones por Agencia
                            </Button> */}
                  <Button onClick={() => setToggle(2)}
                    className="botones"
                    variant="contained"
                    color="primary" >
                    Recaudaciones por Sorteo
                  </Button>
                  <Button onClick={() => setToggle(3)}
                    variant="contained"
                    className="botones"
                    color="primary" >
                    Agrupados por Fecha
                  </Button>
                </Box>
              </Grid>
            </Grid>


            <Grid
              className="grillabotonRes"
              item
              xl={12}
              md={12}
              xs={12}
            >
              <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
              >
                <Button onClick={() => setToggle(1)}
                  variant="contained"
                  className="botones"
                  color="primary" >
                  Recaudaciones por Agencia
                </Button>
              </Box>
            </Grid>
            <Grid
              className="grillabotonRes"
              item
              xl={12}
              md={12}
              xs={12}
            >
              {/* <Box sx={{ justifyContent: 'space-evenly', display: 'flex'}}
                             >
                              <Button onClick= {() => setToggle(2)} className="botones" variant="contained" color="primary" >
                                Recaudaciones por Sorteo
                            </Button>
                            </Box> */}
            </Grid>
            <Grid
              className="grillabotonRes"
              item
              xl={12}
              md={12}
              xs={12}
            >
              <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
              >
                <Button onClick={() => setToggle(3)}
                  variant="contained"
                  className="botones"
                  color="primary" >
                  Agrupados por Fecha
                </Button>
              </Box>
            </Grid>


            {/* <Grid
                          item
                          xl={4}
                          md={4}
                          xs={12}
                        >
                          <TableDepositos items={depositos}/>
                        </Grid> */}
            {toggle === 1 ? <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
              <TableRecaudacionAgencia items={[]} />
            </Grid> : null}
            {toggle === 2 ? <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
              <TableRecaudacionSorteo items={detalleSorteo || []}
                setSorteo={setSorteo} loading={loadingSorteo} />
            </Grid> : null}
            {toggle === 3 ? <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
              <TableTotalGeneralAgrupados sorteo={sorteo} loading={loadingSorteoAgrupado}
                items={detalleSorteoAgrupado || []} />
            </Grid> : null}
          </Grid>

          {/* <Grid
                        container
                        justifyContent="space-between"
                        item
                        xl={12}
                        md={12}
                        xs={12}
                        spacing={3}
                        sx={{marginTop: 5}}
                    >
                        <Grid
                            item
                            xl={12}
                            md={12}
                            xs={12}
                            spacing={3}
                        >
                            <TableTotalGeneral items={data?.gerenciaDetalleQuiniela || []} />
                        </Grid>
                    </Grid> */}

          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            sx={{ marginTop: 1 }}
          >
            <Grid
              className="grillaboton"
              container
              item
              spacing={3}
              xl={12}
              md={12}
              xs={12}
            >
              <Grid

                item
                xl={12}
                md={12}
                xs={12}
              >
                <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
                >
                  <Button onClick={() => setToggleTransacciones(1)}
                    variant="contained"
                    className="botones"
                    color="primary" >
                    Transacciones por Producto
                  </Button>
                  <Button onClick={() => setToggleTransacciones(2)}
                    className="botones"
                    variant="contained"
                    color="primary" >
                    Transacciones por UMN
                  </Button>
                </Box>
              </Grid>
            </Grid>


            <Grid
              className="grillabotonRes"
              item
              xl={12}
              md={12}
              xs={12}
            >
              <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
              >
                <Button onClick={() => setToggleTransacciones(1)}
                  variant="contained"
                  className="botones"
                  color="primary" >
                  Transacciones por Producto
                </Button>
              </Box>
            </Grid>
            <Grid
              className="grillabotonRes"
              item
              xl={12}
              md={12}
              xs={12}
            >
              <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}
              >
                <Button onClick={() => setToggleTransacciones(2)}
                  className="botones"
                  variant="contained"
                  color="primary" >
                  Transacciones por UMN
                </Button>
              </Box>
            </Grid>

            {toggleTransacciones === 1 ? <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
              <TableTransaccionesProducto items={dataResumenProducto || []} loading={loadingResumenProducto}
                setUmnSelected={setUmnSelected}
                umnSelected={umnSelected}
                handleSubmit={() => { }}
                agencia={agencia} />
            </Grid> : null}
            {toggleTransacciones === 2 ? <Grid
              item
              xl={12}
              md={12}
              xs={12}
            >
              <TableTransaccionesUMN items={detalleProductoUmn || []}
                setUmnSelected={setUmnSelected}
                umnSelected={umnSelected}
                handleSubmit={() => { }}
                agencia={agencia}
                loading={loadingProductoUmn} />
            </Grid> : null}
          </Grid>
        </ Container>
      </Box>
    </>
  )
}

export default Gerencia;
