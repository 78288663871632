import type { FC } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import "../../../css/estiloGerencia.css";
import {
    Box,
    Card,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    IconButton,
    Grid
} from '@material-ui/core';
import Actions from '../../ActionsAgenciaProducto';
import Scrollbar from '../../Scrollbar';
import ArrowRight from '../../../icons/ArrowRight';
import formatNumbers from '../../../utils/formatNumbers';

interface TableRaspaditStockJuegoProps {
    items: any;
    idProducto?: any;
    RouterLink?: any;
    xlsx?: any;
};

const TableRaspaditStockJuego: FC<TableRaspaditStockJuegoProps> = (props) => {
    const { items, idProducto, xlsx, ...other } = props;
    const totales = {
        tdp: 0,
        agencia: 0,
        restante: 0,
        vendidas: 0,
        noVendidas: 0,
        corredores: 0,
        premiadas: 0
    }

    const tipoJuegoCodigo = {
      '7 de la suerte': '0001',
      'Plata heta': '0004',
      'Tesoro': '0006',
      'Tragamoneda': '0003',
      'Trebol de la suerte': '0002',
      'El Millonario': '0007',
      'Plata heta Pyahu': '0008',
  };

    useEffect(() => { }, [items])

    return (
        <>
            <Card {...other}>
                <CardHeader
                    action={<Actions xlsx={xlsx}
                        items={items?.juego}
                        idProducto={idProducto}
                    />}
                    title="Stock boletas por tipo de juego"
                />
                <Divider />
                <Scrollbar>
                    <Box sx={{ minWidth: 720 }}>
                        <Table stickyHeader
                            className="tableColor"
                            style={{ height: 150 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Juego
                                        </Typography>
                                    </TableCell >
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            En TDP
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            En Agencia
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Restante TDP
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Vendidas
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            No Vendidas
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            En Corredores
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Disponible en Agencia
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Acciones
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items?.juegos.map((item) => {
                                    console.log('raspadita', item)
                                    const codigo = tipoJuegoCodigo[item.tipo_juego];
                                    totales.tdp += parseInt(item?.cartones, 10);
                                    totales.agencia += parseInt(item?.en_agencia, 10);
                                    totales.restante += parseInt((item?.en_tdp), 10);
                                    totales.vendidas += parseInt(item?.vendidas, 10);
                                    totales.noVendidas += parseInt(item?.no_vendidas, 10);
                                    totales.corredores += parseInt(item?.en_corredores, 10);
                                    totales.premiadas += parseInt(item?.dispo_en_agencia, 10);
                                    return <TableRow
                                        key={item.id}
                                    >
                                        <TableCell align="left">
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {item.tipo_juego}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {formatNumbers(item.cartones)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {formatNumbers(item.en_agencia)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                {formatNumbers(item.en_tdp)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                {`${formatNumbers(item.vendidas)}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {`${formatNumbers(item.no_vendidas)}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {`${formatNumbers(item.en_corredores)}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {`${formatNumbers(item.dispo_en_agencia)}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Grid
                                                display='flex'
                                                direction='row'
                                                justifyContent="flex-end"
                                            >
                                                <Button
                                                    component={RouterLink}
                                                    to={`/stock/raspadita-tipo-juego/agencias/${codigo}`}
                                                    sx={{ maxHeight: 10 }}
                                                >
                                                    <IconButton>
                                                        <ArrowRight
                                                            fontSize="small"
                                                            style={{ color: "blue" }}
                                                        />
                                                    </IconButton>
                                                </Button>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                }
                                )}
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            variant="h6"
                                        >
                                            Total
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            color='textPrimary'
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.tdp)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            color='textPrimary'
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.agencia)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            color='textPrimary'
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.restante)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            color='textPrimary'
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.vendidas)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.noVendidas)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.corredores)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {formatNumbers(totales.premiadas)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Grid
                                        display='flex'
                                        direction='row'
                                        justifyContent="flex-end"
                                      >
                                        <Button
                                            component={RouterLink}
                                            to="/stock/raspadita-tipo-juego/agencias/0000"
                                            sx={{ maxHeight: 10 }}
                                          >
                                          <IconButton>
                                            <ArrowRight
                                              fontSize="small"
                                              style={{ color: "blue" }}
                                            />
                                          </IconButton>
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Scrollbar>
            </Card>
        </>)
};

TableRaspaditStockJuego.propTypes = {
    items: PropTypes.array.isRequired,
    idProducto: PropTypes.any,
    xlsx: PropTypes.any
};

export default TableRaspaditStockJuego;
