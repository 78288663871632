import type { FC } from 'react';
import PropTypes from 'prop-types'
import dayjs from "dayjs";
import TableDetailEntregas from './TableDetailEntregas';

interface TableDetailProps {
  data?: any;
  tableTitle?: any;
  title?: string;
  handleOpen?: any;
  form?: any;
  searchByNumber?: any;
  searchByCI?: any;
  handleSubmit?: any;
}

const TableDetail: FC<TableDetailProps> = (props) => {
  const { data, form, searchByNumber, searchByCI, handleSubmit, tableTitle, title, handleOpen } = props;

  interface tableDataEntregas {
    nro_corredor?: any;
    corredor?: any;
    monto?: any;
    estado?: any;
    id?: any;
    ag?: any;
    umn?: any;
    fcreacion?: any;
    fconfimacion?: any;
  }
  const tableDataEntregasArray: tableDataEntregas[] =
    data.entregas.map((page) => (
      {
        nro_corredor: page?.Corredor?.numero,
        corredor: `${page?.Corredor?.Usuario?.Person?.nombre}, ${page?.Corredor?.Usuario?.Person?.apellido}`,
        monto: page?.monto,
        estado: page?.estado,
        ag: page?.Corredor?.Umn?.agencia_numero,
        umn: page?.Corredor?.Umn?.numero,
        corredor_id: page?.Corredor?.id,
        id: page?.id,
        fcreacion: `${dayjs(page?.created_at).format("DD/MM/YYYY HH:mm:ss")}`,
        fconfimacion: `${dayjs(page?.updated_at).format("DD/MM/YYYY HH:mm:ss")}`
      }
  ))
  return (
    <TableDetailEntregas
      tableDataEntregasArray={tableDataEntregasArray}
      handleSubmit={handleSubmit}
      searchByNumber={searchByNumber}
      form={form}
      searchByCI={searchByCI}
      title={title}
      tableTitle={tableTitle}
      handleOpen={handleOpen}
   />
  );
}
TableDetail.propTypes = {
  data: PropTypes.array,
  tableTitle: PropTypes.array,
  title: PropTypes.string,
  handleOpen: PropTypes.any,
  form: PropTypes.any,
  searchByNumber: PropTypes.any,
  searchByCI: PropTypes.any,
  handleSubmit: PropTypes.any,
};
export default TableDetail;
