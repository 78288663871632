import {
    Box
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { UMNContext } from 'src/contexts/UMNContext';
import { RoleContext } from 'src/contexts/RoleContext';
import { registerData, getDataQueryParams } from 'src/serviceTool/serviceCommon';
import InventarioDetalle from './inventarioDetalle';
import InventarioTable from './inventarioTable';
import InventoryAdd from './inventarioAdd';

const InventoryTool = () => {
    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [value, setValue] = useState('');
    const [books, setBooks] = useState({ book: [] });
    const [error, setError] = useState(null);
    const [libroError, setLibroError] = useState(false);
    const [LibroErrorMessage, setLibroErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [dataDetail, setDataDetail] = useState([]);
    const [cardTitle, setCardTitle] = useState('');
    const [gameType, setGameType] = useState('');

    const { UMN } = useContext(UMNContext)
    const { role } = useContext(RoleContext)
    const umnId = UMN;

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const url = `api/v1/inventario/register`;
        const param = {
            umn_id: umnId,
            gourped: false
        }

        getDataQueryParams(url, token, param)
            .then(res => {
                const { data }: any = res;
                setDataGrid(data);
                setError(null);
            }).catch(err => {
                console.log(err);
            })
    }, [umnId, refresh]);

    const sortClick = (key: string) => {
        const newData = dataGrid.sort((a, b) => {
            if (a[key] > b[key]) {
                return 1;
            }
            if (a[key] < b[key]) {
                return -1;
            }
            return 0;
        });
        setDataGrid(newData);
    }

    // Agregar inventario
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSendBarcode = () => {
        const token = localStorage.getItem('accessToken');
        const url = `api/v1/inventario/${umnId}/register`;
        const model = { code_list: [...books.book] };
        registerData(url, model, token)
            .then(res => {
                console.log(res)
                setBooks({ book: [] });
                setValue('');
                setLibroError(false);
                setLibroErrorMessage('');
                setError(null);
                setOpen(true);
                setRefresh(!refresh)
            }
            )
            .catch(err => {
                console.log(err)
            }
            )
    }

    const handleAddItem = () => {
        const cod = value;
        if (libroError === true) setLibroError(false)
        if (value.trim() === '') {
            setLibroError(true)
            setLibroErrorMessage('Debe introducir el codigo de barras')
            return;
        }
        if (cod.length < 10) {
            setLibroError(true)
            setLibroErrorMessage('El codigo de barras no contiene la longitud de caracteres suficientes.')
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(Number(cod))) {
            setLibroError(true)
            setLibroErrorMessage('El codigo debe ser numerico')
            return;
        }
        if (books.book.includes(cod)) {
            setLibroError(true)
            setLibroErrorMessage('El código que se ingreso ya se encuentra en la lista de cargas mas abajo')
            setValue('')
            return;
        }
        setBooks({ book: [...books.book, cod] });
        setValue('')
    };

    const handleChange = (event) => {
        const code = event?.target?.value?.trim();
        setValue(code);
        console.log(code.length)
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (code.length === 14) {
            handleAddItem();
        }
    };

    const handleRemoveBox = (book: any) => {
        const arr = books.book.filter((item: any) => item !== book);
        setBooks({ book: arr });
    }

    // Inventario Detalle por Estado

    const onClickCell = (row: any, column: any) => {
        const token = localStorage.getItem('accessToken');
        const url = `api/v1/inventario/register/${column.toLowerCase()}?codigo_juego=${row.id}`;

        if (column !== 'tipo_juego') {
            const param = {
                umn_id: umnId
            }
            getDataQueryParams(url, token, param)
                .then(res => {
                    const { data }: any = res;
                    setDataDetail(data);
                    setOpenDetail(true);
                    if (column === 'stock') {
                        column = 'escaneados';
                    }
                    setCardTitle(`${column}`)
                    if (row.id === '0001') {
                        setGameType('7 de la Suerte')
                    }
                    else {
                        setGameType('Tragamonedas')
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const onCloseDetail = () => {
        setOpenDetail(false);
        setDataDetail([]);
    }


    return (
        <>
            {!role?.includes('presidencia', 'tdp_gerencia') &&
                <Box>
                    <InventoryAdd
                        handleClose={handleClose}
                        handleChange={handleChange}
                        handleSendBarcode={handleSendBarcode}
                        errorState={error}
                        umnIdContext={umnId}
                        openState={open}
                        booksData={books}
                        handleRemoveBox={handleRemoveBox}
                        handleAddItem={handleAddItem}
                        ErrorMessageState={LibroErrorMessage}
                        valueState={value}
                        libroErrorState={libroError} />
                </Box>
            }
            <Box>
                <InventarioTable
                    onClickCell={onClickCell}
                    dataGrid={dataGrid}
                    sortClick={sortClick}
                    roles={role} />
            </Box>
            {openDetail &&
                <Box>
                    <InventarioDetalle
                        close={onCloseDetail}
                        dataDetail={dataDetail}
                        cardTitle={cardTitle}
                        gameType={gameType}
                        sortClick={sortClick}
                        roles={role}
                    />
                </Box>
            }
        </>
    );
}

export default InventoryTool;