import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import formatNumbers from 'src/utils/formatNumbers'
// import MoreMenu from '../../../../MoreMenu';
import Scrollbar from "../../../Scrollbar"

interface ReporteProps {
  items: any;
}

const TableCalificacionApuesta: FC<ReporteProps> = (props) => {
  const { items, ...other } = props;

    const totalCantApuestas = items?.data?.getClasificacionDeApuestas.reduce((acc, currentItem) => acc + Number(currentItem.cant_apuestas), 0);
    const totales = {total_apostado: 0, total_ganado: 0, cant_apuestas: 0, cant_ganados: 0, share: 0,  porcentajeGanado: 0, porcentajePremiacion: 0}
    items?.data?.getClasificacionDeApuestas.forEach(item => {
        totales.total_apostado += Number(item.total_apostado);
        totales.total_ganado += Number(item.total_ganado);
        totales.cant_apuestas += Number(item.cant_apuestas);
        totales.cant_ganados += Number(item.cant_ganados);
      }); 
        totales.porcentajePremiacion += Number((totales.cant_ganados / totales.cant_apuestas)) * 100;
        totales.share = (totales.cant_apuestas / totalCantApuestas) * 100;
        totales.porcentajeGanado = Number((totales.cant_ganados / totales.cant_apuestas)) * 100;
        
  return (
    <>
      <Card {...other}>
        <CardHeader
          // action={<MoreMenu />}
          title="Clasificación de apuestas"
        />
        <Divider />
        <Scrollbar>
          <Box>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Sorteo
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ color: 'white', whiteSpace: 'nowrap' }}
                    >
                      Total Apostado
                    </Typography>
                  </TableCell>
                 <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ color: 'white', whiteSpace: 'nowrap' }}
                    >
                        Total Ganado
                    </Typography>
                  </TableCell>
                                   <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ color: 'white', whiteSpace: 'nowrap' }}
                    >
                        % de Premiación 
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ color: 'white', whiteSpace: 'nowrap' }}
                    >
                        Cant apuestas
                    </Typography>
                  </TableCell>
                 <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ color: 'white', whiteSpace: 'nowrap' }}
                    >
                        Share
                    </Typography>
                  </TableCell>
                        <TableCell align='right'>
                        <Typography
                        color="textPrimary"
                        variant="h6"
                        sx={{ color: 'white' }}
                        >
                            Cant de ganados
                        </Typography>
                    </TableCell>
                     <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{color: 'white', whiteSpace: 'nowrap' }}
                    >
                        % Cant de ganados
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.data?.getClasificacionDeApuestas.map((item: any) => (
                    <TableRow
                    hover
                    key={item?.sorteo}
                    sx={{backgroundColor: "inherit"}}
                    >
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                          sx={{fontWeight: 'bold'}}
                          >
                          {item.sorteo}
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                          variant="subtitle1"
                          align='right'
                          >
                         {formatNumbers(item?.total_apostado)}
                        </Typography>
                      </TableCell>
                    <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                          align='right'
                          >
                         {formatNumbers(item?.total_ganado)}
                        </Typography>
                        
                      </TableCell>
                     <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                         
                          variant="subtitle1"
                          align='right'
                          >
                          {((item.total_ganado / item.total_apostado) * 100).toFixed()} %
                        </Typography>
                        
                      </TableCell>
                                           <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                     
                          variant="subtitle1"
                          align='right'
                          >
                         {/* {items?.data?.getAnalisisDeConsistencia[0]?.[item?.property]} */}
                          {item.cant_apuestas}
                        </Typography>
                        
                      </TableCell>
                                     <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                        
                          variant="subtitle1"
                          align='right'
                          >
                         {/* {items?.data?.getAnalisisDeConsistencia[0]?.[item?.property]} */}
                           {((item.cant_apuestas / totalCantApuestas) * 100).toFixed()} %
                        </Typography>
                        
                      </TableCell>
                      
                                                                 <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                         
                          variant="subtitle1"
                          align='right'
                          >
                         {/* {items?.data?.getAnalisisDeConsistencia[0]?.[item?.property]} */}
                          {item.cant_ganados}
                        </Typography>
                        
                      </TableCell>
                                       
                                                                 <TableCell align='right'>
                        <Typography
                          // eslint-disable-next-line no-nested-ternary
                        
                          variant="subtitle1"
                          align='right'
                          >
                         {/* {items?.data?.getAnalisisDeConsistencia[0]?.[item?.property]} */}
                          {((item.cant_ganados/item.cant_apuestas) * 100).toFixed(2)} %
                        </Typography>
                        
                      </TableCell>
                   
                    </TableRow>
                ))}
                {items.data?.getClasificacionDeApuestas.length > 0 && (
                    <TableRow
                  hover
                  // key must be random
                  key={Math.random()}
                  sx={{backgroundColor: "inherit"}}
                >
                  {/* <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                      sx={{ fontWeight: "bold" }}
                    >
                      Saldos
                    </Typography>
                  </TableCell> */}
                 <TableCell>
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Grand Total
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.total_apostado)}
                       
                    </Typography>
                  </TableCell>
                                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.total_ganado)}
                       
                    </Typography>
                  </TableCell>
                                               <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {((totales.total_ganado / totales.total_apostado) * 100).toFixed()} %
                       
                    </Typography>
                  </TableCell>
                                                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.cant_apuestas)}
                       
                    </Typography>
                  </TableCell>
                                                   <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}
                    >
                            {formatNumbers(totales.share)} %
                       
                    </Typography>
                  </TableCell>
                                                   <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.cant_ganados)}
                       
                    </Typography>
                  </TableCell>
                                                    <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {Number(totales.porcentajeGanado).toFixed(2)} %
                       
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

TableCalificacionApuesta.propTypes = {
  items: PropTypes.any,
};

export default TableCalificacionApuesta;
