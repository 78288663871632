import axios from "axios";
import qs from "qs";



export function getData(url: any, token: any): Promise<any[]> {
  const res: any = axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/${url}`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  });

  return res
}

export function registerData(url: any, model: any, token: any): Promise<any[]> {
  const res: any = axios.post(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/${url}`, model, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  });

  return res
}

export function getDataQueryParams(url: any, token: any, param: any): Promise<any[]> {
  console.log('param', param)
  const res: any = axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/${url}`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })

  });

  return res
}

export async function productHabilitados(token: any, umns: any): Promise<any[]> {
  const param = {
    umns_ids: umns
  }

  const res: any = await axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/productos/corredores`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  });

  return res
}

export async function productEdit(token: any, data: any, corredor_id: any): Promise<any[]> {
  console.log(token)
  const res: any = await axios.put(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/productos/corredores/${corredor_id}`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  })
  return res;
}

export async function productEditAll(token: any, data: any, umns: any): Promise<any[]> {
  const param = {
    umns_ids: umns
  }
  const res: any = await axios.put(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/productos/corredores`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  })
  return res;
}

export async function productosComision(token: any, agencia_nro: any): Promise<any[]> {
  const res: any = await axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/comisiones/comisiones?agencia_numero=${agencia_nro}`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
  })
  return res?.data;
}

export async function productosComisionEdit(token: any, data: any): Promise<any[]> {
  const res: any = await axios.put(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/comisiones/comisiones`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  })
  return res;
}

export async function addComisionProducto(token: any, data: any): Promise<any[]> {
  const res: any = await axios.post(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/comisiones/comisiones`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
  })
  return res;
}

export async function fixedComision(token: any, data: any) {
  const res: any = await axios.put(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/comisionescomisiones/fix`, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
  })
  return res;
}

export async function getProductos(token: any): Promise<any[]> {
  const res: any = await axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/productos`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
  })
  return res?.data;
}

export async function getEstadoRecarga(token: any, param: any, pg: any): Promise<any[]> {
  const res: any = await axios.get(`https://apiraspaditav2prod.tedepasa.com/api/pedido?page=${pg}&limit=10`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  })
  return res?.data;
}

export async function getEstadoRecargaAll(token: any, param: any): Promise<any[]> {
  const res: any = await axios.get(`https://apiraspaditav2prod.tedepasa.com/api/pedido/totales`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  })
  return res?.data;
}

export async function getCubridas(token: any, inicio: any, fin: any, ag: any, co: any, pages: any): Promise<any[]> {
  let param = {}

  if (ag === 0) {
    param = {
      desde: inicio,
      hasta: fin,
      corredor: !co ? 0 : co
    }
  } else {
    param = {
      desde: inicio,
      hasta: fin,
      corredor: !co ? 0 : co,
      agencia: ag
    }
  }



  const res: any = await axios.get(`https://apiraspaditav2prod.tedepasa.com/api/cubridas?page=${pages}&limit=100`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  })
  return res?.data;
}

export async function getTotalCubridas(token: any, inicio: any, fin: any, ag: any, co: any): Promise<any[]> {
  let param = {};
  if (ag === 0) {
    param = {
      desde: inicio,
      hasta: fin,
      corredor: !co ? 0 : co
    }
  } else {
    param = {
      desde: inicio,
      hasta: fin,
      agencia: ag,
      corredor: !co ? 0 : co
    }
  }
  const res: any = await axios.get(`https://apiraspaditav2prod.tedepasa.com/api/cubridas/totales`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    },
    params: param,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
  })
  return res?.data;
}

export async function getCuotasAgenciasTeete (agencia: any, page: any, solicitud?: any, estado?: any): Promise<{ data: any[], count: any }> {
  if (solicitud && estado) {
    const res: any = await axios.get(`https://asgard.tedepasa.com/api/cuotas/get_cuotas_agencia/?agencia_numero=${agencia}&page=${page}&solicitud=${solicitud}&estado=${estado}`, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Token 76e93021f2a099c47181d8974f17892a776514d4'
      },
    })
    return {
      data:  res?.data?.results,
      count: res?.data?.count
    }
  }
  const res: any = await axios.get(`https://asgard.tedepasa.com/api/cuotas/get_cuotas_agencia/?agencia_numero=${agencia}&page=${page}`, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'Authorization': 'Token 76e93021f2a099c47181d8974f17892a776514d4'
    },
  })
  return {
    data:  res?.data?.results,
    count: res?.data?.count
  }
}

export async function pagarCuotasTeete (pago: any): Promise<any[]> {
  try {
    const res: any = await axios.post(`https://asgard.tedepasa.com/api/cuotas/pagar_cuota_agencia/`, {
      banco: pago.banco,
      comprobante: pago.comprobante,
      monto_total: pago.monto_total,
      cuotas: pago.cuotas
    }, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': 'Token 76e93021f2a099c47181d8974f17892a776514d4'
      },
    })
    return res?.data;
  }
  catch (error) {
    return error;
  }
}
