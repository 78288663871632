import { useRef, useState, memo } from 'react';
import type { FC } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types'
import { FaFileCsv, FaFilePdf } from 'react-icons/fa'
import DotsHorizontalIcon from '../../icons/DotsHorizontal';
import { exportToCVS } from '../../utils/exportToCSV';
import { exportToPDF } from '../../utils/exportToPDF';

interface ExportProps {
    pdf?: boolean;
    csv?: boolean;
    pdfData?: {
      fontSize?: number;
      title?: string;
      headers: string[];
      data: any[];
      downloadTitle?: string;
    }
    csvData?: {
      data: any;
      downloadTitle?: string;
    };
 }

const Export: FC<ExportProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { pdf, pdfData, csv, csvData } = props;

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton
          onClick={handleMenuOpen}
          ref={anchorRef}
          {...props}
        >
          <DotsHorizontalIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 256,
            marginTop:6
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        {
          pdf ?
          <MenuItem onClick={() => exportToPDF(pdfData)}>
            <ListItemIcon>
              <FaFileCsv fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary="Exportar a PDF" />
          </MenuItem>
          : null
        }
        {
          csv ?
          <MenuItem onClick={() => exportToCVS(csvData)}>
            <ListItemIcon>
              <FaFilePdf fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary="Exportar a CSV" />
          </MenuItem>
          : null
        }
      </Menu>
    </>
  );
};

Export.propTypes = {
  pdf: PropTypes.bool,
  pdfData: PropTypes.any,
  csv: PropTypes.bool,
  csvData: PropTypes.any
};

export default memo(Export);
