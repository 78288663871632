import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types'

interface ModalContextInterface {
    openModalWidget: any;
    setOpenModalWidget: any;
    closeModalWidgetFN: any;
    openModalWidgetFN: any;
    passphrase: any;
    setPassphrase: any;
    message: any;
    setMessage: any;
    openDialog: any;
    setOpenDialog: any;
  }

export const ModalWidgetContext = createContext<ModalContextInterface>({
    openModalWidget: false,
    passphrase: '',
    message: '',
    openDialog: false,
    setPassphrase: () => {},
    setOpenModalWidget: () => {},
    closeModalWidgetFN: () => {},
    openModalWidgetFN: () => {},
    setMessage: () => {},
    setOpenDialog: () => {},

});

export const ModalWidgetProvider = (props) => {
	const { children } = props;
  const [openModalWidget, setOpenModalWidget] = useState(false);
  const [passphrase, setPassphrase] = useState('')
  const [message, setMessage] = useState('')
	const [openDialog, setOpenDialog] = useState(false)


    const closeModalWidgetFN = (passphraseprop) => {
        setOpenModalWidget(false)
        setPassphrase(passphraseprop)
    }
    const openModalWidgetFN = (passphraseprop) => {
        setOpenModalWidget(true)
        setPassphrase(passphraseprop)
    }

	useEffect(() => {
	}, [openModalWidget, message, openDialog])

  return (
    <ModalWidgetContext.Provider
      value={{
        openModalWidget,
        passphrase,
        message,
        openDialog,
        setPassphrase,
        setOpenModalWidget,
        closeModalWidgetFN,
        openModalWidgetFN,
        setMessage,
        setOpenDialog
      }}
    >
      {children}
    </ModalWidgetContext.Provider>
  );
};

ModalWidgetProvider.propTypes = {
  children: PropTypes.node.isRequired
};
