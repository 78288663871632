import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';

import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import RoleBasedGuard from "./components/RoleBasedGuard";

import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import PlanillaGeneralRaspaGerencia from './pages/dashboard/gerencia/productos/PlanillaGeneralRaspaGerencia';
import Inventario from './pages/dashboard/gerencia/raspadita/Inventario';
import InventarioTablePresidencia from './components/dashboard/raspaditas/inventory/inventarioTablePresidencia';
import InvestigadorQweb from './pages/dashboard/gerencia/quinielaweb/gadget/InvestigadorQweb';
import WesterUnionPersonaFisica  from './components/dashboard/wu/WesterUnionPersonaFisica';


// import TableCubridasDetalle from './components/dashboard/gerencia/cubridas/cubridas-detalle';


const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);



// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));


// Dashboard pages
const SorteoDetails = Loadable(lazy(() => import('./pages/dashboard/SorteoDetails')));
const SorteoDetailsPendientes = Loadable(lazy(() => import('./pages/dashboard/SorteoDetailsPendientes')));

// Gerencia
const Gerencia = Loadable(lazy(() => import('./pages/dashboard/Gerencia')))
const GerenciaAgencias = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgencias')))
const GerenciaAgenciasProducto = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasProducto')))
const GerenciaAgenciasUmnProducto = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmnProducto')))
const GerenciaAgenciasProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasProductoCorredor')))
const GerenciaAgenciasUmnProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmnProductoCorredor')))
const GerenciaAgenciasCorredorProductos = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasCorredorProductos')))
const GerenciaDetallePorTransaccion = Loadable(lazy(() => import('./pages/dashboard/GerenciaDetallesPorTransaccion')))
const GerenciaAgenciasUmn = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmns')))
const GerenciaDetailticket = Loadable(lazy(() => import('./pages/dashboard/GerenciaDetailTickets')))
const PlanillaPremiadosSorteoGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosSorteoGerencia')));
const PremiadosGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/premios/GerenciaPremios')))
// Quiniela Web
const RecaudacionesPorSorteo = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/Recaudaciones')));
const UserTickets = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/UserTickets')));
const UserBets = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/UserHistoryBets')));
const General = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/General')));
const SaldoApuesta = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/SaldoApuesta')));
const SaldoGanado = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/SaldoGanado')));
const Recarga = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/saldos/Recarga')));
const Transferencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/saldos/Transferencia')));
const RecargaByUser = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/saldos/RecargaByUser')));
const TransferenciaByUser = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/saldos/TransferenciaByUser')));
const PremiosByUser = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/premios/PremiosByUser')));
const OperativaPromociones = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/operativa/promociones')))
// Agencia
const PlanillaGeneral = Loadable(lazy(() => import('./pages/dashboard/PlanillaGeneral')));
const PlanillaAgencias = Loadable(lazy(() => import('./pages/dashboard/PlanillaAgencias')));
const Planilla = Loadable(lazy(() => import('./pages/dashboard/Planilla')));
const PlanillaPremiados = Loadable(lazy(() => import('./pages/dashboard/Premiados')));
const Boletas = Loadable(lazy(() => import('./pages/dashboard/Boletas')));
const PlanillaPremiadosGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosGerencia')));
const PlanillaPremiadosGerenciaAgrupados = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosGerenciaAgupados')));
const PlanillaPremiadosGerenciaUmn = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosGerenciaUmn')));
const PlanillaPremiadosCorredorGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosCorredorGerencia')));
const SorteosBase = Loadable(lazy(() => import('./pages/dashboard/SorteosBase')));
const Sorteos = Loadable(lazy(() => import('./pages/dashboard/Sorteos')));
const RaspaditaPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditaPremios')));
const Limitados = Loadable(lazy(() => import('./pages/dashboard/Limitados')));
const CorredoresDataList = Loadable(lazy(() => import('./pages/dashboard/CorredorDataList')));
const CorredoresDatailsFecha = Loadable(lazy(() => import('./pages/dashboard/CorredorDetailsFecha')));
const UserList = Loadable(lazy(() => import('./pages/dashboard/UsersList')));
const DeviceList = Loadable(lazy(() => import('./pages/dashboard/DeviceList')));
const TotalTerminales = Loadable(lazy(() => import('./pages/dashboard/TotalTerminales')));
const DeviceDetails = Loadable(lazy(() => import('./pages/dashboard/DeviceDetails')));
const Entregas = Loadable(lazy(() => import('./pages/dashboard/Entregas')));
const CajasAbiertas = Loadable(lazy(() => import('./pages/dashboard/CajasAbiertas')));
const Anulaciones = Loadable(lazy(() => import('./pages/dashboard/Anulaciones')));
const AnulacionesPremios = Loadable(lazy(() => import('./pages/dashboard/AnulacionesPremios')));
const Profile = Loadable(lazy(() => import('./pages/dashboard/Profile')));
const AgenciesPlanillaGeneral = Loadable(lazy(() => import('./pages/dashboard/AgenciesPlanillaGeneral')))
const TerminalSerial = Loadable(lazy(() => import('./pages/dashboard/TerminalSerial')))
// const ProfileDetailsFecha = Loadable(lazy(() => import('./pages/dashboard/ProfileDetailsFecha')));
const ProfileCobrador = Loadable(lazy(() => import('./pages/dashboard/ProfileCobrador')));
const PlanillaGeneralEntregaCobrador = Loadable(lazy(() => import('./pages/dashboard/PlanillaGeneralEntregaCorredor')))
// Agencia
const EstadosCorredor = Loadable(lazy(() => import('./pages/dashboard/EstadosCorredor')))
const Tickets = Loadable(lazy(() => import('./pages/dashboard/Tickets')))
const Billetaje = Loadable(lazy(() => import('./pages/dashboard/Billetaje')))
const BilletajeGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/BilletajeGerencia')))
const PlanillaGerenciaAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/PlanillaGeneralAgencia')))
const PlanillaGerenciaUmn = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/PlanillaGeneralUmn')))
const PlanillaTotalStockJuego = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaTotalStockJuego')))
const PlanillaTotalStockJuegoDias = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaTotalStockJuegoDias')))
const PlanillaTotalStockJuegoDiasAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaTotalStockJuegoDiasAgencias')));
const RaspaditaImportarJuego = Loadable(lazy(() => import('./pages/dashboard/RecepcionJuegoRaspadita')));
const PlanillaGerenciaRaspadita = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaTotalJuego')))
const StockGeneralRaspadita = Loadable(lazy(() => import('./pages/dashboard/StockGeneralRaspadita')))
const Jugadas = Loadable(lazy(() => import('./pages/dashboard/Jugadas')))
const PlanillaCobros = Loadable(lazy(() => import('./pages/dashboard/PlanillaCobros')))
const PlanillaCobros2 = Loadable(lazy(() => import('./pages/dashboard/PlanillaCobros2')))
const PlanillaProducto = Loadable(lazy(() => import('./pages/dashboard/PlanillaProducto')))
const PlanillaProductoByUmn = Loadable(lazy(() => import('./pages/dashboard/PlanillaProductoByUmn')))
const PlanillaRaspaditaByAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaRaspaditaByAgencia')));
const TicketsCorredor = Loadable(lazy(() => import('./pages/dashboard/TicketsCorredor')));
const DetallesProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/DetallesProductoCorredor')));
const UsuariosExternos = Loadable(lazy(() => import('./pages/dashboard/corredores/UsuariosExternos')));
const ProductosHabilitados = Loadable(lazy(() => import('./pages/dashboard/ProductosHabilitados')));
// Raspadita
const RaspaditasCajas = Loadable(lazy(() => import('./pages/dashboard/RaspaditasCajas')))
const RaspaditasPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditasPremios')))
const RaspaditaBoletasPremiadas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaBoletasPremiadas')))
const RaspaditaBoletasNoPremiadas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaBoletasNoPremiadas')));
const RaspaditaBoletasDiferenciaPremiadas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaBoletasDiferenciaPremiadas')));
const RaspaditaRegistrarPremio = Loadable(lazy(() => import('./pages/dashboard/RaspaditasRegistrarPremioV1')));
const RaspaditasLibros = Loadable(lazy(() => import('./pages/dashboard/RaspaditaLibros')))
const RaspaditasStock = Loadable(lazy(() => import('./pages/dashboard/RaspaditasStock')))
const RaspaditaCorredoresBoletasAsignadas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasAsignadas')))
const RaspaditaBoletasVendidasTipoJuego = Loadable(lazy(() => import('./pages/dashboard/RaspaditaBoletasVendidasTipoJuego')))
const RaspaditaCorredoresBoletasAsignadasDias = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasAsignadasDias')));
const RaspaditaCorredoresBoletasAsignadasLista = Loadable(lazy(() => import('./pages/dashboard/OperativaCorredoresBoletasAsignadasLista')))
const RaspaditaTableEstadistica = Loadable(lazy(() => import('./pages/dashboard/EstadisticaVentasTipoJuego')))
const InformeLibrosVendidosRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteLibrosVendidosRaspadita')));
const AnulacionesRaspadita = Loadable(lazy(() => import('./pages/dashboard/OperativaReversion')));
const InformeLibrosAsignadosRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteLibrosAsignadosRaspadita')));
const CajasLogisticaRaspadita = Loadable(lazy(() => import('./pages/dashboard/stock/CajasLogisticaRaspadita')));
const InformeLibrosVendidosRaspaditaAgencias = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteLibrosVendidosRaspaditaAgencias')));
const StockPallets = Loadable(lazy(() => import('./pages/dashboard/stock/StockPallets')));
const StockCajas = Loadable(lazy(() => import('./pages/dashboard/stock/StockCajas')));
const StockLibros = Loadable(lazy(() => import('./pages/dashboard/stock/StockLibros')));
const StockBoletas = Loadable(lazy(() => import('./pages/dashboard/stock/StockBoletas')));
const RaspaditaCorredoresBoletasAsignadasGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/RaspaditaCorredoresBoletasAsignadasGerencia')))
const RaspaditaCorredoresDiasBoletasAsignadasGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/RaspaditaCorredoresDiasBoletasAsignadasGerencia')));
const RaspaditaCorredoresDiasBoletasAsignadasAgencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/RaspaditaCorredoresDiasBoletasAsignadasAgencias')));
const RaspaditaCorredoresBoletasVendidas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasVendidas')))
const RaspaditaOperativaCorredoresBoletasVendidas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaOperativaCorredoresBoletasVendidas')))
const RaspaditaDifenciaPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditaDiferenciaPremios')));
const RaspaditasCorredor = Loadable(lazy(() => import('./pages/dashboard/RaspaditasCorredor')))
const RaspaditasReasignacionCorredor = Loadable(lazy(() => import('./pages/dashboard/RaspaditasReasignacionCorredor')))
const RaspaditaUMN = Loadable(lazy(() => import('./pages/dashboard/RaspaditaUMN')))
const RaspaditaDevolucionLibro = Loadable(lazy(() => import('./pages/dashboard/RaspaditaDevolucionLibro')))
const RaspaditasDetallesCaja = Loadable(lazy(() => import('./pages/dashboard/RaspaditasDetallesCaja')))
const RaspaditaDetallesLibro = Loadable(lazy(() => import('./pages/dashboard/RaspaditaDetallesLibro')))
// Depositos
const Depositos = Loadable(lazy(() => import('./pages/dashboard/depositos/Depositos')))
// Stock
const StockTerminal = Loadable(lazy(() => import('./pages/dashboard/stock/StockTerminal')))
const HistorialTerminales = Loadable(lazy(() => import('./pages/dashboard/stock/HistorialTerminales')))
// Pago de premios
const PagoPremios = Loadable(lazy(() => import('./pages/dashboard/premios/PremiosPagados')))
// Finanzas
const FinanzasResumenGeneral = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenGeneral')));
const FinanzasGerencia = Loadable(lazy(() => import('./pages/dashboard/FinanzasGerencia')));
const FinanzasGerenciaDetalle = Loadable(lazy(() => import('./pages/dashboard/FinanzasGerenciaDetalle')));
// const FinanzasResumenAjustes = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenAjustes')));
// const FinanzasResumenDepositos = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenDepositos')));
// Quinela
const Ajustes = Loadable(lazy(() => import('./pages/dashboard/Ajustes')))
const AjustesUmn = Loadable(lazy(() => import('./pages/dashboard/AjustesUmn')))
// Extractos UMN
const Deposito = Loadable(lazy(() => import('./pages/dashboard/Deposito')));
const ExtractoUMN = Loadable(lazy(() => import('./pages/dashboard/ExtractoUMN')));
// Conajzar
const InformeCuentaJugador = Loadable(lazy(() => import('./pages/dashboard/conajzar/InformeCuentaJugador')));
const ReporteFacturacionAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacionAgencia')));
const ReportePagoDePremios = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePagoDePremios')));
const ReportePagoPremiosSuperiores = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePagoPremiosSuperiores')));
const InformeLiquidaciones = Loadable(lazy(() => import('./pages/dashboard/conajzar/InformeLiquidaciones')));
const InformeFacturacionRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacionRaspadita')));
const InformeFacturacionRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/ReporteFacturacionRaspadita')));
const InformePremiadosRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePremiadosRaspadita')));
const InformePremiadosRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePremiadosRaspaditaGerencia')));
const GanadoresPorAgenciaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/GanadoresPorAgenciaGerencia')));
const VentasPorAgenciaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorAgenciaGerencia')));
const GanadoresPorCorredorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/GanadoresPorCorredorAgencia')));
const VentasPorCorredorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorCorredorAgencia')));
const ReportesDelSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportesDelSistema')));
const VentasPorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorAgencia')));
const VentasPorMediosElectronicos = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorMediosElectronicos')));
const VentasPorTerminal = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorTerminal')));
const AnulacionesConajzar = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/Anulaciones')));
const CambiosParametrosSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/CambiosParametrosSistema')));
const Heartbeat = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/Heartbeat')));
const IntentosLogin = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/IntentosLogin')));
const RegistroDeAsistencias = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/RegistroDeAsistencias')));
const UpdateSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/UpdateSistema')));
const UpdateSoftware = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/UpdateSoftware')));
const PremiosPagados = Loadable(lazy(() => import('./pages/dashboard/premios/PremiosPagados')));
const AuditoriaFirewall = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AuditoriaFirewall')));
const AccesoRemoto = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AccesoRemoto')));
const AbortoApuestas = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AbortoApuestas')));
const CambiosPosibilidades = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/CambiosPosibilidades')));
const ErrorIncompatibilidad = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/ErrorIncompatibilidad')));
const EstacionDeApuestas = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/EstacionDeApuestas')));
const ReporteFacturacionDiaria = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacion')));
// Gerencia
const Sherlock = Loadable(lazy(() => import('./pages/dashboard/gerencia/Sherlock')));
const BoletasPorMonto = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/BoletasPorMonto')));
const HistorialDeMontos = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/HistorialDeSorteo')));
const Ocurrencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/Ocurrencias')));
const OcurrenciasGraficos = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/OcurrenciasGraficos')))
const TombolaOcurrencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/TombolaOcurrencias')));
const Bolillero = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/Bolillero')));
const AsignacionesGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/AsignacionesGerencia')));
const MovimientoFinancieroGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/MovimientoFinancieroGerencia')));
const StockRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/StockGerencia')));
const DetalleVentaLibro = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/DetalleVentaLibro')));
const DetalleAsignacionLibro = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/DetalleAsignacionLibro')));
const DetalleVentaLibroAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/DetalleVentaLibroAgencia')));
const StockRaspaditaGerenciaDiasAsignacion = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/StockGerenciaDiasAsignacion')));
const PlanillaVentasPorCorredor = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaVentasPorCorredor')))
const PlanillaAsignadasPorCorredor = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaAsignadasPorCorredor')))
const PlanillaVentasPorCorredorAgencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaVentasPorCorredorAgencias')))
const PlanillaAsignadasPorCorredorAgencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaAsignadasPorCorredorAgencias')))
const PlanillaVentasPorCorredorByCorredor = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaVentasPorCorredorByCorredor')))
const PlanillaAsignadasPorCorredorByCorredor = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaAsignadasPorCorredorByCorredor')))
const StockRaspaditaDiasAsignacionAgencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/StockGerenciaDiasAsignacionAgencias')));
const ComisionProductos = Loadable(lazy(() => import('./pages/dashboard/ComisionesProductos')));
const SaldoBolsaTigo = Loadable(lazy(() => import('./pages/dashboard/saldoBolsaTigo')));
const EstadoRecargas = Loadable(lazy(() => import('./pages/dashboard/estado_recargas')));
const Cubridas = Loadable(lazy(() => import('./pages/dashboard/cubridas')));
const CubridasLevel2 = Loadable(lazy(() => import('./pages/dashboard/Cubridas2')));
const CubridasDetails = Loadable(lazy(() => import('./pages/dashboard/CubridasDetails')));
const EstadisticaQweb = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/estadisticaQweb')));
const AnalisisDeJugadas = Loadable(lazy(() => import('./pages/dashboard/AnalisisJugadas')));
const AnalisisDeJugasNivel2 = Loadable(lazy(() => import('./pages/dashboard/AnalisisJugadaNivel2')));
const AnalisisDeJugadasNivel3 = Loadable(lazy(() => import('./pages/dashboard/AnalisisJugadaNivel3')));
const AnalisisFrecuencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/frecuencia/AnalisisFrecuencia')));
const SorteosEspeciales = Loadable(lazy(() => import('./pages/dashboard/gerencia/especiales/SorteosEspeciales')));
const EstadoDecuentas = Loadable(lazy(() => import('./pages/dashboard/gerencia/estadoDeCuentas/EstadoDeCuentas')));
const EstadoDeCuentasDetalle = Loadable(lazy(() => import('./pages/dashboard/gerencia/estadoDeCuentas/EstadoDecuentasDetalle')));
const TablePremiosDetalles = Loadable(lazy(() => import('./pages/dashboard/gerencia/premios/GerenciaPremiosDetalles')));
// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const Proyeccion = Loadable(lazy(() => import('./pages/dashboard/proyecciones')));
const Diferencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/Diferencias')));
const DiferenciasDetalles = Loadable(lazy(() => import('./pages/dashboard/gerencia/Diferencias-detalle')));
const ReporteQuinielaWeb = Loadable(lazy(() => import('./pages/dashboard/gerencia/quinielaweb/reporte/ReporteWeb')));
// POS
const ControlPos = Loadable(lazy(() => import('./pages/dashboard/pos/PosControl')));
// GEO
const Geolocalizacion = Loadable(lazy(() => import('./pages/dashboard/maps/Geolocalizacion')));

// MEGALOTO
const MegalotoRegistrarPremio = Loadable(lazy(() => import('./pages/dashboard/megaloto/MegalotoRegistarPremio')));
const MegalotoAnulacion = Loadable(lazy(() => import('./pages/dashboard/megaloto/MegalotoAnulaciones')));
const MegalotoTickets = Loadable(lazy(() => import('./pages/dashboard/megaloto/MegalotoTickets')));

// WESTER-UNION

const WesterUnionCi = Loadable(lazy(() => import('./components/dashboard/wu/WesterUnionCi')));
const WesterUnionForm = Loadable(lazy(() => import('./components/dashboard/wu/WesterUnionForm')));
const WesterUnionPago = Loadable(lazy(() => import('./components/dashboard/wu/WesterUnionPago')));
const WesterUnionPage = Loadable(lazy(() => import('./pages/dashboard/wu/WesterUnionPage')));
const WesterUnionDetallesPago = Loadable(lazy(() => import('./components/dashboard/wu/DetailTransaction')));

// PRESTAMOS TEETE
const PrestamosTeetePage = Loadable(lazy(() => import('./pages/dashboard/prestamosTeete/PrestamosTeete')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '/s/:id',
    element: <SorteoDetails />
  },
  {
    path: '*',   // ex dashboard
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'presidencia', 'tesoreria', 'conajzar', 'raspa_operativa', 'conajzar_raspa']}
          rights={['write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: '/:id',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: 'profile',
        element: <RoleBasedGuard roles={['cobrador']}
          rights={['read']} > <Profile /> </RoleBasedGuard>
      },
      {
        path: '/profile/:idCobrador/fecha/:corredorFecha',
        element: <PlanillaGeneralEntregaCobrador />
      },
      {
        path: 'sorteosBase',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
          rights={['write', 'read', 'edit']} > <SorteosBase /> </RoleBasedGuard>
      },
      {
        path: 'ajustes',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
          rights={['write', 'read', 'edit']} > <Ajustes /> </RoleBasedGuard>
      },
      {
        path: 'planillaAgencias',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <PlanillaAgencias /> </RoleBasedGuard>
      },
      {
        path: 'ajustesUmn',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia']}
          rights={['write', 'read', 'edit']} > <AjustesUmn /> </RoleBasedGuard>
      },
      {
        path: 'planillaGeneral/:id',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: '/cajasAbiertas/',
        element: <CajasAbiertas />
      },
      {
        path: 'planillaGeneral',
        children: [
          {
            path: '/producto/:idProducto/:umnNumber',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><PlanillaProductoByUmn /></RoleBasedGuard>
          },
          {
            path: '/producto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><PlanillaProducto /></RoleBasedGuard>
          },
          {
            path: '/producto/:idProducto/corredor/:idCorredor/perfil/:idPerfil',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><TicketsCorredor /></RoleBasedGuard>
          },
          {
            path: '/productos/:idProducto/corredor/:idCorredor/perfil/:idPerfil',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><DetallesProductoCorredor /></RoleBasedGuard>
          },
          {
            path: '/productos/:idProducto/corredor/:idCorredor/perfil/:idPerfil/:idJuego',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><DetallesProductoCorredor /></RoleBasedGuard>
          },
          {
            path: '/totalProductos/:idProducto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><PlanillaCobros /></RoleBasedGuard>
          },
          {
            path: '/corredor/:idCorredor',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><CorredoresDataList /></RoleBasedGuard>
          },
          {
            path: '/cobrador/:idCobrador',
            element: <ProfileCobrador />
          },
          {
            path: '/corredor/:idCorredor/fecha/:corredorFecha',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} > <CorredoresDatailsFecha /> </RoleBasedGuard>
          },
          {
            path: '/agencias/producto/:idProducto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
              rights={['write', 'read', 'edit']} > <AgenciesPlanillaGeneral /> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/umn/producto/:idProducto/:agenciaNumero',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']} > <PlanillaGerenciaUmn /> </RoleBasedGuard>
          },
          {
            path: '/producto/gerencia/:idProducto/:umnNumber/:idJuego',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><PlanillaProductoByUmn /></RoleBasedGuard>
          },
          {
            path: '/gerencia/raspa/agencias/producto/:idProducto/:juegoId',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
              rights={['write', 'read', 'edit']} > <PlanillaGeneralRaspaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/producto/:idProducto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
              rights={['write', 'read', 'edit']} > <PlanillaGerenciaAgencia /> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/producto/:idProducto/all',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']} > <PlanillaGerenciaUmn /> </RoleBasedGuard>
          },
          {
            path: '/gerencia/raspadita/totales',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']} > <PlanillaGerenciaRaspadita /> </RoleBasedGuard>
          },
          {
            path: '/producto/gerencia/:juegoId/:umnNumber',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
              rights={['write', 'read', 'edit']} ><PlanillaRaspaditaByAgencia /></RoleBasedGuard>
          },
        ]
      },
      {
        path: 'pagarPremios',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
          rights={['write', 'read', 'edit']}> <PremiosPagados /> </RoleBasedGuard>
      },
      {
        path: 'entregas',
        element: <Entregas />
      },
      {
        path: 'productoshabilitados',
        element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
          rights={['write', 'read', 'edit']} > <ProductosHabilitados /> </RoleBasedGuard>
      },
      {
        path: 'comisionesProductos',
        element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
          rights={['write', 'read', 'edit']} > <ComisionProductos /> </RoleBasedGuard>
      },
      {
        path: 'estadoRecargas',
        element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa', 'finanzas']}
          rights={['write', 'read', 'edit']} > <EstadoRecargas /> </RoleBasedGuard>
      },
      {
        path: 'depositoUmn',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <Deposito /> </RoleBasedGuard>
      },
      {
        path: 'extractoUmn',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <ExtractoUMN /> </RoleBasedGuard>
      },
      {
        path: 'tickets',
        element: <Tickets />
      },
      {
        path: 'billetaje',
        element: <Billetaje />
      },
      {
        path: 'boletas',
        element: <Boletas />
      },
      {
        path: 'estadosCorredor',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
          rights={['read']} > <EstadosCorredor /> </RoleBasedGuard>
      },
      {
        path: 'terminal/:serialTerminal/:corredorNumero/:idCorredor/:cajaId',
        element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
          rights={['read']} > <TerminalSerial /> </RoleBasedGuard>
      },
      {
        path: 'planilla',
        element: <Planilla />
      },
      {
        path: 'premiados/:idCorredor',
        element: <PlanillaPremiados />
      },
      {
        path: 'jugadas',
        element: <Jugadas />
      },
      {
        path: 'cobros',
        element: <PlanillaCobros />
      },
      {
        path: 'control-pos',
        element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
          rights={['read']} > <ControlPos /> </RoleBasedGuard>
      },
      {
        path: 'geo',
        element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
          rights={['read']} > <Geolocalizacion /> </RoleBasedGuard>
      },
      {
        path: 'cobros2',
        element: <PlanillaCobros2 />
      },
      {
        path: 'anulaciones',
        element: <Anulaciones />
      },
      {
        path: 'anulaciones-premios',
        element: <AnulacionesPremios roles={['admin_juego', 'tdp_operativa']}/>
      },
      {
        path: 'stock',
        children: [
          {
            path: 'terminal',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'tecnico']}
              rights={['write', 'read', 'edit']}> <StockTerminal /> </RoleBasedGuard>
          },
          {
            path: 'historialTerminal/:idTerminal',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'tecnico']}
              rights={['write', 'read', 'edit']}> <HistorialTerminales /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'web',
        children: [
          {
            path: 'recaudaciones',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <RecaudacionesPorSorteo /> </RoleBasedGuard>
          },
          {
            path: 'recaudaciones/sorteo/:idSorteo',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <UserTickets /> </RoleBasedGuard>
          },
          {
            path: 'recaudaciones/sorteo/:idSorteo/corredor/:idCorredor',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <UserBets /> </RoleBasedGuard>
          },
          {
            path: 'general',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <General /> </RoleBasedGuard>
          },
          {
            path: 'saldos/apostar',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <SaldoApuesta /> </RoleBasedGuard>
          },
          {
            path: 'saldos/ganado',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <SaldoGanado /> </RoleBasedGuard>
          },
          {
            path: 'recargas',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <Recarga /> </RoleBasedGuard>
          },
          {
            path: 'transferencias',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <Transferencia /> </RoleBasedGuard>
          },
          {
            path: 'recargas/usuario/:usuarioId',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <RecargaByUser /> </RoleBasedGuard>
          },
          {
            path: 'transferencias/usuario/:usuarioId',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <TransferenciaByUser /> </RoleBasedGuard>
          },
          {
            path: 'premios/usuario/:corredorId',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'presidencia']}
              rights={['write', 'read', 'edit']}> <PremiosByUser /> </RoleBasedGuard>
          },
          {
            path: 'operativa/promociones',
            element: <RoleBasedGuard roles={['admin_juego', 'qweb_operativa']}
              rights={['write', 'read', 'edit']}> <OperativaPromociones /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'raspaditas',
        children: [
          {
            path: 'cajas',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasCajas /> </RoleBasedGuard>
          },
          {
            path: 'libros',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasLibros /> </RoleBasedGuard>
          },
          // {
          //   path: '/corredores/estadisticas/',
          //   element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
          //     rights={['read']}> <PlanillaAsignadasPorCorredorAgencias /> </RoleBasedGuard>
          // },
          {
            path: 'stock',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasStock /> </RoleBasedGuard>
          },
          {
            path: 'stock-tipo-juego',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <StockGeneralRaspadita /> </RoleBasedGuard>
          },
          {
            path: 'inventario',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']}> <Inventario /> </RoleBasedGuard>
          },
          {
            path: 'inventario/agencias/:codigo_juego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']}> <InventarioTablePresidencia /> </RoleBasedGuard>
          },
          {
            path: 'asignacion-umn',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia']}
              rights={['write', 'read', 'edit']} > <RaspaditaUMN /> </RoleBasedGuard>
          },
          {
            path: 'devolucion-libro-umn',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia']}
              rights={['write', 'read', 'edit']} > <RaspaditaDevolucionLibro /> </RoleBasedGuard>
          },
          {
            path: 'recepcion-caja',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia']}
              rights={['write', 'read', 'edit']} > <RaspaditasCajas /> </RoleBasedGuard>
          },
          {
            path: 'corredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasCorredor /> </RoleBasedGuard>
          },
          {
            path: 'reasignacion-corredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasReasignacionCorredor /> </RoleBasedGuard>
          },
          {
            path: 'premios',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditasPremios /> </RoleBasedGuard>
          },
          {
            path: 'premios-agencia',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaBoletasPremiadas /> </RoleBasedGuard>
          },
          {
            path: 'no-premios-agencia',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa', 'tdp_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaBoletasNoPremiadas /> </RoleBasedGuard>
          },
          {
            path: '/stock/cajas',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa', 'agencia', 'umn']}
              rights={['read']}> <CajasLogisticaRaspadita /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego-dias-asignados',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa', 'tdp_operativa']}
              rights={['read']}> <PlanillaTotalStockJuegoDiasAgencia /> </RoleBasedGuard>
          },
          {
            path: 'diferencia-premiadas',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaBoletasDiferenciaPremiadas /> </RoleBasedGuard>
          },
          {
            path: 'registrar-premio',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaRegistrarPremio /> </RoleBasedGuard>
          },
          {
            path: 'caja/:id/:codigo',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditasDetallesCaja /> </RoleBasedGuard>
          },
          {
            path: 'caja/libro/:id/:codigo',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <RaspaditaDetallesLibro /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadas /> </RoleBasedGuard>
          },
          {
            path: 'boletasVendidasTipoJuegoCorredor',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['read']}> <RaspaditaBoletasVendidasTipoJuego /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasCorredoresDias',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadasDias /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasACorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadasLista /> </RoleBasedGuard>
          },
          {
            path: 'ventasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
              rights={['read']}> <RaspaditaTableEstadistica /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasCorredoresGerencia/:agenciaNumero/:tipoJuego',
            element: <RoleBasedGuard roles={['admin_juego', 'presidencia', 'tdp_gerencia', 'finanzas']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadasGerencia /> </RoleBasedGuard>
          },
          {
            path: 'boletasDiasAsignadosCorredoresGerencia/:agenciaNumero/:dias/:tipoJuego/:prorrogas/:corr/:ag',
            element: <RoleBasedGuard roles={['admin_juego', 'presidencia', 'tdp_gerencia', 'finanzas']}
              rights={['read']}> <RaspaditaCorredoresDiasBoletasAsignadasGerencia /> </RoleBasedGuard>
          },
          {
            path: 'boletasDiasAsignadosCorredoresAgencias/:agenciaNumero/:dias/:tipoJuego/:idJuego/:prorroga/:corredorExt/:agenciaExt',
            element: <RoleBasedGuard roles={['admin_juego', 'presidencia', 'tdp_gerencia', 'finanzas', 'tdp_operativa', 'umn', 'agencia']}
              rights={['read']}> <RaspaditaCorredoresDiasBoletasAsignadasAgencias /> </RoleBasedGuard>
          },
          {
            path: 'boletasVendidasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas']}
              rights={['read']}> <RaspaditaCorredoresBoletasVendidas /> </RoleBasedGuard>
          }
        ]

      },
      {
        path: 'megaloto',
        children: [
            {
              path: '/registrar-premio',
              element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
                rights={['write', 'read', 'edit']} > <MegalotoRegistrarPremio /> </RoleBasedGuard>
            },
             {
              path: '/anulacion-megaloto',
              element: <RoleBasedGuard roles={['admin_juego']}
                rights={['write', 'read', 'edit']} > <MegalotoAnulacion /> </RoleBasedGuard>
            },
            {
              path: '/megaloto-tickets',
              element: <RoleBasedGuard roles={['admin_juego']}
               rights={['write', 'read', 'edit']} > <MegalotoTickets /> </RoleBasedGuard>
            }

        ]
      },
      {
        path: 'westerUnion',
        element: <RoleBasedGuard roles={['bridget']}
          rights={['write', 'read', 'edit']} > <WesterUnionPage /> </RoleBasedGuard>,
        children: [
          {
            path: '/verificar-mtcn',
            element: <WesterUnionPago />
          },
          {
            path: '/verificar-usuario',
            element: <WesterUnionCi />
          },
          {
            path: '/completar-formulario',
            element: <WesterUnionForm />
          },
          {
            path: '/detalles',
            element: <WesterUnionDetallesPago />
          },
          {
            path: '/persona-fisica',
            element: <WesterUnionPersonaFisica />
          },
        ]
      },
      {
         path: 'prestamos-teete',
           children: [
               {
                path: '/cuotas',
                element: <RoleBasedGuard roles={['umn', 'agencia']}
                  rights={['write', 'read', 'edit']} >  <PrestamosTeetePage /> </RoleBasedGuard>
               },
           ]
      },
      {
        path: 'totalTerminales',
        element: <TotalTerminales />
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_gerencia', 'umn', 'agencia']}
              rights={['write', 'read', 'edit']} > <UserList /> </RoleBasedGuard>
          },
        ]
      },
      {
        path: 'usuariosExternos',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']}
              rights={['write', 'read', 'edit']} > <UsuariosExternos /> </RoleBasedGuard>
          }
        ],
      },
      {
        path: 'devices',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <DeviceList /> </RoleBasedGuard>
          },
          {
            path: '/details/:id',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia', 'umn']}
              rights={['write', 'read', 'edit']} > <DeviceDetails /> </RoleBasedGuard>
          },
        ]

      },
      {
        path: 'resumenGeneral',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <FinanzasResumenGeneral /> </RoleBasedGuard>
      },
      {
        path: 'pagoDePremios',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria', 'cobrador']}
          rights={['write', 'read', 'edit']} > <PagoPremios /> </RoleBasedGuard>
      },
      {
        path: 'depositosAgencia',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={['write', 'read', 'edit']} > <Depositos /> </RoleBasedGuard>
      },
      // {
      //   path: 'depositosAgencia',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
      //     rights={[ 'write', 'read', 'edit']} > <Depositos /> </RoleBasedGuard>
      // },
      // {
      //   path: 'resumenDepositos',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
      //     rights={[ 'write', 'read', 'edit']} > <FinanzasResumenDepositos /> </RoleBasedGuard>
      // },
      // {
      //   path: 'resumenAjustes',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
      //     rights={[ 'write', 'read', 'edit']} > <FinanzasResumenAjustes /> </RoleBasedGuard>
      // },
      {
        path: 'numerosLimitados',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia']}
          rights={['write', 'read', 'edit']} > <Limitados /> </RoleBasedGuard>
      },
      {
        path: 'sorteos',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
              rights={['write', 'read', 'edit']} > <Sorteos /> </RoleBasedGuard>
          },
          {
            path: ':id',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
              rights={['write', 'read', 'edit']} > <SorteoDetails /> </RoleBasedGuard>
          },
          {
            path: ':id/pendientes',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
              rights={['write', 'read', 'edit']} > <SorteoDetailsPendientes /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'raspadita',
        children: [
          {
            path: '/correcion-premios',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaPremios /> </RoleBasedGuard>
          },
          {
            path: '/diferencia-premios',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
              rights={['write', 'read', 'edit']} > <RaspaditaDifenciaPremios /> </RoleBasedGuard>
          },
          {
            path: '/libros-vendidos',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <InformeLibrosVendidosRaspadita /> </RoleBasedGuard>
          },
          {
            path: '/anulaciones',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <AnulacionesRaspadita /> </RoleBasedGuard>
          },

          {
            path: '/libros-vendidos/detalle-venta-libro/:codigoLibro',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <DetalleVentaLibro /> </RoleBasedGuard>
          },
          {
            path: '/libros-asignaciones',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <InformeLibrosAsignadosRaspadita /> </RoleBasedGuard>
          },
          {
            path: '/libros-asignaciones/detalle-libro/:codigoLibro',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <DetalleAsignacionLibro /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'gerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <Gerencia /> </RoleBasedGuard>
          },
          {
            path: '/productos/all',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/:producto',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasUmnProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:producto',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasUmnProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:producto',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasUmnProductoCorredor /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/:producto/corredores',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasUmnProductoCorredor /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasCorredorProductos /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id/:producto',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasCorredorProductos /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:corredor_numero/:agencia/:umn_id/:producto_id',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaDetallePorTransaccion /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id/Quiniela',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaDetailticket /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id/Billetaje',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <BilletajeGerencia /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasProductoCorredor /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores/:corredor_id',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgenciasCorredorProductos /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores/:corredor_id/Quiniela',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaDetailticket /> </RoleBasedGuard>
          },
          {
            path: '/agencias/:sorteoId',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']} > <GerenciaAgencias /> </RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia/:umnId/:corredor_id',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'tesoreria']}
              rights={['read']}> <PlanillaPremiadosGerencia /></RoleBasedGuard>
          },
          {
            path: '/premiados-agrupados/:sorteoId/:agencia',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'tesoreria']}
              rights={['read']}> <PlanillaPremiadosGerenciaAgrupados /></RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia/umns',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}><GerenciaAgenciasUmn /></RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia/umns/:umnId',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}> <PlanillaPremiadosGerenciaUmn /></RoleBasedGuard>
          },
          {
            path: '/premiados/sorteo/:sorteoId',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}> <PlanillaPremiadosSorteoGerencia /></RoleBasedGuard>
          },
          {
            path: '/premiados/:agencia/:umnId/corredor/:numeroCorredor/sorteo/:sorteo_id/:premiados',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}> <PlanillaPremiadosCorredorGerencia /></RoleBasedGuard>
          },
          {
            path: '/proyecciones',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'admin_juego']}
              rights={['read']}> <Proyeccion /></RoleBasedGuard>
          },
          {
            path: '/diferencias',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'admin_juego']}
              rights={['read']}> <Diferencias /></RoleBasedGuard>
          },
          {
            path: '/diferencias/:agenciaNumero',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'admin_juego']}
              rights={['read']}> <DiferenciasDetalles /></RoleBasedGuard>
          },
          {
            path: '/qweb/reporte',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}> <ReporteQuinielaWeb /></RoleBasedGuard>
          },
           {
              path: '/investigar/usuario/:nroDocumento',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <InvestigadorQweb /></RoleBasedGuard>
           },
             {
              path: '/investigar/usuario',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <InvestigadorQweb /></RoleBasedGuard>
           },
             {
              path: '/frecuencia',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <AnalisisFrecuencia /></RoleBasedGuard>
           },
           {
            path: '/estadoCuentas',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'finanzas', 'tdp_operativa']}
             rights={['read']}> <EstadoDecuentas /></RoleBasedGuard>
           },
            {
            path: '/estadoCuentas/detalles/:agencia_id',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'finanzas', 'tdp_operativa']}
             rights={['read']}> <EstadoDeCuentasDetalle /></RoleBasedGuard>
           },
          {
            path: '/cubridas',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
              rights={['read']}> <Cubridas /></RoleBasedGuard>
          },
          {
            path: '/cubridas/:agencia',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'tdp_operativa']}
              rights={['read']}> <CubridasLevel2 /></RoleBasedGuard>
          },
          {
              path: '/analisis',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <AnalisisDeJugadas /></RoleBasedGuard>
          },
           {
              path: '/analisis/:sorteo',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <AnalisisDeJugasNivel2 /></RoleBasedGuard>
          },
             {
              path: '/analisis/:sorteo/:agencia_numero',
              element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
                rights={['read']}> <AnalisisDeJugadasNivel3 /></RoleBasedGuard>
          },
          {
            path: '/cubridas/:corredorId/:number/:corredorNumber',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia', 'tdp_operativa']}
            rights={['read']}> <CubridasDetails /></RoleBasedGuard>
          },
          {
            path: '/especiales',
            element: <RoleBasedGuard roles={['dw']}
              rights={['read']}> <SorteosEspeciales /></RoleBasedGuard>
          },
        ]
      },

      {
        path: '/mode-holmes',
        element: <RoleBasedGuard roles={['presidencia']}
          rights={['read']}> <Sherlock /> </RoleBasedGuard>
      },
      {
        path: '/premiadosPorJugada/:sorteoId',
        element: <RoleBasedGuard roles={['presidencia', 'gerencia']}
          rights={['read']}> <PremiadosGerencia /> </RoleBasedGuard>
      },
      {
        path: '/premiadosPorJugada/detalles/:agenciaNumero/:sorteoId/:jugada',
        element: <RoleBasedGuard roles={['presidencia', 'gerencia']}
          rights={['read']}> <TablePremiosDetalles /> </RoleBasedGuard>
      },
      {
        path: '/raspaditaAsignaciones',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <AsignacionesGerencia /> </RoleBasedGuard>
      },
      {
        path: 'bolsaTigo',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'presidencia', 'gerencia', 'tdp_gerencia']}
          rights={['read']}> <SaldoBolsaTigo /> </RoleBasedGuard>
      },
      {
        path: '/cubridas',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'presidencia', 'gerencia', 'tdp_gerencia']}
          rights={['read']}> <Cubridas /></RoleBasedGuard>
      },
      {
        path: '/estadisticaQweb',
        element: <RoleBasedGuard roles={['gerencia', 'tdp_gerencia']}
          rights={['read']}> <EstadisticaQweb /></RoleBasedGuard>
      },
      {
        path: '/raspaditaMovimientoFinanciero',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <MovimientoFinancieroGerencia /> </RoleBasedGuard>
      },
      {
        path: 'raspadita-operativa',
        children: [
          {
            path: 'boletasAsignadasACorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadasLista /> </RoleBasedGuard>
          },
          {
            path: 'boletasVendidasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
              rights={['read']}> <RaspaditaOperativaCorredoresBoletasVendidas /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: '/stock',
        children: [
          {
            path: '/libros-vendidos',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa', 'agencia', 'umn']}
              rights={['read']}> <InformeLibrosVendidosRaspaditaAgencias /> </RoleBasedGuard>
          },
          {
            path: '/libros-vendidos/detalle-venta-libro/:codigoLibro',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa', 'agencia', 'umn']}
              rights={['read']}> <DetalleVentaLibroAgencia /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <PlanillaTotalStockJuego /> </RoleBasedGuard>
          },
          {
            path: '/recepcion-juego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <RaspaditaImportarJuego /> </RoleBasedGuard>
          },
          {
            path: '/pallets',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <StockPallets /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <StockCajas /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo/libros/:cajaCodigo',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <StockLibros /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo/libros/:cajaCodigo/boletas/:libroCodigo',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <StockBoletas /> </RoleBasedGuard>
          },
          {
            path: '/stock/cajas',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa', 'admin_juego', 'tdp_operativa', 'agencia', 'umn']}
              rights={['read']}> <CajasLogisticaRaspadita /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego-dias-asignados',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas']}
              rights={['read']}> <PlanillaTotalStockJuegoDias /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego/agencias/:tipoJuego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa']}
              rights={['read']}> <StockRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego-dias/agencias/:dias/:tipoJuego/:prorro/:corr/:ag',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'tdp_operativa', 'admin_juego']}
              rights={['read']}> <StockRaspaditaGerenciaDiasAsignacion /> </RoleBasedGuard>
          },
          {
            path: '/asignacion-tipo-juego-dias/agencias/:dias/:tipoJuego/:juegoId/:prorroga/:corredorExt/:agenciaExt',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'finanzas', 'tdp_operativa', 'admin_juego', 'umn', 'agencia']}
              rights={['read']}> <StockRaspaditaDiasAsignacionAgencias /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: '/boletasPorMonto',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><BoletasPorMonto /></RoleBasedGuard>
      },
      {
        path: '/historialDeSorteos',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><HistorialDeMontos /></RoleBasedGuard>
      },
      {
        path: '/ocurrenciasTombola',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><TombolaOcurrencia /></RoleBasedGuard>
      },
      {
        path: '/estadoCuenta',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><FinanzasGerencia /></RoleBasedGuard>
          },
          {
            path: '/:nroAgencia/:nombreAgencia',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><FinanzasGerenciaDetalle /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/ocurrenciasGlobales',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><Ocurrencias /></RoleBasedGuard>
      },
      {
        path: '/ocurrenciaGraficos',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><OcurrenciasGraficos /></RoleBasedGuard>
      },
      {
        path: '/bolillero',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}><Bolillero /></RoleBasedGuard>
      },
      {
        path: '/estadoJugador',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <InformeCuentaJugador /></RoleBasedGuard>
      },
      {
        path: '/ventaAgencias',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <VentasPorAgencia /></RoleBasedGuard>
      },
      {
        path: '/mediosElectronicos',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <VentasPorMediosElectronicos /></RoleBasedGuard>
      },
      {
        path: '/mediosTerminales',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <VentasPorTerminal /></RoleBasedGuard>
      },
      {
        path: '/reporteSistema',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ReportesDelSistema /></RoleBasedGuard>
      },
      {
        path: '/reporteAgencia',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ReporteFacturacionAgencia /></RoleBasedGuard>
      },
      {
        path: '/loginFallidos',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <IntentosLogin /></RoleBasedGuard>
      },
      {
        path: '/parametrosSistema',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <CambiosParametrosSistema /></RoleBasedGuard>
      },
      {
        path: '/uptimeSistema',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <UpdateSistema /></RoleBasedGuard>
      },
      {
        path: '/anulacionesEventos',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <AnulacionesConajzar /></RoleBasedGuard>
      },
      {
        path: '/softwareUpdate',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <UpdateSoftware /></RoleBasedGuard>
      },
      {
        path: '/hearbeatsEventos',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <Heartbeat /></RoleBasedGuard>
      },
      {
        path: '/registroAsistencia',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <RegistroDeAsistencias /></RoleBasedGuard>
      },
      {
        path: '/reportePagoPremios',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ReportePagoDePremios /></RoleBasedGuard>
      },
      {
        path: '/reportesPagoSuperior',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ReportePagoPremiosSuperiores /></RoleBasedGuard>
      },
      {
        path: '/informeLiquidaciones',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <InformeLiquidaciones /> </RoleBasedGuard>
      },
      {
        path: '/facturacion-raspadita',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <InformeFacturacionRaspadita /> </RoleBasedGuard>
      },
      {
        path: '/facturacion-raspadita-gerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
              rights={['read']}> <InformeFacturacionRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/:tipojuego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><VentasPorAgenciaGerencia /></RoleBasedGuard>
          },
          {
            path: '/:tipojuego/agencia/:agencia_numero/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><VentasPorCorredorAgencia /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/ganadores',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <InformePremiadosRaspadita /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaVentasPorCorredor /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor/juego/:codigoJuego/:agencia/:venta',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaVentasPorCorredorAgencias /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor/juego/:codigoJuego/agencia/:agenciaNumero/:venta',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaVentasPorCorredorByCorredor /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaAsignadasPorCorredor /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor/juego/:codigoJuego',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaAsignadasPorCorredorAgencias /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor/juego/:codigoJuego/agencia/:agenciaNumero',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
          rights={['read']}> <PlanillaAsignadasPorCorredorByCorredor /> </RoleBasedGuard>
      },
      {
        path: '/ganadoresGerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
              rights={['read']}> <InformePremiadosRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/:tipojuego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><GanadoresPorAgenciaGerencia /></RoleBasedGuard>
          },
          {
            path: '/:tipojuego/agencia/:agencia_numero/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
              rights={['read']}><GanadoresPorCorredorAgencia /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/accesoRemoto',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <AccesoRemoto /> </RoleBasedGuard>
      },
      {
        path: '/auditoriaFirewall',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <AuditoriaFirewall /> </RoleBasedGuard>
      },
      {
        path: '/apuestasAbortadas',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <AbortoApuestas /> </RoleBasedGuard>
      },
      {
        path: '/cambiosPosibilidades',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <CambiosPosibilidades /> </RoleBasedGuard>
      },
      {
        path: '/erroresIncompatibildad',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ErrorIncompatibilidad /> </RoleBasedGuard>
      },
      {
        path: '/estacionApuestas',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <EstacionDeApuestas /> </RoleBasedGuard>
      },
      {
        path: '/reporteFacturacionDiaria',
        element: <RoleBasedGuard roles={['conajzar']}
          rights={['read']}> <ReporteFacturacionDiaria /> </RoleBasedGuard>
      }
    ]
  },
  {
    path: '*',  // ex  *
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
