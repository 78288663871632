import  { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import type { FC, ReactNode } from 'react';
import { useDate } from '../hooks/useDate'

interface DateContextI {
    startDate: string;
    endDate: string;
}

const initialDate: DateContextI = {
    startDate: dayjs(new Intl.DateTimeFormat('en-US', {timeZone: 'America/Asuncion'}).format(new Date())).format('YYYY-MM-DD'),
    endDate: dayjs(new Intl.DateTimeFormat('en-US', {timeZone: 'America/Asuncion'}).format(new Date())).format('YYYY-MM-DD')
}

interface DateProviderProps {
    children?: ReactNode;
}

export const DateContext = createContext<any>({
    actualDate: initialDate,
    actualDatevalue: initialDate,
    setDateValue: () => {},
    setDate: () => {}
})

export const DateProvider:FC<DateProviderProps> = ({ children }) => {
  const [actualDate, setDate] = useDate()
  const [actualDateValue, setDateValue] = useState(initialDate);


  useEffect(() => {}, [actualDateValue])

  const value = {
    actualDate,
    actualDateValue,
    setDate,
    setDateValue,
  }

  return (
    <DateContext.Provider value={value}>
      {children}
    </DateContext.Provider>
  )
}

export const DateConsumer = DateContext.Consumer

DateProvider.propTypes = {
    children: PropTypes.node.isRequired
};
