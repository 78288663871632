import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import formatNumbers from 'src/utils/formatNumbers'
import Scrollbar from '../../../Scrollbar';


interface PuntosDeRecargaProps {
  items: any;
}

const PuntosDeRecarga: FC<PuntosDeRecargaProps> = (props) => {
  const { items, ...other } = props;

   const totales = {share: 0, share_cantidad: 0, cantidad: 0, monto: 0, shareTotal: 0, sharedCantidad: 0}
    items?.forEach(item => { 
        totales.share += Number(item.monto_cargado);
        totales.share_cantidad += Number(item.cant_cargas);
        totales.cantidad += Number(item.cant_cargas);
        totales.monto += Number(item.monto_cargado);
        totales.shareTotal =  Number((totales.monto / totales.share) * 100)
        totales.sharedCantidad = Number((totales.cantidad / totales.share_cantidad) * 100)
      }); 
   

  return (
    <>
      <Card {...other}>
        <CardHeader
          title="Puntos de recarga"
        />
        <Divider />
        <Scrollbar>
          <Box>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Agencia
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Nombre
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                        Cantidad 
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Monto
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Share
                    </Typography>
                  </TableCell>
                   <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{ whiteSpace: 'nowrap', color: "white" }}
                    >
                      Share Cantidad
                    </Typography>
                  </TableCell>
             </TableRow>
              </TableHead>
              <TableBody>
                {items?.map((item) => (
                <TableRow
                  hover
                  key={item?.monto}
                >
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                       Agencia {item?.agencia_numero}
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {item?.nombre}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {formatNumbers(item?.cant_cargas)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {formatNumbers(item?.monto_cargado)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {formatNumbers(Number((item.monto_cargado / totales.share) * 100).toFixed(2))}%
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {formatNumbers(Number((item.cant_cargas / totales.share_cantidad) * 100).toFixed(2))}%
                    </Typography>
                  </TableCell>
                </TableRow>
                ))}
                       {items?.length > 0 && (
                <TableRow
                  hover
                  // key must be random
                  key={Math.random()}
                  sx={{backgroundColor: "inherit"}}
                >
                 <TableCell>
                    <Typography
                      color="textPrimary"
                      sx={{ fontWeight: 'bold' }}
                    >
                       Total
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.cantidad)}
                       
                    </Typography>
                  </TableCell>
                                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {formatNumbers(totales.monto)}
                       
                    </Typography>
                  </TableCell>
                                               <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {(totales.shareTotal).toFixed()} %
                       
                    </Typography>
                  </TableCell>
                                                  <TableCell align='right'>
                    <Typography
                      color="textPrimary"
                      align='right'
                      sx={{fontWeight: 'bold'}}
                    >
                            {(totales.sharedCantidad).toFixed()} %
                       
                    </Typography>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

PuntosDeRecarga.propTypes = {
  items: PropTypes.any
};

export default PuntosDeRecarga;
