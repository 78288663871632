import { useRef, useState, useContext, useEffect} from 'react';
import type { ElementType, FC } from 'react';
import {  subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import axios from 'axios'
// import Loading from 'src/components/loading/Loading';
import { io } from 'socket.io-client';
import { UMNContext, AuthContext} from 'src/contexts';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';


const dayjs = require('dayjs');
require('dayjs/locale/es');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime)

interface Notification {
  id: string;
  message: string;
  title: string;
  description: string;
  type: string;
  leido: Boolean;
  createdAt: number;
}

const now = new Date();

const iconsMap: Record<string, ElementType> = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon
};

const nots: Notification[] = [];

const NotificationsPopover: FC = () => {
  const { UMN } = useContext(UMNContext);
  const { user } = useContext(AuthContext);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [notifications, setNotificaciones] = useState(nots);
  const [badges, setBadges] = useState(0);
  const [loading, setLoading] = useState(false);

  let umnId = UMN
  if (typeof umnId !== 'object') {
    umnId = [UMN]
  }

  const token = localStorage.getItem('accessToken');

  const getNotifications = async () => {
    setLoading(true);
    // const [connected, setConnected] = useState(false);
    console.log("loadinggggggggggggggggggggggggggggg", loading)
    await axios.get(`${process.env.REACT_APP_API_URL_RASPADITA}/api/raspa-monto/notification/listar/1`, {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }
    })
      .then((response) => {
        const arr = response.data as Array<any>;
        subHours(now, 2).getTime()
        console.log("notificacioneeeeees", arr, subHours(now, 2).getTime())
        setLoading(false)
        // setNotificaciones(arr);
      })
      .catch(() => {
        setLoading(false)
      });
  };
  console.log(getNotifications);

  const socketOptions = {
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${token}`
            }
        }
    }
};

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  }

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL_RASPADITA}`, socketOptions);

    // socket.emit('authenticate', { token });
    if(user?.id === "100" || user?.id === "93" || user?.id === "1") socket.emit('joinTopic', { routingKey: 'Raspadita.Venta.Automatica', user_id: user.id });
    socket.emit('joinTopic', { routingKey: 'Raspadita.Usuario.Online', user_id: user.id });
    socket.on('notificaciones', (data) => {
      if(data.items.length > 0) {
        for(let n = 0; n < data.items.length; ++n) {
          const currentNotification = nots.find(f => f.id === data.items[n]?.id);
          if(currentNotification) {
            n++
          };
          nots.push({
            id: data.items[n]?.id,
            createdAt: dayjs(data.items[n]?.created_at).locale('es').fromNow(),
            description: data.items[n]?.mensaje,
            message: data.items[n]?.mensaje,
            title: data.items[n]?.topic.descripcion,
            leido: data.items[n]?.leido,
            type: 'order_placed'
          });
        };
        setNotificaciones(nots);
        setBadges(notifications.filter(f => f.leido === false).length)
      }
    });

    socket.on('connected', () => {
      console.log('Conectado al servidor');
    });

    socket.on('Topics', (topicos) => {
      console.log(`topics: ${JSON.stringify(topicos)}`);
    });

    socket.on('Error', (error) => {
      console.log(`socket: ${JSON.stringify(error)}`);
    });
    // getNotifications();
    return () => {
      // Desconexión del socket antes de que el componente se desmonte
      if (socket) {
        socket.disconnect();
      }
    };
  }, [notifications])

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={badges}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notificaciones
          </Typography>
        </Box>
        {
          notifications.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  There are no notifications
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {notifications.map((notification) => {
                    const Icon = iconsMap.order_placed;

                    return (
                      <ListItem
                        divider
                        key={notification.id}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }}
                          >
                            <Icon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={(
                            <Link
                              color="textPrimary"
                              sx={{ cursor: 'pointer' }}
                              underline="none"
                              variant="subtitle2"
                            >
                            {notification.title}
                            </Link>
                          )}
                          secondary={(
                            <>
                            <div>{notification.message}</div>
                            <span>{notification.createdAt}</span>
                            </>
                          )}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="text"
                  >
                    Marcar todas como leidas
                  </Button>
                </Box>
              </>
            )
        }
      </Popover>
    </>
  );
};

export default NotificationsPopover;
