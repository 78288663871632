import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types'

interface LoadingContextI {
    loading: any;
    setLoading: any;
  }

export const LoadingContext = createContext<LoadingContextI>({
    loading: false,
    setLoading: () => {}
});

export const LoadingProvider = (props) => {
	const { children } = props;
	const [loading, setLoading] = useState(false);

  useEffect(() => {}, [loading])

  return (
    <LoadingContext.Provider

      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired
};
