import { useRef, useState, memo } from 'react';
import type { FC } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FaFileCsv, FaFilePdf } from 'react-icons/fa'
import DotsHorizontalIcon from '../icons/DotsHorizontal';
import formatNumber from '../utils/formatNumbers'

interface ActionsProps {
    xlsx: any;
    items: any;
    printDocument?: any;
 }

const Actions: FC<ActionsProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { xlsx, items, printDocument } = props;

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  interface premiados {
		fecha?: any;
    agencia?: any;
    boletas?: any;
    tipo_juego?: any;
    monto_total?: any;
  }
    const XLSXArray: premiados[] =
    items?.map((premiado) => (
        {
						'Fecha': `${dayjs(premiado?.fecha).format("DD-MM-YYYY")}`,
            'Agencia numero': `${premiado?.agencia_numero}`,
            'Boletas': `${formatNumber(premiado?.total_boletas)}`,
            'Tipo juego': `${premiado?.tipo_juego}`,
            'Monto total': `${premiado?.total_monto_vendido}`
        }
    ))

  const exportToCVS = () => {
      const workSheet = xlsx.utils.json_to_sheet(XLSXArray)
      const workBook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(workBook, workSheet,`premiados-${dayjs(new Date()).format("DD-MM-YYYY")}`)
    	//   buffer
      const buff = xlsx.write(workBook, {bookType: 'csv', type: 'buffer'})
			console.log(buff)
    	//   binary
      xlsx.write(workBook, {bookType: 'csv', type: 'binary'})
			// download
			xlsx.writeFile(workBook, `facturacion-${dayjs(new Date()).format("DD-MM-YYYY")}.csv`)
  }

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton
          onClick={handleMenuOpen}
          ref={anchorRef}
          {...props}
        >
          <DotsHorizontalIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 256,
            marginTop:6
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuItem onClick={() => exportToCVS()}>
          <ListItemIcon>
            <FaFileCsv fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary="Descargar a CSV" />
        </MenuItem>
        <MenuItem onClick={() => printDocument()}>
          <ListItemIcon>
            <FaFilePdf fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary="Descargar a PDF" />
        </MenuItem>
      </Menu>
    </>
  );
};

Actions.propTypes = {
    xlsx: PropTypes.any,
    items: PropTypes.any,
    printDocument: PropTypes.any
};

export default memo(Actions);
