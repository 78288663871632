import { useRef, useState, memo } from 'react';
import type { FC } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types'
import DocumentTextIcon from '../icons/DocumentText';
import DotsHorizontalIcon from '../icons/DotsHorizontal';

interface ActionsAgrupadoProps {
    xlsx: any;
    items: any;
 }

const ActionsAgrupado: FC<ActionsAgrupadoProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { xlsx, items} = props;

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  interface premiados {
		sorteo?: any;
    generado?: any;
    corredor?: any;
    ticket?: any;
    monto?: any;
    premiacion?: any;
  }
    const XLSXArray: premiados[] =
    items?.map((item) => (
        {
						Agencia: `AG ${item.agencia_numero}`,
            Cantidad: `${item?.cantidad}`,
            items: `${ item?.cantidad_premiados }`,
            TotalBruto: `${item?.total}`,
            Premio: `${item.premio? item.premio: "-"}`,
            Porcentaje: `${((parseInt(item.premio, 10) / parseInt(item.total, 10)) * 100).toFixed(2)} %`,
            ComisionAgencia: parseInt(item.totalComisionA, 10),
            ComisionCorredor: parseInt(item.totalComisionC, 10),
            Neto: `${item.premio ? (parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)): (parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))}`
        }
    ))

  const exportToCVS = () => {
      const workSheet = xlsx.utils.json_to_sheet(XLSXArray)
      const workBook = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(workBook, workSheet, "premiados")
    	//   buffer
      const buff = xlsx.write(workBook, {bookType: 'xlsx', type: 'buffer'})
			console.log(buff)
    	//   binary
      xlsx.write(workBook, {bookType: 'xlsx', type: 'binary'})
			// download
			xlsx.writeFile(workBook, "gestionar.xlsx")
  }

  return (
    <>
      <Tooltip title="Acciones">
        <IconButton
          onClick={handleMenuOpen}
          ref={anchorRef}
          {...props}
        >
          <DotsHorizontalIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 256,
            marginTop:6
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuItem onClick={() => exportToCVS()}>
          <ListItemIcon>
            <DocumentTextIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary="Exportar a XLSX" />
        </MenuItem>
      </Menu>
    </>
  );
};

ActionsAgrupado.propTypes = {
    xlsx: PropTypes.any,
    items: PropTypes.any
};

export default memo(ActionsAgrupado);