import { Box, Card, CardHeader, Table, Grid, TableContainer, TablePagination, Typography, Breadcrumbs, Link } from '@material-ui/core';
import TableHeadCustom from 'src/components/_common/table-head';
import TableFooterComponent from 'src/components/_common/table-footer';
import Scrollbar from "src/components/Scrollbar";
import { useContext, useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom'
import { UMNContext } from 'src/contexts/UMNContext';
import TableBodyCustom from 'src/components/_common/table-body';
import formatNumbers from 'src/utils/formatNumbers';
import { getDataQueryParams } from 'src/serviceTool/serviceCommon';
import ChevronRightIcon from 'src/icons/ChevronRight';

const InventarioTablePresidencia = () => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(100);
  const [dataGrid, setDataGrid] = useState([]);

  const totales = {
    asignados: 0,
    vendidos: 0,
    escaneado: 0,
    alerts: 0,
    faltantes: 0
  };
  const { UMN } = useContext(UMNContext)
  const umnId = UMN;
  const { codigo_juego } = useParams();
  let title = 'Inventario de Tragamonedas por Agencias';
  if (codigo_juego === '0001') {
    title = 'Inventario de 7 de la Suerte por Agencias'
  }


  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const url = `api/v1/inventario/register`;
    const param = {
      umn_id: umnId,
      gourped: true
    }

    getDataQueryParams(url, token, param)
      .then(res => {
        const { data }: any = res;
        setDataGrid(data);
      }).catch(err => {
        console.log(err);
      })
  }, []);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const dataFiltered = dataGrid.filter(item => item.codigo_juego === codigo_juego);

  const data = dataFiltered.map((item: any) => {
    const { agencia_nombre, asignados, vendidos, escaneados, alerts, faltantes } = item;
    totales.asignados += parseInt(asignados, 10);
    totales.vendidos += parseInt(vendidos, 10);
    totales.escaneado += parseInt(escaneados, 10);
    totales.alerts += parseInt(alerts, 10);
    totales.faltantes += parseInt(faltantes, 10);

    const row = [
      { id: codigo_juego, value: agencia_nombre, align: 'left', key: "agencia_nombre" },
      { id: codigo_juego, value: formatNumbers(Number(asignados)), align: 'right', key: "asignados" },
      { id: codigo_juego, value: formatNumbers(Number(vendidos)), align: 'right', key: "vendidos" },
      { id: codigo_juego, value: formatNumbers(Number(escaneados)), align: 'right', key: "stock" },
      { id: codigo_juego, value: formatNumbers(Number(alerts)), align: 'right', key: "alerts" },
      { id: codigo_juego, value: formatNumbers(Number(faltantes)), align: 'center', key: "faltantes" }
    ];
    return row;
  });

  const colums = [
    { name: "Agencia", align: "left", key: "tipo_juego" },
    { name: "Asignados", align: "right", key: "Asignados" },
    { name: "Vendidos", align: "right", key: "Vendidos" },
    { name: "Stock", align: "right", key: "Stock" },
    { name: "Alerts", align: "right", key: "Alerts" },
    { "name": "Faltantes", align: "center", key: "Faltantes" }
  ];

  const columnsTotal = [
    { value: totales.asignados, align: 'right' },
    { value: totales.vendidos, align: 'right' },
    { value: totales.escaneado, align: 'right' },
    { value: totales.alerts, align: 'right' },
    { value: totales.faltantes, align: 'center' }
  ];

  return (
    <>
      <Box sx={{ m: 3, py: 5 }}>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              Inventario de Raspaditas por Angencias
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
              maxItems={4}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/raspaditas/inventario"
                variant="subtitle2"
              >
                Inventario
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                Inventario por Agencia
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ m: 3 }}>
        <CardHeader title={title} />
        <Scrollbar>
          <Box sx={{ minWidth: 720 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table stickyHeader className="tableColor" style={{ height: 150 }} >
                    <TableHeadCustom columns={colums} sortClick={() => { }} />
                    <TableBodyCustom dataBody={data} onClickCell={() => { }} />
                    <TableFooterComponent dataFooter={columnsTotal} />
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100, 150, 250]}
        />
      </Card>
    </>
  )
}

export default InventarioTablePresidencia;
