import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useState  } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { useCreateRegimenWuMutation } from "src/generated/graphql";
import { useWesterUnionContext } from "./context/WesterUnionContext";


export default function WesterUnionPersonaFisica() {

  const { handleNext, uuid, userDataRegimenForm } = useWesterUnionContext();
  const navigate = useNavigate();
  const [fecha, setFecha] = useState<Date | null>(new Date());
  const { handleSubmit, register , control, watch, formState: {errors}} = useForm();

  const cargoCheck = watch("cargoCheck");

  const [crearRegimen, {error, loading, data: dataSuccess}] = useCreateRegimenWuMutation()

  const handleCancel = () => {
    navigate('/westerUnion/verificar-usuario')
  }

  const handleGoToPay = () => {
    handleNext('/westerUnion/detalles')
  }

  const onSubmit = async (data: any) => {
    console.log('dataaaa', data)
    await crearRegimen({
      variables: {
        input: {
          nombres: data.nombres,
          documento: data.documento,
          dni: data.dni,
          nacionalidad: data.nacionalidad,
          fechaNacimiento: dayjs(data.fechaNacimiento).format('YYYY-MM-DD'),
          sexo: data.sexo,
          cityAdnCountry: data.cityAdnCountry,
          estadoCivil: data.estadoCivil,
          domicilio: data.domicilio,
          calle: data.calle,
          city: data.city,
          departamento: data.departamento,
          pais: data.pais,
          telefono: data.telefono,
          celular: data.celular,
          correo: data.correo,
          actividadEconomica: data.actividadEconomica,
          cargoOcupacion: data.cargoOcupacion,
          ingresos: data.ingresos,
          origenFondos: data.origenFondos,
          nombresR: data.nombresR,
          apellidosR: data.apellidosR,
          nacionalidadR: data.nacionalidadR,
          paisR: data.paisR,
          relacionR: data.relacionR,
          parentescoR: data.parentescoR,
          fechaRemesa: dayjs(data.fechaRemesa).format('YYYY-MM-DD'),
          destinoRemesa: data.destinoRemesa,
          cargoP: data.cargoP ? data.cargoP : 'no',
          institucionPu: data.institucionPu ? data.institucionPu : 'no',
          fechaInicioCargo: dayjs(data.fechaInicioCargo).format('YYYY-MM-DD'),
          fechaFinCargo: dayjs(data.fechaFinCargo).format('YYYY-MM-DD'),
          conyuge: data.conyuge,
          asociadaP: data.asociadaP,
          accionista: data.acionista,
          miembroG: data.miembroG,
          denominacionPj: data.denominacionPj,
          nroRucPj: data.nroRucPj,
          parentescoP: data.parentescoP,
          nombresP: data.nombresP,
          tipoDocP: data.tipoDocP,
          cargoPariente: data.cargoPariente,
          institucionP: data.institucionP,
          porcentajeP: data.porcentajeP,
          denominacion: data.denominacion,
          nroRuc: data.nroRuc,
          nombresA: data.nombresA,
          tipoDocAccionaria: data.tipoDocAccionaria,
          reference: uuid,
        }
      }
    })
  }


  return (
    <Container>
      <Helmet>
          <title>Wester Union - Persona Fisica</title>
      </Helmet>
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 2,
            mb: 2
        }}>
          <Typography variant="h5">Formulario de Identificación del Cliente</Typography>
            <Typography variant="h6">La información proporcionada en el presente formulario es de uso estrictamente confidencial e interno de la empresa TDP S.A.</Typography>
        </Box>
        <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
           <Typography variant="h5">Información del Cliente</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Nombres y Apellidos (Completos)" defaultValue={`${userDataRegimenForm.names} ${userDataRegimenForm.last_names}`} {...register("nombres", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.nombres && <Typography variant="subtitle2" color="error">{errors.nombres.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Número de Documento" defaultValue={userDataRegimenForm.ci} {...register("documento", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.documento && <Typography variant="subtitle2" color="error">{errors.documento.message}</Typography>
          }
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="dni"
            defaultValue={userDataRegimenForm.doc_type}
            control={control}
            render={({ field }) => (
            <FormControl component="fieldset">
              <FormLabel>Documento de identidad</FormLabel>
              <RadioGroup
              row {...field}
              name="dni"
              value={
                field.value || userDataRegimenForm.doc_type
              }
              >
                <FormControlLabel value="B" control={<Radio />} label="C.I.C." />
                <FormControlLabel value="A" control={<Radio />} label="Pasaporte" />
                <FormControlLabel value="C" control={<Radio />} label="Otro" />
              </RadioGroup>
            </FormControl>
          )
        }/>
        {
          errors.dni && <Typography variant="subtitle2" color="error">{errors.dni.message}</Typography>
        }
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="nacionalidad"
            control={control}
            defaultValue={userDataRegimenForm.paisDoc}
            render={({ field }) => (
              <FormControl component="fieldset">
              <FormLabel >Emisor</FormLabel>
              <RadioGroup row {...field} name="nacionalidad" value={field.value || userDataRegimenForm.paisDoc}>
                <FormControlLabel value="Paraguay" control={<Radio />} label="Paraguay" />
                <FormControlLabel value="otro" control={<Radio />} label="Otro" />
              </RadioGroup>
            </FormControl>
            )}
           />
            {
              errors.nacionalidad && <Typography variant="subtitle2" color="error">{errors.nacionalidad.message}</Typography>
            }

        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="fechaNacimiento"
            control={control}
            rules={{
              required: 'Campo obligatorio'
            }}
            defaultValue={userDataRegimenForm.birth_date ? dayjs(userDataRegimenForm.birth_date).toDate() : null}
            render={({ field }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  name="fechaNacimiento"
                  id="fecha"
                  label="Fecha de Nacimiento"
                  placeholder="dd/MM/aaaa"
                  value={fecha}
                  fullWidth
                  helperText={false}
                  onChange={(value) => setFecha(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  sx={{
                    mt: 2,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  }}
                  {...field}
                />
              </MuiPickersUtilsProvider>
            )}
            />
            {
              errors.fechaNacimiento && <Typography variant="subtitle2" color="error">{errors.fechaNacimiento.message}</Typography>
            }
        </Grid>

        <Grid item xs={6}>
           <Controller
             name="sexo"
             control={control}
             rules={{
                required: 'Seleccione una opción'
             }}
             render={({ field }) => (
              <FormControl component="fieldset">
              <FormLabel component="legend">Sexo</FormLabel>
              <RadioGroup row {...field} name="sexo">
                <FormControlLabel value="femenino" control={<Radio />} label="Femenino" />
                <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
              </RadioGroup>
            </FormControl>
             ) }
           />
           {
              errors.sexo && <Typography variant="subtitle2" color="error">{errors.sexo.message}</Typography>
           }
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField fullWidth label="Ciudad y Pais de Nacimiento" defaultValue={`${userDataRegimenForm.city} ${userDataRegimenForm.country_birth}`} {...register('cityAdnCountry', {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.cityAdnCountry && <Typography variant="subtitle2" color="error">{errors.cityAdnCountry.message}</Typography>
          }
        </Grid>


        <Grid item xs={6}>
          <Controller
            name="estadoCivil"
            control={control}
            rules={{
              required: 'Seleccione una opción'
            }}
            render={({ field }) => (
              <FormControl component="fieldset">
              <FormLabel component="legend">Estado Civil</FormLabel>
              <RadioGroup row {...field} name="estadoCivil">
                <FormControlLabel value="soltero" control={<Radio />} label="Soltero" />
                <FormControlLabel value="casado" control={<Radio />} label="Casado" />
                <FormControlLabel value="divorciado" control={<Radio />} label="Divorciado" />
                <FormControlLabel value="viudo" control={<Radio />} label="Viudo" />
              </RadioGroup>
            </FormControl>
            )}
          />
          {
            errors.estadoCivil && <Typography variant="subtitle2" color="error">{errors.estadoCivil.message}</Typography>
          }
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField fullWidth label="Domicilio Particular" defaultValue={userDataRegimenForm.address} {...register("domicilio", {
            required: 'campo obligatorio'
          })}/>
          {
            errors.domicilio && <Typography variant="subtitle2" color="error">{errors.domicilio.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Calle" {...register("calle", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.calle && <Typography variant="subtitle2" color="error">{errors.calle.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Ciudad" defaultValue={userDataRegimenForm.city} {...register("city", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.city && <Typography variant="subtitle2" color="error">{errors.city.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Departamento" defaultValue={userDataRegimenForm.departamento} {...register("departamento", {
             required: 'Campo obligatorio'
          })}/>
            {
              errors.departamento && <Typography variant="subtitle2" color="error">{errors.departamento.message}</Typography>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Pais" defaultValue={userDataRegimenForm.country_birth} {...register("pais", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.pais && <Typography variant="subtitle2" color="error">{errors.pais.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Telefono" defaultValue={`${userDataRegimenForm.country_code_phone} ${userDataRegimenForm.phone_number}`} {...register("telefono", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.telefono && <Typography variant="subtitle2" color="error">{errors.telefono.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Celular" defaultValue={`${userDataRegimenForm.country_code_phone} ${userDataRegimenForm.phone_number}`} {...register("celular", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.celular && <Typography variant="subtitle2" color="error">{errors.celular.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Correo electronico" defaultValue={userDataRegimenForm.email} {...register("correo", {
            required: 'Campo obligatorio'
          })}/>
           {
            errors.correo && <Typography variant="subtitle2" color="error">{errors.correo.message}</Typography>
           }
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h5">Datos de la Actividad Económica</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Actividad económica" {...register("actividadEconomica", {
             required: 'Campo obligatorio'
          })}/>
            {
              errors.actividadEconomica && <Typography variant="subtitle2" color="error">{errors.actividadEconomica.message}</Typography>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Cargo/Ocupación" {...register("cargoOcupacion", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.cargoOcupacion && <Typography variant="subtitle2" color="error">{errors.cargoOcupacion.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Ingresos mensuales (en Gs.)" {...register("ingresos", {
             required: 'Campo obligatorio',
             pattern: {
              value: /^[0-9]*$/,
              message: 'Solo se permiten números'
             }
          })}/>

          {
            errors.ingresos && <Typography variant="subtitle2" color="error">{errors.ingresos.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Origen de los fondos" {...register("origenFondos", {
            required: 'Campo obligatorio'
          })}/>
            {
            errors.origenFondos && <Typography variant="subtitle2" color="error">{errors.origenFondos.message}</Typography>
            }
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
           <Typography variant="h5">Información del Remitente/Beneficiario</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Nombre(s)" defaultValue={userDataRegimenForm.first_name_sender} {...register("nombresR", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.nombresR && <Typography variant="subtitle2" color="error">{errors.nombresR.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Apellido(s)" defaultValue={userDataRegimenForm.last_name_sender} {...register("apellidosR", {
            required: 'Campo obligatorio'
          })}/>
            {
              errors.apellidosR && <Typography variant="subtitle2" color="error">{errors.apellidosR.message}</Typography>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Nacionalidad" {...register("nacionalidadR", {
            required: 'Campo obligatorio'
          })}/>
          {
            errors.nacionalidadR && <Typography variant="subtitle2" color="error">{errors.nacionalidadR.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="País de origen" {...register("paisR", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.paisR && <Typography variant="subtitle2" color="error">{errors.paisR.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Relacion" {...register("relacionR", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.relacionR && <Typography variant="subtitle2" color="error">{errors.relacionR.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Parentesco" {...register("parentescoR", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.parentescoR && <Typography variant="subtitle2" color="error">{errors.parentescoR.message}</Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
        <Controller
            name="fechaRemesa"
            control={control}
            rules={{
              required: 'Campo obligatorio'
            }}
            render={({ field }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  name="fechaRemesa"
                  id="fecha"
                  label="Fecha de Nacimiento"
                  placeholder="dd/MM/aaaa"
                  value={fecha}
                  fullWidth
                  helperText={false}
                  onChange={(value) => setFecha(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  sx={{
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  }}

                  {...field}
                />
              </MuiPickersUtilsProvider>
            )}
            />
            {
              errors.fechaRemesa && <Typography variant="subtitle2" color="error">{errors.fechaRemesa.message}</Typography>
            }
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 2}}>
          <TextField fullWidth label="Destino/motivo de la remesa:" {...register("destinoRemesa", {
             required: 'Campo obligatorio'
          })}/>
          {
            errors.destinoRemesa && <Typography variant="subtitle2" color="error">{errors.destinoRemesa.message}</Typography>
          }
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center" }}>
           <Typography variant="h5">Datos de ocupación y cargos públicos</Typography>
        </Grid>


        <Grid item xs={12} container spacing={2} alignItems="center" display='flex'>
        <Grid item xs={6}>
           <Typography variant="subtitle2">¿Ocupa o ha ocupado un cargo público?</Typography>
        </Grid>
        <Grid item xs={6}>
            <Controller
              name="cargoCheck"
              control={control}
              rules={{
                required: 'Seleccione una opción'
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox checked={value === 'si'} onChange={() => onChange('si')}/>}
                    label="Si"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={value === 'no'} onChange={() => onChange('no')} />}
                    label="No"
                  />
              {errors.cargoCheck && <Typography variant="subtitle2" color="error">{errors.cargoCheck.message}</Typography>}
                </>
              )}
              />
          </Grid>
      </Grid>

        {cargoCheck === 'si' && (
          <>
            <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Cargo" {...register("cargoP", {required:
               cargoCheck === 'si' ? 'Campo obligatorio': false
             })}/>
             {errors.cargoP && <Typography variant="subtitle2" color="error">{errors.cargoP.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Institucion" {...register("institucionPu", {
              required: cargoCheck === 'si' ? 'Campo obligatorio' : false
            })}/>
            {errors.institucionPu && <Typography variant="subtitle2" color="error">{errors.institucionPu.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={6}>
              <Controller
               name="fechaInicioCargo"
               control={control}
               rules={{
                 required: cargoCheck === 'si' ? 'Campo obligatorio' : false
               }}
               render={({ field }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  name="fechaInicioCargo"
                  id="fecha"
                  label="Fecha Inicio del cargo"
                  placeholder="dd/MM/aaaa"
                  value={fecha}
                  fullWidth
                  helperText={false}
                  onChange={(value) => setFecha(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  sx={{
                    mt: 2,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  }}
                  {...field}
                />
                {errors.fechaInicioCargo && <Typography variant="subtitle2" color="error">{errors.fechaInicioCargo.message}</Typography>}
          </MuiPickersUtilsProvider>
               )}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
             <Controller
              name="fechaFinCargo"
              rules={{
                required: cargoCheck === 'si' ? 'Campo obligatorio' : false
              }}
              control={control}
              render={({ field }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  name="fechaFinCargo"
                  id="fecha"
                  label="Fecha de cese en el cargo"
                  placeholder="dd/MM/aaaa"
                  value={fecha}
                  fullWidth
                  helperText={false}
                  onChange={(value) => setFecha(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  sx={{
                    mt: 2,
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  }}
                    {...field}
                />
                {errors.fechaFinCargo && <Typography variant="subtitle2" color="error">{errors.fechaFinCargo.message}</Typography>}
                </MuiPickersUtilsProvider>
              )}
             />
          </Grid>
          </>
        )}

        <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={6}>
           <Typography variant="subtitle2">¿Es usted cónyuge, concubino/a o pariente por consanguinidad o afinidad en línea ascendente, descendente o colateral de una persona que ocupa o haya ocupado un cargo público? </Typography>
        </Grid>
        <Grid item xs={6}>
        <Controller
              name="conyuge"
              control={control}
              rules={{
                required: 'Seleccione una opción'
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox checked={value === 'si'} onChange={() => onChange('si')} />}
                    label="Si"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={value === 'no'} onChange={() => onChange('no')} />}
                    label="No"
                  />
                </>
              )}
            />
            {
              errors.conyuge && <Typography variant="subtitle2" color="error">{errors.conyuge.message}</Typography>
            }
          </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={6}>
           <Typography variant="subtitle2">¿Es usted una persona asociada a una persona que haya ocupado un cargo público?</Typography>
        </Grid>
        <Grid item xs={6}>
        <Controller
              name="asociadaP"
              control={control}
              rules={{
                required: 'Seleccione una opción'
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox checked={value === 'si'} onChange={() => onChange('si')} />}
                    label="Si"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={value === 'no'} onChange={() => onChange('no')} />}
                    label="No"
                  />
                  {errors.asociadaP && <Typography variant="subtitle2" color="error">{errors.asociadaP.message}</Typography>}
                </>
              )}
            />
          </Grid>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h5">Datos de Parientes</Typography>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1, ml: 2 }}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Parentesco</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Nombre y apellidos</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">Tipo y N de Doc</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Cargo ocupado</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Institución</Typography>
            </Grid>


            {[...Array(3)].map((_, index) => (
              <React.Fragment key={uuidv4()}>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`parentescoP[${index}]`)}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth variant="outlined" {...register(`nombresP[${index}]`)}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth variant="outlined" {...register(`tipoDocP[${index}]`)}/>
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`cargoPariente[${index}]`)}/>
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`institucionP[${index}]`)}/>
                </Grid>
            </React.Fragment>
            ))}
          </Grid>
      <Grid item xs={12} container spacing={2} alignItems="center">
        <Grid item xs={6}>
           <Typography variant="subtitle2">¿Es usted un/a socio/a, accionista, asociado, administrador/a, representante legal, apoderado/a o mandatorio/a con poder de disposición, de una persona o ente jurídico donde una persona que ocupe o haya ocupado un cargo público tenga el 10% o más de capital social, aporte o participación? </Typography>
        </Grid>
        <Grid item xs={6}>
        <Controller
              name="acionista"
              control={control}
              rules={{
                required: 'Seleccione una opción'
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox checked={value === 'si'} onChange={() => onChange('si')} />}
                    label="Si"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={value === 'no'} onChange={() => onChange('no')} />}
                    label="No"
                  />
                  {
                    errors.acionista && <Typography variant="subtitle2" color="error">{errors.acionista.message}</Typography>
                  }
                </>
              )}
            />
          </Grid>
      </Grid>

        <Grid container spacing={3} sx={{ mt: 1, ml: 2 }}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Denominacion de la persona o ente juridico </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">N de RUC</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1">% de participacion</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Nombre y apellidos</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Tipo y N de Doc. De identidad</Typography>
            </Grid>

            {[...Array(3)].map((_, index) => (
              <React.Fragment key={uuidv4()}>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`denominacion[${index}]`)}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth variant="outlined" {...register(`nroRuc[${index}]`)}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth variant="outlined" {...register(`porcentajeP[${index}]`)}/>
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`nombresA[${index}]`)}/>
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth variant="outlined" {...register(`tipoDocAccionaria[${index}]`)}/>
                </Grid>
            </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="subtitle2">¿Es usted miembro de la Alta Gerencia, director/a, miembro de la Junta, o tiene una función prominente en alguna organización internacional o intergubernamental? </Typography>
            </Grid>
            <Grid item xs={6}>
            <Controller
              name="miembroG"
              control={control}
              rules={{
                 required: 'Seleccione una opción'
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={<Checkbox checked={value === 'si'} onChange={() => onChange('si')} />}
                    label="Si"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={value === 'no'} onChange={() => onChange('no')} />}
                    label="No"
                  />
                  {
                    errors.miembroG && <Typography variant="subtitle2" color="error">{errors.miembroG.message}</Typography>
                  }
                </>
              )}
            />
              </Grid>
          </Grid>

        <Grid container spacing={4} sx={{ mt: 1, ml: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Denominacion de la persona o ente juridico </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">N de RUC</Typography>
            </Grid>

            {[...Array(2)].map((_, index) => (
              <React.Fragment key={uuidv4()}>
                <Grid item xs={6}>
                  <TextField fullWidth variant="outlined" {...register(`denominacionPj[${index}]`)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth variant="outlined" {...register(`nroRucPj[${index}]`)}/>
                </Grid>
            </React.Fragment>
            ))}
          </Grid>
            {
                    dataSuccess?.createRegimenWu?.status !== 200 ? (
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2, gap: 1, cursor: "pointer" }}>
                        <Button type="submit" variant="contained" fullWidth color="primary">
                          {loading ? 'Registrando...' : 'Registrar Datos'}
                        </Button>
                      </Grid>
                    ) : (
                      // Mostrar los botones de continuar y cancelar si el status es 200
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2, gap: 1, cursor: "pointer" }}>
                        <Button onClick={handleGoToPay} variant="contained" color="primary" fullWidth>Continuar con el pago</Button>
                        <Button onClick={handleCancel} variant="contained" color="secondary" fullWidth>Cancelar</Button>
                      </Grid>
                    )
                  }
                  {
                    dataSuccess?.createRegimenWu?.status !== 200 && (
                      <Typography variant="subtitle2" color="error" sx={{ textAlign: "center" }}>
                        {error ? 'Error al registrar los datos' : ''}
                      </Typography>
                    )
                  }
        </Grid>
       </form>
    </Container>
  );
}
