import { Card, CardContent, Grid } from "@material-ui/core";
import PropTypes from 'prop-types';
import { QwebCharts } from './charts/QwebCharts'


const QwebChart = ({ data }) => {
  console.log( data?.data?.dataQwebForChart)
  return(
  <Grid container spacing={3} sx={{
    mt: 1
  }}>
    <Grid item xs={12} md={12} lg={12}>
      <Card>
        <CardContent>
          <QwebCharts data={data?.data?.dataQwebForChart}/>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)}


QwebChart.propTypes = {
  data: PropTypes.any.isRequired
}

export default QwebChart;
