import { combineReducers } from '@reduxjs/toolkit';
import { reducer as ticketReducer } from '../slices/ticket';
import { reducer as quinielaReducer } from '../slices/quiniela';

const rootReducer = combineReducers({
  ticket:ticketReducer,
  quiniela: quinielaReducer
});

export default rootReducer;
