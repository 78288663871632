import { Box, Card, CardHeader, Table, Grid, TableContainer, TablePagination } from '@material-ui/core';
import TableHeadCustom from 'src/components/_common/table-head';
import TableFooterComponent from 'src/components/_common/table-footer';
import Scrollbar from "src/components/Scrollbar";
import { useState } from 'react';
import TableBodyCustom from 'src/components/_common/table-body';
import formatNumbers from 'src/utils/formatNumbers';

const InventarioTable = ({ onClickCell, dataGrid, sortClick, roles }: any) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(100);
  const title = 'Inventario';
  const totales = {
    asignados: 0,
    vendidos: 0,
    escaneado: 0,
    alerts: 0,
    faltantes: 0
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const data = dataGrid.map((item: any) => {
    const { tipo_juego, asignados, vendidos, escaneados, alerts, codigo_juego, faltantes } = item;
    totales.asignados += parseInt(asignados, 10);
    totales.vendidos += parseInt(vendidos, 10);
    totales.escaneado += parseInt(escaneados, 10);
    totales.alerts += parseInt(alerts, 10);
    totales.faltantes += parseInt(faltantes, 10);

    const row = [
      { id: codigo_juego, value: tipo_juego, align: 'left', key: "tipo_juego" },
      { id: codigo_juego, value: formatNumbers(Number(asignados)), align: 'right', key: "Asignado" },
      { id: codigo_juego, value: formatNumbers(Number(vendidos)), align: 'right', key: "vendidos" },
      { id: codigo_juego, value: formatNumbers(Number(escaneados)), align: 'right', key: "stock" },
      { id: codigo_juego, value: formatNumbers(Number(alerts)), align: 'right', key: "alerts" },
      { id: codigo_juego, value: formatNumbers(Number(faltantes)), align: 'center', key: "faltante" },
      roles?.includes('presidencia', 'tdp_gerencia') || roles?.includes('admin_juego', 'tdp_operativa', 'raspa_gerencia') ? { id: codigo_juego, value: '', align: 'center', key: "acciones", isLink: `/raspaditas/inventario/agencias/${codigo_juego}`, icon: true } : null
    ];

    return row;
  });

  const colums = [
    { name: "Tipo de Juego", align: "left", key: "tipo_juego" },
    { name: "Asignados", align: "right", key: "Asignados" },
    { name: "Vendidos", align: "right", key: "Vendidos" },
    { name: "Stock", align: "right", key: "Stock" },
    { name: "Alerts", align: "right", key: "Alerts" },
    { "name": "Faltantes", align: "center", key: "Faltantes" },
    roles?.includes('presidencia', 'tdp_gerencia') || roles?.includes('admin_juego', 'tdp_operativa', 'raspa_gerencia') ? { name: "Acciones", align: "center", key: "Acciones" } : null
  ];

  const columnsTotal = [
    { value: totales.asignados, align: 'right', key: "asignados" },
    { value: totales.vendidos, align: 'right', key: "vendidos" },
    { value: totales.escaneado, align: 'right', key: "stock" },
    { value: totales.alerts, align: 'right', key: "alerts" },
    { value: totales.faltantes, align: 'center', key: "faltante" },
    roles?.includes('presidencia', 'tdp_gerencia') || roles?.includes('admin_juego', 'tdp_operativa', 'raspa_gerencia') ? { value: '-', align: 'center', key: 'acciones' } : null
  ];

  return (
    <>
      <Card sx={{ m: 3 }}>
        <CardHeader title={title} />
        <Scrollbar>
          <Box sx={{ minWidth: 720 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table stickyHeader className="tableColor" style={{ height: 150 }} >
                    <TableHeadCustom columns={colums} sortClick={!roles?.includes('presidencia', 'tdp_gerencia') ? sortClick : () => { }} />
                    <TableBodyCustom
                      dataBody={data}
                      onClickCell={!roles?.includes('presidencia', 'tdp_gerencia') ? onClickCell : () => { }}
                    />
                    <TableFooterComponent dataFooter={columnsTotal} />
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Scrollbar>
        <TablePagination component="div" count={3} onPageChange={handlePageChange} onRowsPerPageChange={handleLimitChange} page={page} rowsPerPage={limit} rowsPerPageOptions={[100, 150, 250]} />
      </Card>
    </>
  )
}

export default InventarioTable;
