import type { FC } from 'react';
import { 
  Button,
  Dialog,
	DialogActions,
	DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types'


interface dialogComplete {
	openDialog: any;
	handleDialogClose: any;
    title: any,
    handleCloseGenerar: any;
}
export const DialogComplete: FC<dialogComplete> = (props) => {
    const {openDialog, handleDialogClose, handleCloseGenerar, title } = props;
    
    const closeDialogAlert = () => {
        handleDialogClose()
        handleCloseGenerar()
    }
	return (
		<Dialog
		open={openDialog}
		onClose={handleDialogClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		<DialogActions>
			<Button onClick={closeDialogAlert}
color="primary"
autoFocus>
				Aceptar
			</Button>
		</DialogActions>
	</Dialog>
	)
}

DialogComplete.propTypes = {
  openDialog: PropTypes.any.isRequired,
	handleDialogClose: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    handleCloseGenerar: PropTypes.any
};
