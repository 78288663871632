import { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
	TableHead,
  TableRow,
	Typography,
} from '@material-ui/core';
import dayjs from 'dayjs';
import formatNumbers from 'src/utils/formatNumbers';
import { useGetUmnsQuery } from '../../../generated/graphql'
import Export from '../../export/Exports';
import Scrollbar from '../../Scrollbar';

interface TableDetailConfirmadaProps {
	items?: any;
		umnSelected?: any;
		setUmnSelected?: any;
		handleSubmit?: any;
    agencia?:any;
    loading?: boolean;
}

const TableTransaccionesUMN: FC<TableDetailConfirmadaProps> = (props) => {
    const { items, setUmnSelected, umnSelected, handleSubmit, agencia,  ...other } = props;

    let variableAgencia;
    if (agencia === 0 || agencia === '0') {
      variableAgencia = [1, 2, 3];
    } else {
      variableAgencia = [parseInt(agencia, 10)];
    }

		const { data } = useGetUmnsQuery({variables: {agencia: variableAgencia}});
		const dataUmn = []
		data?.allUmns.map(oneUmn => dataUmn.push({id: oneUmn.id, numero: oneUmn.numero }))

		const isAllSelected = dataUmn.length > 0 && umnSelected.length === dataUmn.length;

		useEffect(() => {}, [umnSelected, isAllSelected])

    const totales = {cantidad: 0, comisionA: 0, comisionC:0, neto: 0, bruto: 0, premios: 0, ganadores: 0};
    useEffect(() => {}, [items, agencia])

    const headers = ["Nombre", "UMN", "Agencia", "Total Bruto", "Cantidad", "Comision A", "Comision C", "Neto"];

    let dataPdf = items.map((item)=> [
      item.nombre,
      formatNumbers(parseInt(item.numero, 10)),
      formatNumbers(parseInt(item.agencia_numero, 10)),
      formatNumbers(parseInt(item.total, 10)),
      formatNumbers(parseInt(item.cantidad, 10)),
      formatNumbers(parseInt(item.totalComisionA, 10)),
      formatNumbers(parseInt(item.totalComisionC, 10)),
      formatNumbers(parseInt(item.total, 10) - parseInt(item?.totalComisionA, 10) - parseInt(item?.totalComisionC, 10))
    ]);
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      totales.bruto += parseInt(item.total, 10)
      totales.cantidad += parseInt(item.cantidad, 10)
      totales.comisionA += parseInt(item?.totalComisionA, 10)
      totales.comisionC += parseInt(item?.totalComisionC, 10)
      totales.ganadores += parseInt(item?.cantidad_ganadores, 10);
      totales.premios += parseInt(item?.premio_quiniela, 10);
      totales.neto += (parseInt(item?.total, 10) - parseInt(item?.totalComisionA, 10) - parseInt(item?.totalComisionC, 10))
    })
    dataPdf = [
      ...dataPdf,
      [
        "Total: ",
        "",
        "",
        formatNumbers(totales.bruto),
        formatNumbers(totales.cantidad),
        `${formatNumbers(totales.comisionA)}`,
        `${formatNumbers(totales.comisionC)}`,
        `${ formatNumbers(totales.neto)}`
      ]
    ]

    const pdfData = {
      fontSize: 10,
      title: "Transacciones por UMN",
      headers,
      data: dataPdf,
      downloadTitle: `transacciones-umn-${dayjs().format('DD-MM-YYYY')}`,
    }

    return (
    <>
        <Card {...other}>
          <CardHeader
            action={<Export pdf
            pdfData={pdfData} />}
            title="Transacciones por UMN"
          />
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                              <TableCell>
																<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Nombre
																</Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
                                	UMN
																</Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
	                                Agencia
																</Typography>
                              </TableCell>
															<TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Bruto
																</Typography>
                              </TableCell>
															<TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Cantidad
																</Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Premios
																</Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	C. Premiados
																</Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Comisión Agencia
																</Typography>
                              </TableCell>
															<TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Comisión Corredor
																</Typography>
                              </TableCell>
															<TableCell
                                align="right"
                              >
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Neto
																</Typography>
                              </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item) => <TableRow
                                hover
                                key={item.id}
                                >
                                    <TableCell align="left">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
																					{formatNumbers(parseInt(item.numero, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.agencia_numero, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.total, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.cantidad, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item?.premio_quiniela, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item?.cantidad_ganadores, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item?.totalComisionA, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item?.totalComisionC, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                        { formatNumbers(parseInt(item.total, 10) - parseInt(item?.totalComisionA, 10) - parseInt(item?.totalComisionC, 10) - item?.premio_quiniela)  }
                                        </Typography>
                                    </TableCell>
                                    </TableRow>)}
														<TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                       Total
                                    </Typography>
                                </TableCell>
																<TableCell />
																<TableCell />
                                <TableCell align="right">
																	<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.bruto)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.cantidad)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.premios)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.ganadores)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionA)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionC)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.neto - totales.premios - totales.comisionA - totales.comisionC)}
                                  </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableTransaccionesUMN.propTypes = {
		items: PropTypes.any.isRequired,
		umnSelected: PropTypes.any,
		setUmnSelected: PropTypes.any,
		handleSubmit: PropTypes.any,
    agencia: PropTypes.any,
    loading: PropTypes.bool
};

export default TableTransaccionesUMN;
