import xlsx from 'xlsx';
import dayjs from 'dayjs';

export const exportToCVS = (csvData) => {
  const { data, downloadTitle = `csv-${dayjs(new Date()).format("DD-MM-YYYY")}` } = csvData;

  const workSheet = xlsx.utils.json_to_sheet(data)
  workSheet['!cols'] = [
    { wch: 5 },
    { wch: 10 },
    { wch: 10 },
    { wch: 5 },
    { wch: 20 },
    { wch: 5 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 10 },
  ];
  const workBook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(workBook, workSheet, downloadTitle)
  xlsx.utils.sheet_add_aoa(workBook, [], { origin: "B1" });
  //   buffer
  const buff = xlsx.write(workBook, {bookType: 'xlsx', type: 'buffer'})
  console.log(buff)
  //   binary

  xlsx.write(workBook, {bookType: 'xlsx', type: 'binary'})
  // download
  xlsx.writeFile(workBook, `${downloadTitle}.xlsx`)
}
