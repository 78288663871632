import { useState, useContext, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Grid,
  TextField,
	IconButton,
	Icon
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom'
import { RoleContext } from '../../../contexts/RoleContext'
import Scrollbar from '../../Scrollbar';
import formatNumbers from '../../../utils/formatNumbers'

interface TableResumenCorredoresProps {
    tableDataEntregasArray: any[];
		tableTitle: any[];
		title: any;
		handleOpen: any;
		form?: any;
		searchByNumber?: any;
		searchByCI?: any;
		handleSubmit?: any;
}

const applyPagination = (
  tableDataEntregasArray: any[],
  page: number,
  limit: number
): any[] => tableDataEntregasArray
  .slice(page * limit, page * limit + limit);

const TableDetailEntregas: FC<TableResumenCorredoresProps> = (props) => {
    const { tableDataEntregasArray, tableTitle, title, handleOpen, form, searchByNumber, searchByCI, handleSubmit, ...other } = props;
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const { role } = useContext(RoleContext)

    const totales = {monto: 0}

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value, 10));
    };
    const paginateditems = applyPagination(tableDataEntregasArray, page, limit);

    useEffect(() => {}, [tableDataEntregasArray])
    return (
    <>
        <Card {...other}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{marginTop: 2}}
            >
              <Grid
                  xl={6}
                  md={6}
                  xs={12}
              >
                <CardHeader
                  title={title}
                  sx={{marginLeft: 3}}
                />
              </Grid>
              <Grid
                  container
                  item
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  xl={6}
                  md={6}
                  xs={12}
                  >
                  <Grid sx={{marginBottom: "10px"}}>
                      <form  noValidate
autoComplete="off"
onSubmit={handleSubmit} >
                          <TextField
                              onChange={searchByCI}
                              name="nrodoc"
                              value={form.nrodoc}
                              id="standard-basic"
                              className="inputdata"
                              label="Buscar por CI"
                          />
                      </form>
                  </Grid>
                  <Grid sx={{ marginBottom: '10px', marginLeft: '10px'}}>
                      <form noValidate
autoComplete="off"
onSubmit={handleSubmit} >
                          <TextField
                              onChange={searchByNumber}
                              name="numero_corredor"
                              value={form.numero_corredor}
                              id="standard-basic"
                              className="inputdata"
                              label="Buscar &#8470; de corredor"
                          />
                      </form>
                  </Grid>
                  <Grid sx={{ marginBottom: '10px', marginLeft: '10px'}}>
                      <IconButton sx={{maxWidth: "55px", maxHeight: "75px", height: "55px", width: "55px", border: "solid 1px #bdbdbd"}}
type="submit"
onClick={handleSubmit}
aria-label="search">
                          <Icon>search</Icon>
                      </IconButton>
                  </Grid>
              </Grid>
          </Grid>
          <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {tableTitle.map((titleColumn) =>(
                                   <TableCell>
                                       <Typography
																					color="textPrimary"
																					variant="h6"
																				>
																					{titleColumn.name}
																				</Typography>
                                  </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginateditems.map((item) =>  {
															totales.monto += item.monto
															return <TableRow
															hover
															key={item.id}
															>
																<TableCell>
                                      <Typography
                                          color="textPrimary"
                                          variant="subtitle1"
                                          component={RouterLink}
                                          to={`/planillaGeneral/corredor/${item.corredor_id}`}
                                      >
                                        {item.nro_corredor}
                                      </Typography>
																	</TableCell>
																	<TableCell>
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{item.corredor}
																			</Typography>
																	</TableCell>
																	<TableCell>
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{item.ag}
																			</Typography>
																	</TableCell>
																	<TableCell>
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{item.umn}
																			</Typography>
																	</TableCell>
																	<TableCell>
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{item.fcreacion}
																			</Typography>
																	</TableCell>
																	<TableCell>
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{item.fconfimacion}
																			</Typography>
																	</TableCell>
																	<TableCell align="right">
																			<Typography
																					color="textPrimary"
																					variant="subtitle1"
																			>
																					{formatNumbers(item.monto)}
																			</Typography>
																	</TableCell>
																	<TableCell>
																		{
																			// eslint-disable-next-line no-nested-ternary
																			item.estado === 'pendiente' ?
																			<Box
																			sx={{
																				display: 'flex',
																				justifyContent: 'space-evenly'
																			}}
																		>
																			{role?.includes('finanzas') ?
																			<Typography
																				variant="subtitle1"
																				color="inherit"
																				sx={{ fontWeight: 'bold'}}
																			>{item.estado.charAt(0).toUpperCase()+item.estado.slice(1)}</Typography>
																			:
																			<>
																			<Button
																				variant="contained"
																				size="large"
																				color="inherit"
																				onClick={() => handleOpen(item, "Confirmar")}
																			>{item.estado.charAt(0).toUpperCase()+item.estado.slice(1)}</Button>
																			<Button
																				variant="outlined"
																				size="large"
																				color="secondary"
																				onClick={() => handleOpen(item, "Rechazar")}
																			>Rechazar</Button>
																			</>
																			}
																		</Box>
																			// eslint-disable-next-line no-nested-ternary
																			: item.estado === 'confirmada' ?
																			<Typography
																				variant="subtitle1"
																				color="success.main"
																				sx={{ fontWeight: 'bold'}}
																			>{item.estado.charAt(0).toUpperCase()+item.estado.slice(1)}</Typography>
																			: item.estado === 'rechazada' ?
																			<Typography
																				variant="subtitle1"
																				color="error.main"
																				sx={{ fontWeight: 'bold'}}
																			>{item.estado.charAt(0).toUpperCase()+item.estado.slice(1)}</Typography> : null
																		}
																	</TableCell>
															</TableRow>
														})}
														<TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                       Total
                                    </Typography>
                                </TableCell>
																<TableCell align="right" />
																<TableCell align="right" />
																<TableCell align="right" />
																<TableCell align="right" />
																<TableCell align="right" />
																<TableCell align="right">
																<Typography
                                    color="textPrimary"
                                    variant="subtitle1"
                                  >
                                    {formatNumbers((totales.monto))}
                                  </Typography>
                                </TableCell>
																<TableCell align="right" />
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
            <TablePagination
            component="div"
            count={tableDataEntregasArray.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[50, 100, 250]}
            />
        </Card>
    </>)
};

TableDetailEntregas.propTypes = {
    tableDataEntregasArray: PropTypes.any.isRequired,
		tableTitle: PropTypes.any.isRequired,
		title: PropTypes.any.isRequired,
		handleOpen: PropTypes.any.isRequired,
		form: PropTypes.any,
		searchByNumber: PropTypes.any,
		searchByCI: PropTypes.any,
		handleSubmit: PropTypes.any,
};

export default TableDetailEntregas;
