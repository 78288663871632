import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface TicketState {
  data:any;
  juego_id:any;
  isSidebarOpen: boolean;
  isComposeOpen: boolean;
}

const initialState: TicketState = {
  data:null,
  juego_id:null,
  isSidebarOpen: false,
  isComposeOpen: false
};

const slice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getData(state: TicketState, action: PayloadAction<{ data: any }>): void {
      state.data = action.payload;
    },

    openSidebar(state: TicketState): void {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: TicketState): void {
      state.isSidebarOpen = false;
    },
    openCompose(state: TicketState, action:PayloadAction<{juego_id:any}>  ): void {

        console.log(" payload", action.payload);
      state.isComposeOpen = true;
      state.juego_id=action.payload;
    },
    closeCompose(state: TicketState): void {
      state.isComposeOpen = false;
    }
  }
});

export const { reducer } = slice;


export const getData = (data: any): AppThunk => async (dispatch): Promise<void> => {

  dispatch(slice.actions.getData( data ));
};

export const openSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = (juego_id:any): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.openCompose(juego_id));
};

export const closeCompose = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
