import Chart from 'react-apexcharts';

export const QwebCharts = ({ data }: any) => {
  console.log('hola', data);
  if (!data || !Array.isArray(data)) return null;



  // const montoTipo3 = data.map((item: any) => ({
  //   name: 'Premios',
  //   x: new Date(item.generado).getTime(),
  //   y: item.monto_tipo_3
  // }));

  // const montoTipo4 = data.map((item: any) => ({
  //   name: 'Apuestas',
  //   x: new Date(item.generado).getTime(),
  //   y: item.monto_tipo_4
  // }));

  // const montoTipo5 = data.map((item: any) => ({
  //   name: 'Retiro',
  //   x: new Date(item.generado).getTime(),
  //   y: item.monto_tipo_5
  // }));

  // const montoTipo6 = data.map((item: any) => ({
  //   name: 'Cargas',
  //   x: new Date(item.generado).getTime(),
  //   y: item.monto_tipo_6
  // }));

  // const series = [
  //   {
  //     name: 'Premios',
  //     data: montoTipo3,
  //     color: "#00FFFF"
  //   },
  //   {
  //     name: 'Apuestas',
  //     data: montoTipo4,
  //     color: "#808080"
  //   },
  //   {
  //     name: 'Retiro',
  //     data: montoTipo5,
  //     color: "#FF0000"
  //   },
  //   {
  //     name: 'Cargas',
  //     data: montoTipo6,
  //     color: "#00FF00"
  //   }
  // ];
  const combinedData = {};
data.forEach((item) => {
  const timestamp = new Date(item.generado).getTime();
  if (!combinedData[timestamp]) {
    combinedData[timestamp] = {
      x: timestamp,
      y_monto_tipo_3: 0,
      y_monto_tipo_4: 0,
      y_monto_tipo_5: 0,
      y_monto_tipo_6: 0
    };
  }
  combinedData[timestamp].y_monto_tipo_3 += item.monto_tipo_3;
  combinedData[timestamp].y_monto_tipo_4 += item.monto_tipo_4;
  combinedData[timestamp].y_monto_tipo_5 += item.monto_tipo_5;
  combinedData[timestamp].y_monto_tipo_6 += item.monto_tipo_6;
});

const series = [
  {
    name: 'Premios',
    data: Object.values(combinedData).map((item: any) => ({
      x: item.x,
      y: item.y_monto_tipo_3
    })),
    color: "#00FFFF"
  },
  {
    name: 'Apuestas',
    data: Object.values(combinedData).map((item: any) => ({
      x: item.x,
      y: item.y_monto_tipo_4
    })),
    color: "#808080"
  },
  {
    name: 'Retiro',
    data: Object.values(combinedData).map((item: any) => ({
      x: item.x,
      y: item.y_monto_tipo_5
    })),
    color: "#FF0000"
  },
  {
    name: 'Cargas',
    data: Object.values(combinedData).map((item: any) => ({
      x: item.x,
      y: item.y_monto_tipo_6
    })),
    color: "#00FF00"
  }
];


  const options = {
    chart: {
      type: 'line',
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 3,
      curve: 'straight'
    },
    title: {
      text: 'Qweb Chart',
      align: 'left'
    },
    legend: {
      tooltipHoverFormatter: (val: any, opts: any) => `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yy',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Montos'
      }
    }
  };

  return (
    <>
      <Chart options={options} series={series} type="line" height={725} />
    </>
  );
}
