import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Box,
	Container,
	Grid,
	Typography,
	Breadcrumbs,
	Link,
	Divider
} from '@material-ui/core';
import XLSX from 'xlsx'
import TableRaspaditaStockJuego from 'src/components/dashboard/gestionar/TableRaspaditaStockJuego';
import { useParams, Link as RouterLink } from 'react-router-dom';
// import Loading from 'src/components/loading/Loading'
// import {UMNContext} from '../../contexts/UMNContext'
// settings
import Loading from 'src/components/loading/Loading';
import axios from 'axios';
import useSettings from '../../../../hooks/useSettings';
import ChevronRightIcon from '../../../../icons/ChevronRight';

const PlanillaTotalStockJuego: FC = () => {
	const token = localStorage.getItem('accessToken');
	const { settings } = useSettings();
	const { idProducto } = useParams()
	const [stock, setStock] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	// const [variables, setVariables] = useState({
	//   desde: actualDate.startDate, hasta: actualDate.endDate
	// })
	// implementacion not right
	const obtenerStockBoletas = async () => {
		await axios.get(`${process.env.REACT_APP_API_URL_FASTTDPBIZ}/api/v1/gerencia/stock/juego`, { headers: { "Authorization": `Bearer ${token}` } })
			.then((response) => {
				setStock(response.data);
				console.log("adataastockkkkk", stock, response)
				setLoading(false);
			})
			.catch((response) => {
				setError(response);
				setLoading(false);
			});
	}

	useEffect(() => {
		obtenerStockBoletas();
	}, [])


	if (loading) {
		return <Loading />
	}

	if (error) {
		return <h1>Ha ocurrido un error</h1>
	}


	return (
		<>
			<Helmet>
				<title>Dashboard: Detalles Raspadita Juegos | TDPBIZ 2.0</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 8
				}}
			>
				<Container maxWidth={settings.compact ? 'xl' : false}>
					<Grid
						container
						justifyContent="space-between"
						spacing={3}
					>
						<Grid item>
							<Typography
								color="textPrimary"
								variant="h5"
							>
								Planilla de Stock por tipo de juego
							</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Breadcrumbs
							aria-label="breadcrumb"
							separator={<ChevronRightIcon fontSize="small" />}
							sx={{ mt: 1 }}
							maxItems={4}
						>
							<Link
								color="textPrimary"
								component={RouterLink}
								to="/"
								variant="subtitle2"
							>
								Planilla General
							</Link>
							<Typography
								color="textSecondary"
								variant="subtitle2"
							>
								Stock Tipo Juego
							</Typography>
						</Breadcrumbs>
					</Grid>
					<Box sx={{ mt: 3 }}>
						<Grid
							container
							spacing={3}
						>
							<Box sx={{ flexGrow: 1 }}>
								<Divider orientation="horizontal"
									sx={{ backgroundColor: "white" }}
								/>
							</Box>
							<Grid
								item
								md={12}
								xl={12}
								xs={12}
							>
								<TableRaspaditaStockJuego xlsx={XLSX}
									items={stock}
									idProducto={idProducto} />
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	)
}

export default PlanillaTotalStockJuego;
