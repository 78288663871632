import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth';

interface RoleContextI {
    role: any;
    setRole: any;
  }

export const RoleContext = createContext<RoleContextI>({
    role: [],
    setRole: () => {}
});

export const RoleProvider = (props) => {
	const { children } = props;
	const [role, setRole] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    if(auth.user !== null){
      const roles = []
      auth.user.permisos.map((rolName) => roles.push(rolName.Role.name))
      setRole(roles)
    }
    
  }, [auth])

  useEffect(() => {
    console.log(role)
  }, [role])

  return (
    <RoleContext.Provider

      value={{
        role,
        setRole,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

RoleProvider.propTypes = {
  children: PropTypes.node.isRequired
};
