import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
	Divider,
  TextField,
  IconButton,
  Icon,
  Card,
} from '@material-ui/core';
import TableConscistencia from 'src/components/dashboard/gerencia/gadget/TableConscistencia';
import TableCalificacionApuesta from 'src/components/dashboard/gerencia/gadget/TableCalificacionApuestas';
import ErrorMessage from 'src/components/dashboard/error/ErrorMessage';
import Loading from 'src/components/loading/Loading'
import useSettings from 'src/hooks/useSettings';
import axios from 'axios';
import dayjs from 'dayjs';
import PuntosDeRecarga from 'src/components/dashboard/gerencia/gadget/PuntosDeRecarga';
import PuntosDeRetiro from 'src/components/dashboard/gerencia/gadget/PuntosDeRetiro';
import QwebChart from 'src/components/dashboard/gerencia/gadget/QwebChart';


const InvestigadorQweb: FC = () => {

  const { settings } = useSettings();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [data, setData] = useState<any>([])
  const [apuestas, setApuestas] = useState<any>([])
  const [recargas, setRecargas] = useState<any>([])
  const [chart, setChart] = useState<any>([])
  const  params = useParams()
  const [searchCI, setSearchCI] = useState<string>(params.nroDocumento || '');

  const [form, setForm] = useState<any>({
    nroDoc: ''
  })
  const handleChangeUser = (e: any) => {
     const trimmedValue = e.target.value.trim();
     setForm({ ...form, [e.target.name]: trimmedValue })
     setSearchCI(trimmedValue)

  }

   const getClasiApuestas = async (nroDoc: any) => {
    setLoading(true)
    setErrorMessage('')
    setError(false)
    const token = localStorage.getItem('accessToken');

    await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}`, JSON.stringify({
      operationName: null,
      query: `{
        getClasificacionDeApuestas(nroDoc: "${nroDoc}")
      }`,

     }), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
      }
    })
    .then((response: any) => {
      setApuestas(response?.data)
      setError(false)
      setErrorMessage('')
    })
    .catch((response) => {
      setError(true)
      setErrorMessage(response.response)
    })
    .finally(() => {
      setLoading(false)
    })
   }

  const getConsistenica = async (nroDoc: any) => {
    setLoading(true)
    setErrorMessage('')
    setError(false)
    const token = localStorage.getItem('accessToken');

    await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}`, JSON.stringify({
      operationName: null,
      query: `{
        getAnalisisDeConsistencia(nroDoc:  "${nroDoc}")
      }`,

     }), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
      }
    })
    .then((response: any) => {
      setData(response?.data)
      setError(false)
      setErrorMessage('')
    })
    .catch((response) => {
      setError(true)
      setErrorMessage(response.response)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const getRecargasYRetiros = async (nroDoc: any) => {
    setLoading(true)
    setErrorMessage('')
    setError(false)
    const token = localStorage.getItem('accessToken');

    await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}`, JSON.stringify({
      operationName: null,
      query: `{
        getRechargeAndWithdrawal(nroDoc:  "${nroDoc}")
      }`,

     }), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
      }
    })
    .then((response: any) => {
      setRecargas(response?.data)
      setError(false)
      setErrorMessage('')
    })
    .catch((response) => {
      setError(true)
      setErrorMessage(response.response)
    })
    .finally(() => {
      setLoading(false)
    })
  }
  const getChart = async (nroDoc: any) => {

    setLoading(true)
    setErrorMessage('')
    setError(false)
    const token = localStorage.getItem('accessToken');

    await axios.post(`${process.env.REACT_APP_API_URL_GRAPHQL}`, JSON.stringify({
      operationName: null,
      query: `{
        dataQwebForChart(nroDoc: "${nroDoc}")
      }`,

     }), {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
      }
    })
    .then((response: any) => {
      setChart(response?.data)
      setError(false)
      setErrorMessage('')
    })
    .catch((response) => {
      setError(true)
      setErrorMessage(response.response)
    })
    .finally(() => {
      setLoading(false)
    })
   }
    const performSearch = async (searchNroDoc: string) => {
        if (!searchNroDoc) {
          alert('Debe ingresar el CI del usuario');
          return;
        }

        setLoading(true);
        setErrorMessage('');
        setError(false);

        // Lógica de búsqueda
        await getConsistenica(searchNroDoc);
        await getClasiApuestas(searchNroDoc);
        await getRecargasYRetiros(searchNroDoc);
        await getChart(searchNroDoc);

        setLoading(false);
      };
  const handleSearch = async (e: React.FormEvent) => {
       e.preventDefault();
       await performSearch(form.nroDoc || params.nroDocumento);
   }

   useEffect(() => {
      const fetchData = async () => {
      if (params.nroDocumento) {
        setSearchCI(params.nroDocumento);
        await performSearch(params.nroDocumento);
      }
    };

      fetchData();
   }, [params.nroDocumento])


  if(loading) return <Loading />
  if(error){
    return <ErrorMessage title={errorMessage}
    message={errorMessage} />
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Investigador Qweb  | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ marginBottom: "15px"}}
              >
                Investigar Usuario Qweb
              </Typography>
                <Grid sx={{ margin: "5px", display: "flex" }}>
              <form
                noValidate
                autoComplete="off"
                onSubmit={(e) => handleSearch(e)}
                >
                <TextField
                  name="nroDoc"
                  value={searchCI}
                  onChange={handleChangeUser}
                  className="inputdata"
                  required
                  label="Buscar por CI"
                  InputLabelProps={{
                      shrink:  (form.nroDoc || params.nroDocumento) !== ''
                    }}
                />
              </form>
            <Grid sx={{ marginLeft: "30px" }}>
              <IconButton sx={{ maxWidth: "55px", maxHeight: "75px", height: "50px", width: "50px", border: "solid 1px #bdbdbd" }}
                type="submit"
                onClick={(e) => handleSearch(e)}

                aria-label="search">
                <Icon>search</Icon>
              </IconButton>
            </Grid>
            </Grid>
          </Grid>
          <Grid item
              xs={8}
              md={8}
              lg={8}
               sx={{display: "flex", flexDirection: "column", marginBottom: "10px"}}>
              {data && data?.data?.getAnalisisDeConsistencia && data?.data?.getAnalisisDeConsistencia?.length > 0 && (
                <Card>
                  <div style={{ padding: '16px' }}>
                    <Typography variant="h6" sx={{ font: 'bold' }}>
                      Nombre: {data?.data?.getAnalisisDeConsistencia?.[0]?.nombre} {data?.data?.getAnalisisDeConsistencia?.[0]?.apellido}
                    </Typography>
                    <Typography variant="h6">
                      Teléfono: {data?.data?.getAnalisisDeConsistencia?.[0]?.telefono}
                    </Typography>
                    <Typography variant="h6">
                      Cuenta creada el: {dayjs(data?.data?.getAnalisisDeConsistencia?.[0]?.created_at).format('DD/MM/YYYY')}
                    </Typography>
                  </div>
                </Card>
              )}
      </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              mb={3}
            >
							<Box sx={{ flexGrow: 1 }}>
								<Divider
                  orientation="horizontal"
                  sx={{backgroundColor:"white"}}
                />
							</Box>
             <Grid container spacing={3}>
              <Grid
                item
                spacing={3}
                md={12}
                xl={12}
                xs={12}
              >
                <Grid container spacing={3} >
                  <Grid item xs={12} md={6} lg={6}>
                    <TableConscistencia items={data}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                       <QwebChart data={chart} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={12}>
                       <TableCalificacionApuesta items={apuestas} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}  >
                       <PuntosDeRecarga items={recargas?.data?.getRechargeAndWithdrawal?.recarga} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <PuntosDeRetiro items={recargas?.data?.getRechargeAndWithdrawal?.retiros}/>
                    </Grid>
                     <Grid item xs={12} md={6} lg={6}>
                        <Divider />
                    </Grid>
                </Grid>
              </Grid>
             </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InvestigadorQweb;
