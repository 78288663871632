import type { FC } from 'react';
import { useContext, useEffect } from 'react'
import { DateContext } from 'src/contexts/DateContext';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';
import dayjs from "dayjs";
import Loading from 'src/components/loading/Loading';
import { useDispatch } from '../../../store';
import ArrowRight from '../../../icons/ArrowRight';
import {  openCompose } from '../../../slices/ticket';
import Export from '../../export/Exports';
import Scrollbar from '../../Scrollbar';
import formatNumbers from '../../../utils/formatNumbers'

interface TableRecaudacionSorteoProps {
  items: any[];
  setSorteo: any;
  loading: boolean;
}

const TableRecaudacionSorteo: FC<TableRecaudacionSorteoProps> = (props) => {
    const { items, setSorteo, loading, ...other } = props;
    console.log(loading);
    const totales = {
        // total_corredores: 0,
        // corredores: 0,
        cantidad: 0,
        comisionA: 0,
        comisionC: 0,
        totalPremiados: 0,
        bruto: 0,
        premio: 0,
        neto: 0
    }

    const dispatch = useDispatch();
		const {actualDate} = useContext(DateContext)

    const handleComposeClick = (juego_id): void => {
        dispatch(openCompose(juego_id));
    };

    // const sorteoMap = (sorteoId: number) => {
    //     if (sorteoId === 2) {
    //         return "Matutino"
    //     }
    //     if (sorteoId === 4) {
    //         return "Vespertino"
    //     }
    //     if (sorteoId === 5) {
    //         return "Nocturno"
    //     }
    //     if (sorteoId === 20) {
    //         return "Purete"
    //     }
    //     if(sorteoId === 1){
    //         return "Tempranero"
    //     }
    //     return ""
    // } 

    useEffect(() => {}, [items, loading])

    const handleChangeSorteo = (idSorteo) => {
      setSorteo(idSorteo)
    }
    const headers = ["Sorteo", "Cantidad", "Cantidad Premiados", "Monto", "Premio", "%", "Comision A", "Comision C", "Neto"];
    let data = items.map((item)=> [
    //   sorteoMap(item.sorteo_base_id),
      formatNumbers(parseInt(item.cantidad, 10)),
      formatNumbers(parseInt(item.cantidad_premiados, 10)),
      formatNumbers(parseInt(item.monto, 10)),
      `${item.premio === null ? '-' : formatNumbers(parseInt(item.premio, 10))}`,
      `${ ((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%`,
      `${formatNumbers(parseInt(item.comisionA, 10))}`,
      `${formatNumbers(parseInt(item.comisionC, 10))}`,
      `${ formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)) }`
    ]);
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
    //   totales.total_corredores = parseInt(item.total_corredores, 10)
    //   totales.corredores += parseInt(item.corredores, 10)
      totales.cantidad += parseInt(item.cantidad, 10)
      totales.bruto += parseInt(item.monto, 10)
      totales.premio += parseInt(item.premio, 10)
      totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
      totales.comisionA += parseInt(item.comisionA, 10)
      totales.comisionC += parseInt(item.comisionC, 10)
      totales.neto += (parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))
    })
    data = [
      ...data,
      [
        "Total: ",
        // formatNumbers(totales.corredores),
        formatNumbers(totales.cantidad),
        formatNumbers(totales.totalPremiados),
        formatNumbers(totales.bruto),
        `${totales.premio === 0 ? '-' : formatNumbers(totales.premio)}`,
        `${ ((totales.premio / totales.bruto) * 100).toFixed(2)}%`,
        `${formatNumbers(totales.comisionA)}`,
        `${formatNumbers(totales.comisionC)}`,
        `${ formatNumbers(totales.neto)}`
      ]
    ]

    const pdfData = {
      fontSize: 10,
      title: "Recaudación por Sorteo",
      headers,
      data,
      downloadTitle: `recaudacionsorteo-${dayjs().format('DD-MM-YYYY')}`,
    }

    return (
    <>
        <Card {...other}>
            <CardHeader
                action={<Export pdf
                pdfData={pdfData} />}
                title="Recaudacion por Sorteo"
            />
						<Typography
                color="textSecondary"
                variant="h6"
                sx = {{marginLeft: 2}}
            >
                Desde {dayjs(actualDate.startDate).format("DD/MM/YYYY")} hasta {dayjs(actualDate.endDate).format("DD/MM/YYYY")}
            </Typography>
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Sorteo
																	</Typography>
                                </TableCell>
                                {/* <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Corredores
																	</Typography>
                                </TableCell> */}
                                <TableCell align="right">
                                  <Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Cantidad
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premiados
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Monto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premio
																	</Typography>
                                </TableCell>
																<TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    %
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comision Agencia
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comision Corredor
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Neto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Opciones
																	</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading ? items.map((item) => <TableRow
                                key={item.id}
                                onClick={ ()=>handleComposeClick(item.id) }
                                >
                                    <TableCell align="left">
                                        <Typography
                                             variant="subtitle1"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell align="right">
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.corredores, 10))}
                                        </Typography>
                                    </TableCell> */}
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.cantidad, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >

                                            {formatNumbers(parseInt(item.cantidad_premiados, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.monto, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { item.premio === null ? '-' : formatNumbers(parseInt(item.premio, 10)) }
                                        </Typography>
                                    </TableCell>
																		<TableCell align="right">
                                    <Tooltip
                                     title="Ver los premios por jugadas"
                                     aria-label="ver-premiados"
                                     placement="top-start"
                                     disableFocusListener
                                     disableTouchListener
                                    >
                                        <Typography
                                            sx={{fontSize: item.premio > item.monto * 0.54? "20px": "16px"}}
                                            color={item.premio > item.monto * 0.54? "red": "textPrimary"}
                                            variant="subtitle1"
                                            component={RouterLink}
                                            to={`/premiadosPorJugada/${item.sorteo_base_id}`}
                                        >
                                            { ((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%
                                        </Typography>
                                    </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { `${formatNumbers(parseInt(item.comisionA, 10))}` }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.comisionC, 10)) }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)) }
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Button
                                          component={RouterLink}
                                          to={`/gerencia/agencias/${item.sorteo_base_id}`}
                                          sx={{maxHeight:10}}
                                          onClick={() => { handleChangeSorteo(item.sorteo_base_id) }}
                                      >
                                          <IconButton>
                                          <ArrowRight
                                              fontSize="small"
                                              style={{color: "blue"}}
                                          />
                                          </IconButton>
                                      </Button>
                                    </TableCell>
                                </TableRow>
                            ) : <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <Loading />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>}
                                {!loading ?
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                    >
                                        Total
                                    </Typography>

                                </TableCell>
                                 {/* <TableCell
                                        align="right"
                                 >
                                    <Typography
                                        color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.total_corredores)}
                                    </Typography>

                                </TableCell> */}
                                <TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.cantidad)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.totalPremiados)}
                                    </Typography>
                                </TableCell>
																<TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.bruto)}
                                    </Typography>
                                </TableCell>
																<TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.premio)}
                                    </Typography>
                                </TableCell>
																<TableCell align="right">
                                <Tooltip
                                 title="Ver todos los premios por jugadas"
                                 aria-label="ver-premiados"
                                 placement="top-start"
                                 disableFocusListener
                                 disableTouchListener
                                >
                                    <Typography
                                        color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                        component={RouterLink}
                                        to='/premiadosPorJugada/0'
                                    >
                                        { (((totales.premio / totales.bruto) || 0) * 100).toFixed(2) }%
                                    </Typography>
                                </Tooltip>
                                </TableCell>
                                <TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.comisionA)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.comisionC)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                        align="right"
                                    >
																		<Typography
																				color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
																			{formatNumbers(totales.neto)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                      component={RouterLink}
                                      to='/gerencia/agencias/0'
                                      sx={{maxHeight:10}}
                                  >
                                      <IconButton>
                                      <ArrowRight
                                          fontSize="small"
                                          style={{color: "blue"}}
                                      />
                                      </IconButton>
                                  </Button>
                                </TableCell>
                            </TableRow> : null}
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableRecaudacionSorteo.propTypes = {
  items: PropTypes.array.isRequired,
  setSorteo: PropTypes.any,
  loading: PropTypes.bool,
};

export default TableRecaudacionSorteo;
