import { useContext, FC, useEffect, useState } from 'react'
import {
  TextField,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useListAgenciasForFilterQuery} from 'src/generated/graphql'
import { FilterGerenciaContext } from '../../../contexts/FilterContext'

const optionsSorteo = [
  { id: 1, value: 5, label: "Sorteo Nocturno" },
  { id: 2, value: 4, label: "Sorteo Vespertino" },
  { id: 3, value: 2, label: "Sorteo Matutino" },
  { id: 4, value: 1, label: "Sorteo Tempranero"},
  { id: 5, value: 20, label: "Purete" },
  { id: 6, value: 0, label: "Todos" }
]

interface FilterOptionAgencia {
  id: number;
  value: number;
  label: string;
}

// const optionsAgencia = [
//   { id: 0, value: 0, label: "Todos" },
//   { id: 1, value: 1, label: "AG 1 Centro" },
//   { id: 2, value: 2, label: "AG 2 Bo Obrero" },
//   { id: 3, value: 3, label: "AG 3 Sajonia" },
//   { id: 4, value: 4, label: "AG 4 Tembetary" },
//   { id: 5, value: 5, label: "AG 5 San Vicente" },
//   { id: 7, value: 7, label: "AG 7 Herrera" },
//   { id: 6, value: 6, label: "AG 6 Pinoza" },
//   { id: 8, value: 8, label: "AG 8 Abasto" },
//   { id: 9, value: 9, label: "AG 9 Las Mercedes" },
//   { id: 10, value: 10, label: "AG 10 Tablada" },
//   { id: 11, value: 11, label: "AG 11 Luque" },
//   { id: 12, value: 12, label: "AG 12 S. Lorenzo" },
//   { id: 13, value: 13, label: "AG 13 Ñemby" },
//   { id: 14, value: 14, label: "AG 14 FDM" },
//   { id: 15, value: 15, label: "AG 15 Trinidad" },
//   { id: 16, value: 16, label: "AG 16 Aregua" },
//   { id: 17, value: 17, label: "AG 17 Villa Elisa" },
//   { id: 18, value: 18, label: "AG 18 MRA" },
//   { id: 19, value: 19, label: "AG 19 Itaugua" },
//   { id: 20, value: 20, label: "AG 20 Loma Pyta" },
//   { id: 21, value: 21, label: "AG 21 Capiata I" },
//   { id: 22, value: 22, label: "AG 22 Capiata II" },
//   { id: 23, value: 23, label: "AG 23 J. Augusto Saldivar" },
//   { id: 24, value: 24, label: "AG 24 Ypane" },
//   { id: 25, value: 25, label: "AG 25 Limpio" },
//   { id: 26, value: 26, label: "AG 26 San Antonio" },
//   { id: 27, value: 27, label: "AG 27 Ita" },
//   { id: 28, value: 28, label: "AG 28 Ypacarai" },
//   { id: 29, value: 29, label: "AG 29 Lambare I" },
//   { id: 30, value: 30, label: "AG 30 Lamabare II" },
//   { id: 31, value: 31, label: "AG 31 Guarambare" },
//   { id: 32, value: 32, label: "AG 32 Villeta" },
//   { id: 33, value: 33, label: "AG 33 Remanso" },
//   { id: 99, value: 99, label: "AG 99 E-Global" },
//   { id: 101, value: 101, label: "AG 101 Concepcion" },
//   { id: 102, value: 102, label: "AG 102 San Pedro" },
//   { id: 103, value: 103, label: "AG 103 Cordillera" },
//   { id: 104, value: 104, label: "AG 104 Guaira" },
//   { id: 105, value: 105, label: "AG 105 Caaguazu" },
//   { id: 106, value: 106, label: "AG 106 Caazapa" },
//   { id: 107, value: 107, label: "AG 107 Itapua" },
//   { id: 108, value: 108, label: "AG 108 Misiones" },
//   { id: 109, value: 109, label: "AG 109 Paraguari" },
//   { id: 110, value: 110, label: "AG 110 Alto Parana" },
//   { id: 112, value: 112, label: "AG 112 Ñeembucu" },
//   { id: 113, value: 113, label: "AG 113 Amambay" },
//   { id: 114, value: 114, label: "AG 114 Canindeyu" },
//   { id: 115, value: 115, label: "AG 115 Presidente Hayes" },
//   { id: 116, value: 116, label: "AG 116 Boqueron" },
//   { id: 117, value: 117, label: "AG 117 Puerto Casado" },
//   { id: 118, value: 118, label: "AG 118 Alberdi" },
//   { id: 119, value: 119, label: "AG 119 Santa Rosa" },
//   { id: 120, value: 120, label: "AG 120 TDP Pos Web" },
//   { id: 100, value: 100, label: "AG 100 Quiniela Web" },
//   { id: 300, value: 300, label: "AG 300 Pretobax Nextar" },
//   { id: 301, value: 301, label: "AG 301 Baratote" },
//   { id: 303, value: 302, label: "AG 302 BMAS" },
//   { id: 304, value: 304, label: "AG 304 Tarquino" },
//   { id: 305, value: 305, label: "AG 305 Bancard" },
// ]

interface FilterGerenciaProps {
  agencia?: any;
}


const FilterGerencia: FC<FilterGerenciaProps> = (props) => {

  const { agencia: agenciaType } = props;
  const { agenciaValue, corredorValue, sorteoValue, setAgenciaValue, setSorteoValue, setCorredorValue } = useContext(FilterGerenciaContext)
  const { data: dataAgencia } = useListAgenciasForFilterQuery();
  const [optionsAgencia, setOptionsAgencia] = useState<FilterOptionAgencia[]>([])
  const handleChangeSorteo = (e) => {
    setSorteoValue(e.target.value)
  }
  const handleChangeAgencia = (e) => {
    setAgenciaValue(e.target.value)
  }
  const handleChangeCorredor = (e) => {
    if (e.target.value.trim() > 1) {
      setCorredorValue(Number(e.target.value))
    } else {
      setCorredorValue(null)
    }
  }

  useEffect(() => {
    if (dataAgencia?.listAgenciasForFilter) {
      const options = dataAgencia.listAgenciasForFilter;
      console.log(`Options for agencia: ${options}`)
      setOptionsAgencia(options)
    }
  }, [dataAgencia])



  return (<>
    <Grid container
      sx={{ justifyContent: 'flex-start' }}>
      <Grid sx={{ paddingBottom: 2, paddingLeft: 3 }}>
        <TextField
          label="Sorteo"
          fullWidth
          sx={{ width: 175 }}
          name="sorteo"
          onChange={handleChangeSorteo}
          select
          SelectProps={{ native: true }}
          value={sorteoValue}
          variant="outlined"
        >
          {optionsSorteo.map((option) => (
            <option
              key={option.id}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {agenciaType ?
        null
        :
        <Grid sx={{ paddingBottom: 2, paddingLeft: 3 }}>
          <TextField
            label="Agencia"
            fullWidth
            name="agencia"
            onChange={handleChangeAgencia}
            select
            SelectProps={{ native: true }}
            value={agenciaValue}
            variant="outlined"
            InputLabelProps={{ shrink: agenciaValue !== '' }}
             sx={{ width: 175 }}
          >
            {optionsAgencia.map((option) => (
              <option
                key={option.id}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
      }
      {agenciaType ?
        null
        :
        <Grid sx={{ paddingBottom: 2, paddingLeft: 3 }}>
          <TextField
            label="Corredor"
            id="standard-start-adornment"
            value={corredorValue}
            sx={{ width: 175 }}
            type='number'
            onChange={(e) => { handleChangeCorredor(e) }}
            InputProps={{
              startAdornment: <InputAdornment position="start">&#8470;</InputAdornment>,
            }}
          />
        </Grid>
      }
    </Grid>
  </>)
}

FilterGerencia.propTypes = {
  agencia: PropTypes.any
}

export default FilterGerencia;
